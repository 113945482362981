import axios from "axios";

export const _getApplicant = async (id) => {
  try {
    const url = `/applicant/${id}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getCandidates = async (jb_id, batch_number) => {
  try {
    const url = `/jobs/candidates/${jb_id}/${batch_number}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getApplicantResume = async (id) => {
  try {
    const url = `/resume_applicant/${id}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getApplicantReport = async (id) => {
  try {
    const url = `/report_applicant/${id}`;
    const response = await axios.get(url, { responseType: "blob" });

    // Create a URL for the blob
    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", "report.pdf");

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the click event on the link
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);

    return response;
  } catch (error) {
    throw error;
  }
};

export const _viewApplicantReport = async (id) => {
  try {
    const url = `/report_applicant/${id}`;
    const response = await axios.get(url, { responseType: "arraybuffer" });

    // Create a Blob from the response data with content type 'application/pdf'
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Create a URL for the blob
    const blobUrl = window.URL.createObjectURL(blob);

    // Open the blob URL in a new tab
    window.open(blobUrl);

    return response;
  } catch (error) {
    throw error;
  }
};

export const _getApplicantVideo = async (userId) => {
  try {
    const url = `/recording/list/${userId}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _playApplicantVideo = async (recording_id, file_type) => {
  try {
    const url = `/recording/get/${recording_id}/${file_type}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _deleteApplicant = async (id) => {
  try {
    const url = `/applicants/${id}`;
    const response = await axios.delete(url);
    return response;
  } catch (error) {}
};

export const _checkTransCription = async (data) => {
  try {
    const url = `/applicants/check_audio_transcription`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {}
};

export const _saveApplicantPicture = async (data) => {
  try {
    const url = `/applicants/save_picture`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {}
};


