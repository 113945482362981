import React from 'react'

const logoStyle = {
  width: "140px",
  height: "25px",
  cursor: "pointer",
  paddingLeft: "2px",
};

function Logo() {
  return (

      <img
        // src={"https://girikon.ai/wp-content/uploads/2023/09/Girikon-AI.png"}
        src={"https://hire.girikon.ai/wp-content/uploads/2025/02/GirikHire-logo.webp"}
        style={logoStyle}
        alt="logo of sitemark"
      />

  );
}

export default Logo