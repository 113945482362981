import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardActionArea, 
  Box,
  Stack,
  Tooltip 
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { _getSuggestedCourses } from '../../services/employee.service';
import { useParams, Link } from "react-router-dom"; // Import Link from react-router-dom

const SuggestedCourses = () => {
  const [courses, setCourses] = useState({});
  const { skill_Id } = useParams();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await _getSuggestedCourses(skill_Id);
        setCourses(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCourses();
  }, [skill_Id]);

  const CourseSection = ({title, courses}) => (
    <Grid item xs={12} sm={4}>
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ 
          mb: 3, 
          color: 'white',
          background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
          p: 1.5,
          borderRadius: 1,
          textAlign: 'center',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}
      >
        {title}
      </Typography>
      {courses?.map((course, index) => (
        <Card 
          key={index} 
          sx={{ 
            mb: 2,
            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            backgroundColor: course.completed ? '#e8f5e9' : '#ffffff',
            '&:hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 8px 24px rgba(0,0,0,0.15)'
            }
          }}
        >
          <CardActionArea>
            <CardContent>
              <Stack 
                direction="row" 
                justifyContent="space-between" 
                alignItems="center"
                spacing={4}
                sx={{ width: '100%' }}
              >
                <Tooltip title={course.name} arrow placement="top">
                  <Link 
                    to={`/employee/training-course/${course.name}`} // Use "to" instead of "href"
                    style={{ 
                      textDecoration: 'none', 
                      color: course.completed ? '#2e7d32' : '#1976d2', // Adjusted to match MUI success/primary
                      fontWeight: 600,
                      fontSize: '1.1rem',
                      width: '250px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    onMouseOver={(e) => e.currentTarget.style.color = course.completed ? '#1b5e20' : '#1565c0'} // Hover effect
                    onMouseOut={(e) => e.currentTarget.style.color = course.completed ? '#2e7d32' : '#1976d2'}
                  >
                    {course.name}
                  </Link>
                </Tooltip>
                {course.completed ? (
                  <Typography
                    sx={{
                      color: 'success.main',
                      fontWeight: 500,
                      fontSize: '1rem',
                      width: '100px',
                      textAlign: 'right'
                    }}
                  >
                    Completed
                  </Typography>
                ) : (
                  <Link
                    to={`/employee/training-course/${course.name}/${skill_Id}`} // Use "to" instead of "href"
                    style={{
                      textDecoration: 'none',
                      color: '#f50057', // Matches MUI secondary.main
                      fontSize: '1rem',
                      fontWeight: 500,
                      width: '100px',
                      textAlign: 'right',
                      whiteSpace: 'nowrap',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                    onMouseOver={(e) => e.currentTarget.style.color = '#c51162'} // Hover effect
                    onMouseOut={(e) => e.currentTarget.style.color = '#f50057'}
                  >
                    <span>Start Course</span>
                    <ArrowForwardIcon sx={{ ml: 1, fontSize: '1.1rem' }} />
                  </Link>
                )}
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Grid>
  );

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        py: 6
      }}
    >
      <Container maxWidth="xl">
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom 
          sx={{ 
            mb: 6, 
            textAlign: 'center',
            color: '#1a237e',
            fontWeight: 700,
            textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
          }}
        >
          Suggested Courses
        </Typography>
        
        <Grid container spacing={4}>
          <CourseSection 
            title="MCQ Suggested Courses" 
            courses={courses?.mcqCourses} 
          />
          <CourseSection 
            title="Coding Suggested Courses" 
            courses={courses?.codingCourses} 
          />
          <CourseSection 
            title="Interview Suggested Courses" 
            courses={courses?.interviewCourses} 
          />
        </Grid>
      </Container>
    </Box>
  );
};

export default SuggestedCourses;