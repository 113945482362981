import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-github";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { get_agent_by_id } from "../../services/agent.service";

const ViewAiAgent = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [agentData, setAgentData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAgentData = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const response = await get_agent_by_id(id);
      if (response.status === 200) {
        setAgentData(response.data.agent);
      } else {
        console.error("Failed to fetch agent details:", response);
      }
    } catch (error) {
      console.error("Error fetching agent data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchAgentData();
  }, [fetchAgentData]);

  if (loading || !agentData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Card sx={{ p: 4, boxShadow: 4 }}>
      <CardContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Grid container spacing={3} sx={{ maxWidth: "75%" }}>
            <Grid item xs={12}>
              <Typography variant="h5">
                <SupportAgentIcon sx={{ mr: 1 }} /> AI Agent Details
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Agent Name:</Typography>
              <Typography>{agentData.agent_name}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Prompt:</Typography>
              <AceEditor
                mode="markdown"
                theme="github"
                value={agentData.agent_summary}
                width="100%"
                height="100px"
                setOptions={{ tabSize: 2, readOnly: true }}
                style={{ border: "1px solid #ccc", borderRadius: "8px" }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Do's:</Typography>
              <AceEditor
                mode="markdown"
                theme="github"
                value={agentData.agent_dos}
                width="100%"
                height="70px"
                setOptions={{ tabSize: 2, readOnly: true }}
                style={{ border: "1px solid #ccc", borderRadius: "8px" }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Don'ts:</Typography>
              <AceEditor
                mode="markdown"
                theme="github"
                value={agentData.agent_dont}
                width="100%"
                height="70px"
                setOptions={{ tabSize: 2, readOnly: true }}
                style={{ border: "1px solid #ccc", borderRadius: "8px" }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Guiderails:</Typography>
              <AceEditor
                mode="markdown"
                theme="github"
                value={agentData.agent_guide_rails}
                width="100%"
                height="70px"
                setOptions={{ tabSize: 2, readOnly: true }}
                style={{ border: "1px solid #ccc", borderRadius: "8px" }}
              />
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/hr/edit-ai-agent/${id}`)}
                fullWidth
                sx={{ height: 40, textTransform: "none" }}
              >
                Edit Agent
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate("/hr/agents")}
                fullWidth
                sx={{ height: 40, textTransform: "none" }}
              >
                Back to Agents
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ViewAiAgent;
