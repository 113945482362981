import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import {
  Videocam,
  Mic,
  MicOff,
  VideocamOff,
  PersonRemove,
  Chat,
  Close,
  CallEnd,
  People,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Tooltip,
  Fade,
} from "@mui/material";

// Use the same ICE configuration as CandidateVideoCall.jsx
const ICE_SERVERS = [
  // { urls: "stun:stun.l.google.com:19302" },
  { 
    urls: "turn:stun.girikon.ai:3478", 
    username: "user", 
    credential: "pass" 
  }
];

const BASE_URL =
  localStorage.getItem("REACT_APP_WS_URL") || process.env.REACT_APP_WS_URL;
const orgName = localStorage.getItem("org_name");

function InterviewerVideoCall() {
  const { roomId } = useParams();
  const [meetingStarted, setMeetingStarted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [remoteStreams, setRemoteStreams] = useState(new Map());
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isParticipantsOpen, setIsParticipantsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const localVideoRef = useRef(null);
  const peerConnections = useRef(new Map());
  const socketRef = useRef(null);
  const localStreamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const iceCandidateQueues = useRef(new Map());
  const chatContainerRef = useRef(null);
  const participantsRef = useRef([]);

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  // Helper: Update video sender parameters to cap bitrate
  const updateVideoSenderParameters = (peerConnection) => {
    peerConnection.getSenders().forEach((sender) => {
      if (sender.track && sender.track.kind === "video") {
        const parameters = sender.getParameters();
        if (!parameters.encodings) {
          parameters.encodings = [{}];
        }
        // Cap bitrate to 250 kbps
        parameters.encodings[0].maxBitrate = 250000;
        sender.setParameters(parameters)
          .then(() => console.log("Debug: Video sender parameters updated"))
          .catch((e) =>
            console.error("Debug: Failed to update video sender parameters", e)
          );
      }
    });
  };

  useEffect(() => {
    if (meetingStarted) {
      console.log("Debug: Meeting started, initializing socket connection for Interviewer");
      socketRef.current = io(BASE_URL, { path: `/${orgName}/socket.io` });
      const init = async () => {
        setIsLoading(true);
        console.log("Debug: Requesting user media for Interviewer");
        try {
          // Use lower quality video constraints
          const stream = await navigator.mediaDevices.getUserMedia({
            video: {
              width: { ideal: 320 },
              height: { ideal: 320 },
              frameRate: { ideal: 15 },
            },
            audio: true,
          });
          console.log("Debug: Local stream obtained for Interviewer", stream);
          localStreamRef.current = stream;
          if (localVideoRef.current) {
            localVideoRef.current.srcObject = stream;
          }
          console.log("Debug: Emitting join-room", roomId, user.u_id, user.u_email);
          socketRef.current.emit("join-room", roomId, user.u_id, user.u_email);

          socketRef.current.on("connect", () => {
            console.log("Debug: Socket connected, ID:", socketRef.current.id);
          });

          socketRef.current.on("connect_error", (error) => {
            console.error("Debug: Socket connection error:", error);
            //setError("Failed to connect to server. Please try again.");
          });

          socketRef.current.on("reconnect", () => {
            console.log("Debug: Socket reconnected, rejoining room");
            socketRef.current.emit("join-room", roomId, user.u_id, user.u_email);
          });

          socketRef.current.on("participants-updated", async (participantsInfo) => {
            console.log("Debug: Received participants-updated", participantsInfo);
            if (participantsInfo.roomId === roomId) {
              const newParticipantsList = participantsInfo.participantsList.filter(
                (p) => p.email !== user.u_email
              );
              participantsRef.current = newParticipantsList;
              setParticipants(newParticipantsList);
              for (const userInfo of newParticipantsList) {
                if (userInfo.user_id !== user.u_id && !peerConnections.current.has(userInfo.user_id)) {
                  console.log("Debug: Creating peer connection for user:", userInfo.user_id);
                  await createPeerConnection(userInfo.user_id, userInfo.socket_id);
                }
              }
            }
          });

          socketRef.current.on("offer", handleOffer);
          socketRef.current.on("answer", handleAnswer);
          socketRef.current.on("ice-candidate", handleIceCandidate);
          socketRef.current.on("user-disconnected", handleUserDisconnect);
          socketRef.current.on("chat-message", (message) => {
            console.log("Debug: Received chat message", message);
            setMessages((prev) => [...prev, message]);
          });
        } catch (error) {
          console.error("Debug: Error initializing WebRTC for Interviewer:", error);
          setError("Failed to access camera/microphone. Please check permissions.");
        } finally {
          setIsLoading(false);
          console.log("Debug: Finished initialization for Interviewer");
        }
      };

      init();
      return () => {
        console.log("Debug: Cleaning up Interviewer on unmount");
        cleanup();
      };
    }
  }, [meetingStarted, roomId, user.u_id, user.u_email]);

  const createPeerConnection = async (userId, socketId) => {
    console.log(`Debug: Creating RTCPeerConnection for user ${userId}`);
    const peerConnection = new RTCPeerConnection({
      iceServers: ICE_SERVERS,
    });

    peerConnection.oniceconnectionstatechange = () => {
      console.log(`Debug: ICE connection state for ${userId}: ${peerConnection.iceConnectionState}`);
    };
    peerConnection.onconnectionstatechange = () => {
      console.log(`Debug: Connection state for ${userId}: ${peerConnection.connectionState}`);
    };

    // Add local tracks
    localStreamRef.current?.getTracks().forEach((track) => {
      console.log("Debug: Adding local track", track.kind, "to peer connection for user", userId);
      peerConnection.addTrack(track, localStreamRef.current);
    });
    updateVideoSenderParameters(peerConnection);

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        if(event.candidate.type == "srflx"){
          console.log("The STUN server is reachable!");
          console.log(`   Your Public IP Address is: ${event.candidate.address}`);
      }
  
      // If a relay candidate was found, notify that the TURN server works!
      if(event.candidate.type == "relay"){
          console.log("The TURN server is reachable !");
      }
        console.log("Debug: ICE candidate generated for", userId, event.candidate);
        socketRef.current.emit("ice-candidate", { candidate: event.candidate, roomId, to: userId });
      }
    };

    peerConnection.ontrack = (event) => {
      const stream = event.streams[0];
      console.log("Debug: Received remote track from user:", userId, "Stream:", stream);
      if (!remoteStreams.has(userId)) {
        setRemoteStreams((prev) => {
          const newMap = new Map(prev);
          newMap.set(userId, stream);
          return newMap;
        });
      }
    };

    peerConnection.onnegotiationneeded = async () => {
      console.log("Debug: Negotiation needed for user", userId);
      try {
        const offer = await peerConnection.createOffer();
        console.log("Debug: Offer created for user", userId, offer);
        await peerConnection.setLocalDescription(offer);
        console.log("Debug: Local description set for user", userId);
        socketRef.current.emit("offer", { offer, roomId, to: userId });
      } catch (err) {
        console.error("Debug: Error during negotiation for user", userId, err);
      }
    };

    peerConnections.current.set(userId, peerConnection);
    iceCandidateQueues.current.set(userId, []);
  };

  const handleOffer = async ({ offer, from }) => {
    console.log("Debug: Received offer from", from, offer);
    if (!offer || !offer.type || !offer.sdp) {
      console.error("Debug: Invalid offer received:", offer);
      return;
    }
    if (!peerConnections.current.has(from)) {
      console.log("Debug: No peer connection found for offer sender, creating one for", from);
      const peerConnection = new RTCPeerConnection({
        iceServers: ICE_SERVERS,
      });
      peerConnection.oniceconnectionstatechange = () => {
        console.log(`Debug: ICE connection state for ${from}: ${peerConnection.iceConnectionState}`);
      };
      peerConnection.onconnectionstatechange = () => {
        console.log(`Debug: Connection state for ${from}: ${peerConnection.connectionState}`);
      };
      localStreamRef.current?.getTracks().forEach((track) => {
        console.log("Debug: Adding local track", track.kind, "to peer connection for", from);
        peerConnection.addTrack(track, localStreamRef.current);
      });
      updateVideoSenderParameters(peerConnection);

      peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          if(event.candidate.type == "srflx"){
            console.log("The STUN server is reachable! 3");
            console.log(`   Your Public IP Address is 3 : ${event.candidate.address}`);
        }
    
        // If a relay candidate was found, notify that the TURN server works!
        if(event.candidate.type == "relay"){
            console.log("The TURN server is reachable 3!");
        }
          const toInfo = participantsRef.current.find((p) => p.socket_id === from);
          if (!toInfo) return;
          console.log("Debug: ICE candidate (offer) from", from, event.candidate);
          socketRef.current.emit("ice-candidate", {
            candidate: event.candidate,
            roomId,
            to: toInfo.user_id,
          });
        }
      };

      peerConnection.ontrack = (event) => {
        const stream = event.streams[0];
        console.log("Debug: Received remote track (offer) from user:", from, stream);
        if (!remoteStreams.has(from)) {
          setRemoteStreams((prev) => {
            const newMap = new Map(prev);
            newMap.set(from, stream);
            return newMap;
          });
        }
      };

      peerConnections.current.set(from, peerConnection);
      iceCandidateQueues.current.set(from, []);
    }

    const peerConnection = peerConnections.current.get(from);
    try {
      console.log("Debug: Setting remote description for offer from", from);
      await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
      console.log("Debug: Remote description set for", from);
      const answer = await peerConnection.createAnswer();
      console.log("Debug: Answer created for", from, answer);
      await peerConnection.setLocalDescription(answer);
      console.log("Debug: Local description set for answer to", from);
      const toInfo = participantsRef.current.find((p) => p.socket_id === from);
      if (toInfo && answer) {
        console.log("Debug: Emitting answer to", toInfo.user_id);
        socketRef.current.emit("answer", { answer, roomId, to: toInfo.user_id });
      }
      const queue = iceCandidateQueues.current.get(from) || [];
      while (queue.length) {
        const candidate = queue.shift();
        console.log("Debug: Adding queued ICE candidate for", from, candidate);
        await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      }
    } catch (error) {
      console.error("Debug: Error handling offer from", from, error);
    }
  };

  const handleAnswer = async ({ answer, from }) => {
    console.log("Debug: Received answer from", from, answer);
    const answerInfo = participantsRef.current.find((p) => p.socket_id === from);
    if (!answerInfo) {
      console.error(`Debug: Participant with socket_id ${from} not found`);
      return;
    }
    const peerConnection = peerConnections.current.get(answerInfo.user_id);
    if (!peerConnection) {
      console.error(`Debug: No peer connection found for user ${from}`);
      return;
    }
    try {
      console.log("Debug: Setting remote description for answer from", from);
      await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
      console.log("Debug: Remote description set for answer from", from);
      const queue = iceCandidateQueues.current.get(answerInfo.user_id) || [];
      while (queue.length) {
        const candidate = queue.shift();
        console.log("Debug: Adding queued ICE candidate for", answerInfo.user_id, candidate);
        await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      }
    } catch (error) {
      console.error("Debug: Error handling answer from", from, error);
    }
  };

  const handleIceCandidate = async ({ candidate, from }) => {
    console.log("Debug: Received ICE candidate from", from, candidate);
    const candidateInfo = participantsRef.current.find((p) => p.socket_id === from);
    if (!candidateInfo) {
      console.error("Debug: No candidate info for", from);
      return;
    }
    const peerConnection = peerConnections.current.get(candidateInfo.user_id);
    if (!peerConnection) {
      console.error(`Debug: No peer connection for user ${candidateInfo.user_id}`);
      return;
    }
    if (peerConnection.remoteDescription) {
      console.log("Debug: Adding ICE candidate immediately for", candidateInfo.user_id);
      await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    } else {
      console.log("Debug: Queueing ICE candidate for", candidateInfo.user_id, candidate);
      const queue = iceCandidateQueues.current.get(candidateInfo.user_id) || [];
      queue.push(candidate);
      iceCandidateQueues.current.set(candidateInfo.user_id, queue);
    }
  };

  const handleUserDisconnect = (userId) => {
    console.log("Debug: Handling user disconnect for", userId);
    const peerConnection = peerConnections.current.get(userId);
    if (peerConnection) {
      peerConnection.close();
      peerConnections.current.delete(userId);
      setRemoteStreams((prev) => {
        const newMap = new Map(prev);
        newMap.delete(userId);
        return newMap;
      });
      iceCandidateQueues.current.delete(userId);
      setParticipants((prev) => prev.filter((p) => p.id !== userId));
      console.log("Debug: User disconnected and cleaned up:", userId);
    }
  };

  const cleanup = () => {
    console.log("Debug: Running cleanup for Interviewer");
    localStreamRef.current?.getTracks().forEach((track) => {
      console.log("Debug: Stopping local track", track.kind);
      track.stop();
    });
    peerConnections.current.forEach((peer, userId) => {
      console.log("Debug: Closing peer connection for", userId);
      peer.close();
    });
    if (socketRef.current && socketRef.current.connected) {
      console.log("Debug: Disconnecting socket");
      socketRef.current.disconnect();
    }
    peerConnections.current.clear();
    iceCandidateQueues.current.clear();
    localStreamRef.current = null;
    console.log("Debug: Cleanup complete for Interviewer");
  };

  const startMeeting = () => {
    console.log("Debug: Starting Interviewer meeting");
    setMeetingStarted(true);
  };
  const endCall = () => {
    console.log("Debug: Ending Interviewer call");
    cleanup();
    setMeetingStarted(false);
    setParticipants([]);
    setIsAudioEnabled(true);
    setIsVideoEnabled(true);
    setError(null);
  };

  const toggleAudio = () => {
    if (localStreamRef.current) {
      const audioTrack = localStreamRef.current.getAudioTracks()[0];
      audioTrack.enabled = !audioTrack.enabled;
      console.log("Debug: Toggled audio to", audioTrack.enabled);
      setIsAudioEnabled(audioTrack.enabled);
    }
  };

  const toggleVideo = () => {
    if (localStreamRef.current) {
      const videoTrack = localStreamRef.current.getVideoTracks()[0];
      videoTrack.enabled = !videoTrack.enabled;
      console.log("Debug: Toggled video to", videoTrack.enabled);
      setIsVideoEnabled(videoTrack.enabled);
    }
  };

  useEffect(() => {
    console.log("Debug: Updating remote video elements for Interviewer");
    remoteStreams.forEach((stream, userId) => {
      const videoEl = document.getElementById(`remote-video-${userId}`);
      if (videoEl && videoEl.srcObject !== stream) {
        console.log("Debug: Setting remote video element source for", userId);
        videoEl.srcObject = stream;
      }
    });
  }, [remoteStreams]);

  return (
    <Box sx={{ height: "80vh", bgcolor: "#1c2526", display: "flex", flexDirection: "column", fontFamily: "Roboto" }}>
      {!meetingStarted ? (
        <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Paper elevation={3} sx={{ p: 4, bgcolor: "#2d3839", borderRadius: 2, textAlign: "center" }}>
            <Typography variant="h5" sx={{ color: "#e0e7e9", mb: 2 }}>Ready to start the meeting?</Typography>
            <Button onClick={startMeeting} variant="contained" sx={{ bgcolor: "#4285f4", "&:hover": { bgcolor: "#3267d6" }, borderRadius: 1, px: 4, py: 1, fontWeight: 500 }}>
              Start Meeting
            </Button>
          </Paper>
        </Box>
      ) : (
        <>
          <Box sx={{ bgcolor: "#2d3839", p: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="subtitle1" sx={{ color: "#e0e7e9" }}>Interview Room</Typography>
            <Typography variant="caption" sx={{ color: "#b0bec5" }}>{new Date().toLocaleTimeString()}</Typography>
          </Box>
          <Box sx={{ flex: 1, p: 2, overflow: "hidden", bgcolor: "#1c2526", position: "relative" }}>
            {error && <Typography color="error" sx={{ p: 2 }}>{error}</Typography>}
            {isLoading && <Typography sx={{ p: 2, color: "#e0e7e9" }}>Loading...</Typography>}
            <Grid container spacing={2} sx={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
              {remoteStreams.size > 0 ? (
                Array.from(remoteStreams.entries()).map(([userId, stream]) => (
                  <Grid item xs={remoteStreams.size > 1 ? 6 : 12} key={userId}>
                    <Box sx={{ position: "relative", height: "100%", bgcolor: "#000", borderRadius: 2, overflow: "hidden" }}>
                      <video id={`remote-video-${userId}`} autoPlay playsInline style={{ width: "100%", height: "100%", objectFit: "cover",  maxHeight: "calc(80vh - 120px)" }} />
                      <Typography variant="body2" sx={{ position: "absolute", bottom: 8, left: 8, bgcolor: "rgba(0,0,0,0.7)", color: "#fff", px: 1, py: 0.5, borderRadius: 1 }}>
                        {remoteStreams.size > 1 ? "Participant" : "Candidate"}
                      </Typography>
                      <Tooltip title="Remove Participant">
                        <IconButton onClick={() => handleUserDisconnect(userId)} sx={{ position: "absolute", top: 8, right: 8, bgcolor: "rgba(0,0,0,0.5)", color: "#fff", "&:hover": { bgcolor: "#d93025" } }}>
                          <PersonRemove />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography sx={{ color: "#e0e7e9", textAlign: "center" }}>Waiting for candidate to join...</Typography>
                </Grid>
              )}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 50,
                  right: 30,
                  width: { xs: 120, md: 200 },
                  height: { xs: 90, md: 150 },
                  bgcolor: "#000",
                  borderRadius: 2,
                  overflow: "hidden",
                  border: "2px solid #e0e7e9",
                  zIndex: 10,
                }}
              >
                <video ref={localVideoRef} autoPlay muted playsInline style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                <Typography variant="body2" sx={{ position: "absolute", bottom: 4, left: 4, bgcolor: "rgba(0,0,0,0.7)", color: "#fff", px: 1, py: 0.5, borderRadius: 1 }}>You</Typography>
              </Box>
            </Grid>
            <Fade in={true} timeout={500}>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 16,
                  left: "50%",
                  transform: "translateX(-50%)",
                  bgcolor: "rgba(45,56,57,0.9)",
                  borderRadius: 10,
                  p: 1,
                  display: "flex",
                  gap: 2,
                  boxShadow: "0 4px 12px rgba(0,0,0,0.3)",
                  "&:hover": { bgcolor: "rgba(45,56,57,1)" },
                }}
              >
                <Tooltip title={isAudioEnabled ? "Mute" : "Unmute"}>
                  <IconButton onClick={toggleAudio} sx={{ bgcolor: isAudioEnabled ? "#616161" : "#d93025", color: "#fff", "&:hover": { bgcolor: isAudioEnabled ? "#757575" : "#b71c1c" } }}>
                    {isAudioEnabled ? <Mic /> : <MicOff />}
                  </IconButton>
                </Tooltip>
                <Tooltip title={isVideoEnabled ? "Turn Off Camera" : "Turn On Camera"}>
                  <IconButton onClick={toggleVideo} sx={{ bgcolor: isVideoEnabled ? "#616161" : "#d93025", color: "#fff", "&:hover": { bgcolor: isVideoEnabled ? "#757575" : "#b71c1c" } }}>
                    {isVideoEnabled ? <Videocam /> : <VideocamOff />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Leave Call">
                  <IconButton onClick={endCall} sx={{ bgcolor: "#d93025", color: "#fff", "&:hover": { bgcolor: "#b71c1c" } }}>
                    <CallEnd />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Chat">
                  <IconButton onClick={() => setIsChatOpen(!isChatOpen)} sx={{ bgcolor: "#616161", color: "#fff", "&:hover": { bgcolor: "#757575" } }}>
                    <Chat />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Participants">
                  <IconButton onClick={() => setIsParticipantsOpen(!isParticipantsOpen)} sx={{ bgcolor: "#616161", color: "#fff", "&:hover": { bgcolor: "#757575" } }}>
                    <People />
                  </IconButton>
                </Tooltip>
              </Box>
            </Fade>
          </Box>
        </>
      )}
    </Box>
  );
}

export default InterviewerVideoCall;
