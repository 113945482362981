import { useState, useEffect } from "react";
import {
  Stack,
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { clearRedux } from "../../redux/actions/dashboard";
import { getSkillLevel } from "../../utils/getSkillLevelName";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LayersTwoToneIcon from "@mui/icons-material/LayersTwoTone";
import DifferenceTwoToneIcon from "@mui/icons-material/DifferenceTwoTone";
import EmojiEventsTwoToneIcon from "@mui/icons-material/EmojiEventsTwoTone";
import SsidChartTwoToneIcon from "@mui/icons-material/SsidChartTwoTone";
import InsightsTwoToneIcon from "@mui/icons-material/InsightsTwoTone";
import SkillStrengthDynamicChart from "../../components/dataCharts/SkillStrengthDynamicChart";
import SkillExperienceDynamicPieChart from "../../components/dataCharts/SkillExperienceDynamicPieChart";
import { authUser } from "../../redux/actions/auth";

Chart.register(CategoryScale);

export const Dashboard = ({ userData, clearRedux, auth, authUser }) => {
  const [totalSkillSet, setTotalSkillSet] = useState(0);
  const [countSkillGap, setCountSkillGap] = useState(0);
  const [countSkillStrength, setCountSkillStrength] = useState(0);
  const [skillGapScore, setSkillGapScore] = useState(0);
  const [skillStrength, setSkillStrengthScore] = useState(0);
  const [skillList, setSkillList] = useState([]);

  const setData = (data) => {
    let {
      _totalSkillSet,
      _skillGapScore,
      _skillStrengthScore,
      _countSkillGap,
      _countSkillStrength,
      _skills,
      _selfEval,
    } = data;
    setTotalSkillSet(_totalSkillSet);
    setCountSkillGap(_countSkillGap);
    setCountSkillStrength(_countSkillStrength);
    setSkillGapScore(_skillGapScore);
    setSkillStrengthScore(_skillStrengthScore);

    if (_selfEval && _selfEval.length > 0) {
      // setSelfEvalId(_selfEval[0].se_id);
    }

    if (_skills && _skills.length > 0) {
      setSkillList(_skills);
    }
  };

  useEffect(() => {
    if (userData) {
      setData(userData);
    }
  }, [userData]);
  useEffect(() => {
    authUser();
  }, []);
  return (
    <>
      <>
        <Stack
          className={"dashboard-cards-container"}
          direction={{ sm: "column", md: "row" }}
          // spacing={{ xs: 1, sm: 1 }}
          useFlexGap
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ marginTop: 0 }}
        >
          <Card
            raised
            className={"cards"}
            sx={{
              width: "80%",
            }}
          >
            <CardContent>
              <Typography
                className={"text"}
                sx={{ fontSize: 16 }}
                color={"text.secondary"}
                gutterBottom
              >
                Total Skills
              </Typography>
              <Typography variant="h4">
                {totalSkillSet ? totalSkillSet : 0}
              </Typography>
              <div className={"card-icon-container"}>
                <LayersTwoToneIcon />
              </div>
            </CardContent>
          </Card>
          <Card raised className={"cards"}>
            <CardContent>
              <Typography
                className={"text"}
                sx={{ fontSize: 16 }}
                color="text.secondary"
                gutterBottom
              >
                Skills Gap Out Of {totalSkillSet ? totalSkillSet : 0}
              </Typography>

              <Typography variant="h4">
                {countSkillGap ? countSkillGap : 0}
              </Typography>
              <div className={"card-icon-container"}>
                <DifferenceTwoToneIcon />
              </div>
            </CardContent>
          </Card>
          <Card raised className={"cards"}>
            <CardContent>
              <Typography
                className={"text"}
                sx={{ fontSize: 16 }}
                color="text.secondary"
                gutterBottom
              >
                Skills Strengths
              </Typography>

              <Typography variant="h4">
                {countSkillStrength ? countSkillStrength : 0}
              </Typography>
              <div className={"card-icon-container"}>
                <EmojiEventsTwoToneIcon />
              </div>
            </CardContent>
          </Card>
          <Card raised className={"cards"}>
            <CardContent>
              <Typography
                className={"text"}
                sx={{ fontSize: 16 }}
                color="text.secondary"
                gutterBottom
              >
                Total Skills Gap Score
              </Typography>

              <Typography variant="h4">
                {skillGapScore ? skillGapScore : 0}
              </Typography>
              <div className={"card-icon-container"}>
                <SsidChartTwoToneIcon />
              </div>
            </CardContent>
          </Card>
          <Card raised className={"cards"}>
            <CardContent>
              <Typography
                className={"text"}
                sx={{ fontSize: 16 }}
                color="text.secondary"
                gutterBottom
              >
                Total Skills Strength Score
              </Typography>

              <Typography variant="h4">
                {skillStrength ? skillStrength : 0}
              </Typography>
              <div className={"card-icon-container"}>
                <InsightsTwoToneIcon />
              </div>
            </CardContent>
          </Card>
        </Stack>
      </>

      {skillList && skillList.length > 0 ? (
        <>
          <Box sx={{ mt: 3 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width={20}>
                      SN
                    </TableCell>
                    <TableCell align="left">Skill Name</TableCell>
                    <TableCell align="left">Experience</TableCell>
                    <TableCell align="left">Skill Level</TableCell>
                    <TableCell align="left">Self Assessment</TableCell>
                    <TableCell align="left">Skill Gap(5)</TableCell>
                    <TableCell align="left">Mcq Score(5)</TableCell>
                    <TableCell>Coding Score(5)</TableCell>
                    <TableCell align="left">AI Score(5)</TableCell>
                    <TableCell align="left">Suggested Courses</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {skillList.map((row, index) => (
                    <TableRow
                      key={row.ses_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell>{row.ses_skills_name}</TableCell>
                      <TableCell align="center">{row.ses_skills_exp}</TableCell>
                      <TableCell>
                        {getSkillLevel(row.ses_skills_level)}(
                        {row.ses_skills_level})
                      </TableCell>
                      <TableCell>
                        {row.sc_attempt_number === 0 ? (
                          <>
                            <Typography>Not Completed</Typography>

                            {/* <Button
                              color="primary"
                              component="label"
                              variant="contained"
                              sx={{ height: "20px" }}
                              onClick={() =>
                                navigate(
                                  `/self-assessment/mcq/${row.ses_skills_id}/${row.ses_skills_level}`
                                )
                              }
                            >
                              Start
                            </Button> */}
                          </>
                        ) : (
                          <Typography>Completed</Typography>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.sc_attempt_number === 0 ? "-" : row.sc_skill_gap}
                      </TableCell>
                      <TableCell align="center">
                        {row.sc_attempt_number === 0
                          ? "-"
                          : row.sc_mcq_score}
                      </TableCell>
                      <TableCell align="center">
                        {row.sc_attempt_number === 0
                          ? "-"
                          : row.sc_coding_score}
                      </TableCell>
                      <TableCell align="center">
                        {row.sc_attempt_number === 0
                          ? "-"
                          : row.sc_interview_score}
                        {/* {row.sc_ai_model_score} */}
                      </TableCell>
                      <TableCell>
                        {row.sc_recom_courses ? (
                          row.is_training_complete ? (
                            <IconButton>
                              <Link to={`/employee/suggested-courses/${row.ses_id}`}>
                                <AutoStoriesIcon sx={{ color: 'green' }}/>
                              </Link>
                            </IconButton>
                          ): (
                            <IconButton>
                              <Link to={`/employee/suggested-courses/${row.ses_id}`}>
                                <AutoStoriesIcon />
                              </Link>
                            </IconButton>
                          )
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Stack
            className={"dashboard-cards-container"}
            direction={{ sm: "column", md: "row" }}
            spacing={{ xs: 1, sm: 1 }}
            useFlexGap
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ marginTop: 0 }}
          >
            <SkillExperienceDynamicPieChart />

            <SkillStrengthDynamicChart />
          </Stack>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

const mapDispatchToProps = { clearRedux, authUser };

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
