import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "../../assets/PsychometricReport.css";
import { get_report_by_id } from "../../services/psychometric_report.service";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Avatar,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
} from "@mui/material";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";


// Register the datalabels plugin
Chart.register(ChartDataLabels);

const PsychometricAssessmentReport = () => {
  const { apct_id } = useParams(); // Get apct_id from URL params
  const navigate = useNavigate();
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [applicantLanguage, setApplicantLanguage] = useState("en");
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [shareableUrl, setShareableUrl] = useState("");

    const openShareModal = () => {
      const org_name =  localStorage.getItem('org_name');
      const url = `${window.location.origin}/${org_name}/psychometric-report/${apct_id}`;
      setShareableUrl(url);
      setIsShareModalOpen(true);
    };
  
    const closeShareModal = () => {
      setIsShareModalOpen(false);
    };
  

    const handleCopy = () => {
      navigator.clipboard.writeText(shareableUrl);
      // setSnackbarOpen(true);
    };

  // Fetch assessment data from API
  useEffect(() => {
    const fetchAssessmentData = async () => {
      try {
        const response = await get_report_by_id(apct_id);
        if (response.status === 200) {
          console.log("report data 1 ===============>", response.data);
          const data = response.data?.report;
          const language = response?.data?.language;
          if(language && language.code !== 'en'){
            setApplicantLanguage(language.code);
          }

          console.log("report data 2 ===============>", data);
          setAssessmentData(data);
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching assessment data:", err);
        setError(err.response?.data?.error || "Failed to load report");
        setLoading(false);
      }
    };

    fetchAssessmentData();
  }, [apct_id]);

  // Render chart when assessmentData is available
  useEffect(() => {
    if (!assessmentData) return;

    const sectionsCtx = document
      .getElementById("sectionsChart")
      ?.getContext("2d");
    if (sectionsCtx) {
      new Chart(sectionsCtx, {
        type: "pie",
        data: {
          labels: assessmentData.sections.map((section) => section.name),
          datasets: [
            {
              data: assessmentData.sections.map((section) => section.score),
              backgroundColor: [
                "rgba(59, 130, 246, 0.8)",
                "rgba(16, 185, 129, 0.8)",
                "rgba(245, 158, 11, 0.8)",
              ],
              borderColor: [
                "rgb(59, 130, 246)",
                "rgb(16, 185, 129)",
                "rgb(245, 158, 11)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "right",
              align: "start",
              labels: { font: { size: 12 }, padding: 20 },
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (context) => `${context.label}: ${context.raw}%`,
              },
            },
            datalabels: {
              color: "#fff",
              font: { weight: "bold", size: 12 },
              formatter: (value) => `${value}%`,
              anchor: "center",
              align: "center",
              textAlign: "center",
              padding: 6,
            },
          },
        },
      });
    }
  }, [assessmentData]);

  // Handle loading and error states
  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }
  if (error) {
    return <div className="container">Error: {error}</div>;
  }
  if (!assessmentData) {
    return <div className="container">No report data available</div>;
  }

 


  const groupedQuestions = assessmentData.questionsList.reduce(
    (acc, question) => {
        acc[question.section] ??= [];
        acc[question.section].push(question);
        return acc;
    },
    {}
);

  const handleTranslate = async () => {
    console.log("handleTranslate");
    navigate(`/hr/psychometric-report/${apct_id}/en`, { replace: true });
  }
 

  return (
    <div className="container">
      {applicantLanguage !== "en" ? (
      <div className="translate-row">
        <button
          className="translate-btn"
          onClick={handleTranslate}
        >
          Translate to English
        </button>

        <Button
          variant="contained"
          color="primary"
          onClick={openShareModal}
          sx={{ ml: 2 }}
        >
          Share
        </Button>
      </div>
    ) : (<div className="translate-row">
      

      <Button
        variant="contained"
        color="primary"
        onClick={openShareModal}
        sx={{ ml: 2 }}
      >
        Share
      </Button>
    </div>)}
      {/* Header */}
      <div className="header" >
        <div className="flex">
          <div>
            <h1>Psychometric Assessment Report</h1>
            <div className="info">
              <span>Date: {assessmentData.assessmentDate}</span>
              <span>Duration: {assessmentData.duration}</span>
            </div>
          </div>
          <div className="right">
            <p className="name">{assessmentData.candidateName}</p>
            <p>{assessmentData.position}</p>
          </div>
        </div>
      </div>

      {/* Overall Score */}
      <div className="grid-3">
        <div className="score-card">
          <h2>
            Overall Assessment Score{" "}
            <span className="overall">{assessmentData.overallScore}%</span>
          </h2>
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${assessmentData.overallScore}%` }}
            ></div>
          </div>
          <div className="details">
            Based on {assessmentData.totalQuestions} out of 25 questions across 3 key
            areas
          </div>
        </div>
        <div className="score-card overview-card">
          <h3>Assessment Overview</h3>
          <div className="stat">
            <div className="label">Total Questions</div>
            <div className="value">{assessmentData.totalQuestions}/25</div>
          </div>
          <div className="stat">
            <div className="label">Areas Assessed</div>
            <div className="value">{assessmentData.sections.length}</div>
          </div>
        </div>
      </div>

      {/* Dimensional Analysis */}
      <div className="analysis-card">
        <h2>Dimensional Analysis</h2>
        <div className="grid-2">
          <div className="chart-container">
            <canvas id="sectionsChart"></canvas>
          </div>
          <div>
            {assessmentData.sections.map((section, index) => (
              <div key={index} className="section-item">
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <h3>{section.name}</h3>
                    <p>{section.questions} questions</p>
                  </div>
                  <span className="score">{section.score}%</span>
                </div>
                <div className="progress-bar">
                  <div
                    className="progress"
                    style={{ width: `${section.score}%` }}
                  ></div>
                </div>
                <p>{section.interpretation}</p>
                <div className="traits">
                  {section.traits.map((trait, i) => (
                    <span key={i} className="trait">
                      {trait}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Key Findings */}
      <div className="grid-2">
        <div className="findings-card">
          <h2>Key Strengths</h2>
          <ul className="findings-list">
            {assessmentData.keyStrengths.map((strength, index) => (
              <li key={index} className="finding-item">
                <span className="bullet-green"></span>
                <span className="finding-text">{strength}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="findings-card">
          <h2>Development Areas</h2>
          <ul className="findings-list">
            {assessmentData.developmentAreas.map((area, index) => (
              <li key={index} className="finding-item">
                <span className="bullet-blue"></span>
                <span className="finding-text">{area}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Detailed Questions List */}
      <div className="questions-card">
        <h2>Detailed Questions List</h2>
        <div className="questions-list">
          {Object.entries(groupedQuestions).map(
            ([section, questions], sectionIndex) => (
              <div key={sectionIndex} className="section-group">
                <h3 className="section-heading">{section}: {questions.length} Questions</h3>
                <div className="questions-grid">
                  {questions?.map((item, index) => (
                    <div key={index} className="question-item">
                      <h4>
                        {index + 1}. {item.question}
                      </h4>
                      <ul className="options-list">
                        {item?.options?.map((option, i) => (
                          <li
                            key={i}
                            className={`option-item ${
                              option === item.selectedAnswer ? "selected" : ""
                            }`}
                          >
                            {option}
                            {option === item.selectedAnswer && (
                              <span className="answer-tag">Selected</span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
      </div>

      {/* Footer */}
      <div className="footer">
        <div className="flex">
          <div>
            <div className="icon-text">
              <svg
                className="icon"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <p className="text-sm font-medium text-gray-700">
                AI-Generated Assessment Report
              </p>
            </div>
            <p className="text-sm text-gray-600">
              This assessment follows the BAT (Behavioral Assessment Technology)
              framework, ensuring:
            </p>
            <ul>
              <li>Standardized evaluation methodology</li>
              <li>Scientifically validated criteria</li>
              <li>Comprehensive personality and skills assessment</li>
              <li>Objective leadership potential measurement</li>
            </ul>
            <div className="small">
              <p>
                © 2025 - Generated using AI-powered technology per BAT
                guidelines.
              </p>
              <p className="mt-1">
                BAT ensures reliability and validity in psychometric
                evaluations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={isShareModalOpen} onClose={closeShareModal}>
              <DialogTitle>Share Report</DialogTitle>
              <DialogContent>
              <Box display="flex" alignItems="center">
                  <TextField
                    value={shareableUrl}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <IconButton
                    onClick={handleCopy}
                    color="primary"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeShareModal} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
    </div>
  );
};

export default PsychometricAssessmentReport;
