import { useEffect, useState, useRef, useCallback } from "react";
import {
  Typography,
  Box,
  Button,
  Dialog,
  MenuItem,
  Select,
  Grid,
  LinearProgress,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import React from "react";
import { connect } from "react-redux";
import AIInterview from "./AIInterview";
import { useNavigate } from "react-router-dom";
import useFullScreen from "../../hooks/fullScreenWindow";
import NetworkInfo from "../../components/candidate/NetworkInfo";
import OperatingSystemCheck from "../../components/OperatingSystemCheck";
import { hideSidebar, showSidebar } from "../../redux/actions/sidebar";
import {
  _getInterviewStatus,
  _getSampleTextTrannslated,
} from "../../services/candidate.service.js";
import {
  _saveApplicantPicture,
  _getApplicant,
} from "../../services/applicant.service";
import Loader from "../../components/Loader.jsx";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
const CandidateDashboard = ({ auth: { user }, hideSidebar }) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [mcqCompleted, setMcqCompleted] = useState(false);
  const [codingCompleted, setCodingCompleted] = useState(false);
  const { isFullScreen, enterFullScreen } = useFullScreen();
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState("");
  const [selectedAudioDevice, setSelectedAudioDevice] = useState("");
  const [deviceConfirmed, setDeviceConfirmed] = useState(false);
  // const [selectedDevice, setSelectedDevice] = useState("");
  const [openDeviceDialog, setOpenDeviceDialog] = useState(false);
  const [microphoneQuality, setMicrophoneQuality] = useState(0); // Microphone quality as a percentage
  const [isMuted, setIsMuted] = useState(true);
  const [pictureTaken, setPictureTaken] = useState(false);
  const [speed, setSpeed] = useState("");
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [file, setFile] = useState(null);
  const [ip, setIP] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoDebug, setVideoDebug] = useState(null);
  const [videoRecorded, setVideoRecorded] = useState(false); // Track if the video has been recorded
  const [recordingText, setRecordingText] = useState(
    "Please record a sample video and speak something for sample"
  );
  const [recordingInProgress, setRecordingInProgress] = useState(false); // Track if video recording is in progress
  const streamRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const navigate = useNavigate();
  const [startInterviewButton, setStartInterviewButton] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPictureAvailable, setIsPictureAvailable] = useState(false);
  const [isShowDefaultLoader, setIsShowDefaultLoader] = useState(true);
  const [applicant, setApplicant] = useState(null);
  const [interviewStatus, setInterviewStatus] = useState(null);
  const [interviewEnded, setInterviewEnded] = useState(false);
  const [enableStopRecording, setEnableStopRecording] = useState(false);
  const [sampleText, setSampleText] = useState("");
  const [translatedStatus, setTranslatedStatus] = useState(false);
  const [language, setLanguage] = useState("en-US"); // Default language
  const videoRef = useCallback(
    (node) => {
      if (node && selectedVideoDevice) {
        startVideo(selectedVideoDevice, node);
      }
    },
    [selectedVideoDevice]
  );

  useEffect(() => {
    hideSidebar();
  }, [hideSidebar]);

  // Add this useEffect to start listening when recording starts
  useEffect(() => {
    if (recordingInProgress) {
      SpeechRecognition.startListening({
        language: language,
        continuous: true,
      });
    } else {
      SpeechRecognition.stopListening();
      resetTranscript();
    }
  }, [recordingInProgress, resetTranscript]);

  // Add this useEffect to check if the paragraph is fully read
  useEffect(() => {
    if (transcript.toLowerCase().includes("hello, my name is")) {
      // The paragraph is fully read
      setRecordingText("Paragraph fully read. You can stop recording.");
      setEnableStopRecording(true);
    }
  }, [transcript]);

 

  // Fetch applicant and interview status
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user && user.u_apct_id_fk) {
          const intStatus = await _getInterviewStatus(user.u_apct_id_fk);
          setInterviewStatus(intStatus?.data);
          const apctData = await _getApplicant(user.u_apct_id_fk);
          setApplicant(apctData?.data?.applicant);
        }
      } catch (error) {
        console.error(
          "Error fetching interview status or applicant data:",
          error
        );
      } finally {
        setIsShowDefaultLoader(false);
      }
    };

    fetchData();
  }, [user, startInterviewButton, interviewEnded]);

  // Request permissions and detect video devices
  useEffect(() => {
    const getPermissionsAndDevices = async () => {
      try {
        // Request permissions for video and audio
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop()); // Stop the stream after permissions are granted

        // Enumerate video input devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setVideoDevices(videoDevices);
        const audioDevices = devices.filter((d) => d.kind === "audioinput");
        setAudioDevices(audioDevices);

        // // Automatically select the first device
        // if (videoDevices.length > 0) {
        //   selectedAudioDevice(videoDevices[0].deviceId);
        // }
      } catch (error) {
        console.error(
          "Error requesting permissions or fetching video devices:",
          error
        );
      }
    };
    getPermissionsAndDevices();
  }, []);

  // Set MCQ and Coding test completion state
  useEffect(() => {
    if (applicant) {
      setMcqCompleted(!applicant.apct_mcq && applicant.apct_mcq_complete);
      setCodingCompleted(
        !applicant.apct_coding && applicant.apct_coding_complete
      );
      if (applicant.apct_picture) {
        setIsPictureAvailable(true);
        // checkDeviceConfirm();
      }
    }
  }, [applicant]);

  useEffect(() => {
    if (isPictureAvailable) {
      checkDeviceConfirm();
    }
  }, [isPictureAvailable]);


  const startRecording = async () => {
    try {
      if (!selectedVideoDevice || !selectedAudioDevice) {
        alert("Please select a video and audio device first.");
        return;
      }

      // Request audio and video streams
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: selectedVideoDevice } },
        audio: { deviceId: { exact: selectedAudioDevice } },
      });

      // Create a MediaRecorder
      const recorder = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });

      const chunks = []; // Local array to store recorded chunks

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      recorder.onstop = () => {
        // Combine recorded chunks into a single Blob
        const blob = new Blob(chunks, { type: "video/webm" });
        // Combine recorded chunks into a single Blob
        // Create a File object for further processing (e.g., upload)
        const videoFile = new File([blob], "recorded-video.webm", {
          type: "video/webm",
        });

        // Set the video file for uploading or other uses
        setVideo(videoFile); // Save the File object in state

        // Optionally, create an Object URL for playback (not necessary if you don't want to play locally)
        const videoUrl = URL.createObjectURL(blob);
        // console.log("Video URL for playback:", videoUrl); // For debugging, remove this line if not needed.
        setVideoDebug(videoUrl);

        setRecordedChunks([]); // Clear recorded chunks

        // Stop all tracks to free up resources
        stream.getTracks().forEach((track) => track.stop());
      };

      recorder.start();
      setMediaRecorder(recorder);
      setRecordingInProgress(true);
      SpeechRecognition.startListening({
        language: language,
        continuous: true,
      }); // Start speech recognition
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop(); // This triggers the `onstop` event defined in `startRecording`
      setMediaRecorder(null);
      setRecordingInProgress(false);
      SpeechRecognition.stopListening(); // Stop speech recognitio
    }
  };

  // Start video preview
  const startVideo = async (deviceId, videoElement) => {
    try {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }

      // Enable only the video stream initially
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: deviceId } },
        audio: false, // Audio is disabled by default
      });

      videoElement.srcObject = videoStream;
      videoElement.play();
      streamRef.current = videoStream; // Save the video stream reference
    } catch (error) {
      console.error("Error starting video stream:", error);
    }
  };

  const startAudio = async (audioDeviceId) => {
    try {
      // Enable audio stream when a microphone is selected
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: audioDeviceId } },
      });

      // Check microphone quality for the selected audio device
      checkMicrophoneQuality(audioStream);
    } catch (error) {
      console.error("Error starting audio stream:", error);
    }
  };

  const handleAudioDeviceSelection = async (event) => {
    const audioDeviceId = event.target.value;
    setSelectedAudioDevice(audioDeviceId);

    // Start the audio stream and check quality
    startAudio(audioDeviceId);
  };

  // Check microphone quality and mute status
  const checkMicrophoneQuality = async (stream) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(stream);

    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    source.connect(analyser);

    audioContextRef.current = audioContext;
    analyserRef.current = analyser;
    dataArrayRef.current = dataArray;

    let silentFrames = 0; // Count of consecutive silent frames
    const silenceThreshold = 50; // Number of frames to confirm mute
    const silenceLevel = 80; // Adjusted threshold for silence detection

    const analyzeAudio = () => {
      analyser.getByteTimeDomainData(dataArray);
      const sum = dataArray.reduce(
        (acc, value) => acc + Math.abs(value - 128),
        0
      );
      const average = sum / dataArray.length;

      const quality = Math.min((average / 128) * 100, 100); // Calculate quality as a percentage
      setMicrophoneQuality(quality);
      // console.log("==========>",sum);

      // const isAudioSilent = sum < 80;
      // Increment or reset silentFrames based on audio input level
      if (sum < silenceLevel) {
        silentFrames++;
      } else {
        silentFrames = 0;
      }
      const isAudioSilent = silentFrames > silenceThreshold;
      if (stream.getAudioTracks()[0].muted) {
        setIsMuted(true);
      } else if (isAudioSilent) {
        setIsMuted(true);
      } else {
        setIsMuted(false);
      }
      requestAnimationFrame(analyzeAudio);
    };

    analyzeAudio();
  };

  const fetchIP = async () => {
    try {
      // Using ipify API
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      setIP(data.ip);
    } catch (error) {
      console.error("Error fetching IP: ", error);
    }
  };

  const translateSampleText = async () => {
    const languageData = applicant.apct_interview_language
      ? JSON.parse(applicant.apct_interview_language)
      : { code: "en", label: "English" };
    if (languageData.label.toLowerCase() !== "english") {
      setLanguage(languageData.code);
      const data = {
        text: `Hello, my name is ${applicant?.apct_name}. I am excited to be here today. Clear communication is essential in any professional setting, and this sample recording helps ensure my audio quality and fluency. I will speak naturally and confidently, maintaining a steady pace. This test ensures that my voice is clear, my pronunciation is accurate, and there are no background disturbances. I am now ready to proceed with the interview. Thank you!`,
        language: languageData.label,
      };
      const translatedText = await _getSampleTextTrannslated(data);
      console.log(`tra`, translatedText);
      if (translatedText.status === 200) {
        setSampleText(translatedText.data.message);
      }
    } else {
      setSampleText(
        `Hello, my name is ${applicant?.apct_name}. I am excited to be here today. Clear communication is essential in any professional setting, and this sample recording helps ensure my audio quality and fluency. I will speak naturally and confidently, maintaining a steady pace. This test ensures that my voice is clear, my pronunciation is accurate, and there are no background disturbances. I am now ready to proceed with the interview. Thank you!`
      );
    }
    setTranslatedStatus(true);
  };

  useEffect(() => {
    fetchIP();
  }, []);
  const saveFile = async () => {
    setIsSaving(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("ip", ip);
      formData.append("sample-video", video);
      const saveResponse = await _saveApplicantPicture(formData);
      if (saveResponse.status === 200) {
        // console.log('save picture good');
        setStartInterviewButton(true);
      }
    } catch (error) {
      console.error("Error saving file:", error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (file) {
      saveFile();
    }
  }, [file]);

  const takePicture = () => {
    const canvas = document.createElement("canvas");
    const video = document.querySelector("#picture-capture-video");
    if (!video) {
      console.error("Video element not found for picture capture.");
      return;
    }
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const picture = canvas.toDataURL("image/png");
    // console.log("Picture captured: ", picture);
    setPictureTaken(true);

    const byteString = atob(picture.split(",")[1]);
    const mimeString = picture.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    const file = new File([blob], "captured-image.png", { type: "image/png" });

    // Set the file to state for upload
    setFile(file);
  };

  const handleJoin = (id) => {
    navigate(`/meeting/${id}`);
  };

  const handleCancel = () => {
    setOpenDeviceDialog(false);
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  const confirmDeviceSelection = () => {
    if (selectedVideoDevice && selectedAudioDevice && !isMuted) {
      localStorage.setItem("selectedDeviceId", selectedVideoDevice);
      localStorage.setItem("selectedDeviceIdAudio", selectedAudioDevice);
      setDeviceConfirmed(true);
      setOpenDeviceDialog(false);
      takePicture();
      // Stop video stream to save resources
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    } else {
      alert("Please select all required perameter");
    }
  };

  const checkDeviceConfirm = () => {
    const selectedVdevice = localStorage.getItem("selectedDeviceId");
    const selectedAdevice = localStorage.getItem("selectedDeviceIdAudio");
    if (selectedVdevice && selectedAdevice) {
      setDeviceConfirmed(true);
      setStartInterviewButton(true);
    } else {
      setIsPictureAvailable(false);
    }
  };

  const handleStartMcqTest = () => {
    enterFullScreen();
    navigate("/candidate/mcq-test");
  };

  const handleStartMcqTestPractical = () => {
    enterFullScreen();
    navigate("/candidate/practical-test");
  };

  const handleStartMcqTestPsycometric = () => {
    enterFullScreen();
    navigate("/candidate/psycometric-test");
  };

  const handleStartCodingTest = () => {
    enterFullScreen();
    navigate("/candidate/coding-test");
  };

  useEffect(() => {
    if (applicant?.apct_interview_language  && !translatedStatus) {
      translateSampleText(sampleText);
    }
  }, [applicant]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Your browser does not support speech recognition.</span>;
  }

  // console.log('interviewStatus', applicant);
  return (
    <>
      {isShowDefaultLoader && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <Loader />
        </div>
      )}
      {isSaving && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <Loader />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Saving your profile...
          </Typography>
        </div>
      )}
      {/* Test Buttons */}
      {applicant?.apct_mcq || applicant?.apct_coding || interviewStatus ? (
        isPictureAvailable ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            {!mcqCompleted &&
            applicant?.apct_mcq &&
            !applicant?.apct_is_practical &&
            !applicant?.apct_is_psycometric ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleStartMcqTest}
              >
                Start MCQ Test
              </Button>
            ) : !codingCompleted &&
              applicant?.apct_coding &&
              !applicant?.apct_is_practical &&
              !applicant?.apct_is_psycometric ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleStartCodingTest}
              >
                Start Coding Test
              </Button>
            ) : (
              interviewStatus &&
              interviewStatus.apct_stage !== "completed" &&
              deviceConfirmed &&
              !applicant?.apct_is_practical &&
              !applicant?.apct_is_psycometric && (
                <AIInterview
                  userId={user && user.u_id}
                  userConsent={applicant?.apct_user_consent}
                  InterViewStage={interviewStatus.apct_stage}
                  interViewThreadId={applicant?.apct_int_thread_id}
                  streamRef={streamRef}
                  startInterviewButton={startInterviewButton}
                  interviewEnded={interviewEnded}
                  setInterviewEnded={setInterviewEnded}
                  language={language}
                />
              )
            )}

            {applicant && applicant?.apct_is_practical && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleStartMcqTestPractical}
              >
                Start Practical MCQ Test
              </Button>
            )}

          
            {/* {applicant &&
              applicant?.apct_is_psycometric &&
              applicant.apct_psycometric_stage === "invited" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleStartMcqTestPsycometric}
                >
                  Start Psychometric Test
                </Button>
              )} */}
          </Box>
        ) : (
          !isPictureAvailable &&
          interviewStatus &&
          interviewStatus.apct_stage !== "completed" && (
            <>
              {speed > 1 ? (
                !videoRecorded ? (
                  <Grid container>
                    {/* Left Section */}
                    <Grid item xs={6} style={{ padding: "20px" }}>
                      <video
                        ref={videoRef}
                        style={{
                          width: "100%",
                          height: "auto",
                          border: "1px solid #ccc",
                          borderRadius: "10px",
                        }}
                        autoPlay
                        playsInline
                        muted
                      />
                    </Grid>

                    {/* Right Section */}
                    <Grid item xs={6} style={{ padding: "20px" }}>
                      {!recordingInProgress && (
                        <>
                          <Typography variant="h5">
                            Select Devices (Current OS:
                            <OperatingSystemCheck />)
                          </Typography>
                          <Select
                            value={selectedVideoDevice}
                            onChange={(e) =>
                              setSelectedVideoDevice(e.target.value)
                            }
                            displayEmpty
                            fullWidth
                            style={{ marginTop: "20px" }}
                          >
                            <MenuItem value="" disabled>
                              Select Camera
                            </MenuItem>
                            {videoDevices.map((device) => (
                              <MenuItem
                                key={device.deviceId}
                                value={device.deviceId}
                              >
                                {device.label || `Camera ${device.deviceId}`}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select
                            value={selectedAudioDevice}
                            onChange={handleAudioDeviceSelection}
                            displayEmpty
                            fullWidth
                            style={{ marginTop: "20px" }}
                          >
                            <MenuItem value="" disabled>
                              Select Microphone
                            </MenuItem>
                            {audioDevices.map((device) => (
                              <MenuItem
                                key={device.deviceId}
                                value={device.deviceId}
                              >
                                {device.label ||
                                  `Microphone ${device.deviceId}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                      <Box
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          gap: "10px",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{ marginTop: "10px" }}
                        >
                          Microphone Status:{" "}
                          {isMuted ? (
                            <MicOffIcon color="error" />
                          ) : (
                            <>
                              <MicIcon color="primary" />
                              <LinearProgress
                                variant="determinate"
                                value={microphoneQuality}
                                style={{ height: "10px", borderRadius: "5px" }}
                              />
                            </>
                          )}
                        </Typography>
                        {!videoRecorded && (
                          <>
                            <Typography
                              variant="h6"
                              color="primary"
                              style={{ marginBottom: "10px" }}
                            >
                              {recordingText}
                            </Typography>
                            <Box sx={{ textAlign: "center", margin: "auto" }}>
                              {!recordingInProgress ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    startRecording();
                                    setRecordingInProgress(true);
                                  }}
                                >
                                  Record a Sample Video
                                </Button>
                              ) : (
                                <>
                                  <Typography
                                    variant="h5"
                                    sx={{
                                      maxWidth: {
                                        xs: "90%",
                                        sm: "80%",
                                        md: "600px",
                                      }, // Responsive width
                                      margin: "auto",
                                      textAlign: "left", // or "justify"
                                    }}
                                  >
                                    Please read below paragraph
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      maxWidth: {
                                        xs: "90%",
                                        sm: "80%",
                                        md: "600px",
                                      }, // Responsive width
                                      margin: "auto",
                                      textAlign: "left", // or "justify"
                                    }}
                                  >
                                    {sampleText}
                                  </Typography>

                                  <Typography
                                    variant="body1"
                                    sx={{
                                      maxWidth: {
                                        xs: "90%",
                                        sm: "80%",
                                        md: "600px",
                                      }, // Responsive width
                                      margin: "auto",
                                      textAlign: "left", // or "justify"
                                      marginTop: "15px",
                                    }}
                                  >
                                    <b>Transcription</b>: {transcript}
                                  </Typography>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      stopRecording();
                                      setRecordingInProgress(false);
                                      setVideoRecorded(true); // Mark video recording as complete
                                    }}
                                  >
                                    Stop Recording
                                  </Button>
                                </>
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={confirmDeviceSelection}
                      >
                        Confirm and Proceed
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          setVideoRecorded(false);
                          setRecordedChunks([]);
                          setMediaRecorder(null);
                        }}
                      >
                        Re-record Video
                      </Button>
                    </Box>
                    {videoDebug && (
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <video
                          id="picture-capture-video"
                          controls
                          style={{
                            width: "50%",
                            height: "auto",
                            border: "2px solid #1976d2",
                            borderRadius: "15px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            // transform: "scaleX(-1)", // Mirror effect
                          }}
                          src={videoDebug}
                        ></video>
                      </Box>
                    )}
                  </>
                )
              ) : (
                "Poor network please ensure a stable and high-quality network connection to resolve the connectivity issue."
              )}
              <NetworkInfo speed={speed} setSpeed={setSpeed} />
            </>
          )
        )
      ) : (
        <Typography variant="subtitle2" textAlign="center" color="primary">
          No tests available.
        </Typography>
      )}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >

        {interviewStatus?.apct_is_psycometric && interviewStatus?.apct_psycometric_stage === "invited" && (
            <Button variant="contained" color="primary" onClick={handleStartMcqTestPsycometric}>
              Start Psychometric Test
            </Button>
          )}
        {/* Completed Interview Message */}
        {interviewStatus?.apct_stage === "completed" && interviewStatus?.apct_gen_completed_message && (
          <Typography variant="subtitle2" color="primary">
            Your AI interview is completed, HR will get back to you.
          </Typography>
        )}

        {interviewStatus?.apct_is_psycometric &&
          interviewStatus?.apct_psycometric_stage === "completed" && interviewStatus?.apct_psycometric_completed_message &&(
            <Typography variant="subtitle2" color="primary">
              Your Psychometric Assessment is completed, HR will get back to
              you.
            </Typography>
          )}
      </Box>

      {/* {interviewStatus && interviewStatus.apct_stage === "completed" && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle2" textAlign="center" color="primary">
            Your AI interview is completed, HR will get back to you.
          </Typography>
        </Box>
      )}

      {interviewStatus && interviewStatus.apct_is_psycometric &&   interviewStatus.apct_psycometric_stage === "completed" && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle2" textAlign="center" color="primary">
            Your Psychometric Assessment is completed, HR will get back to you.
          </Typography>
        </Box>
      )} */}

{interviewStatus && interviewStatus.apct_stage === "completed" && interviewStatus.apct_sec_round_scheduled_status === 'Invited' && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle2" textAlign="center" color="primary">
            You are invited for the further stage of interview.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleJoin(applicant.apct_sec_round_room_id)}
            style={{ marginTop: "10px" }} >
            Join Interview
          </Button>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { hideSidebar };

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDashboard);
