import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
  Typography,
  Autocomplete,
  CircularProgress,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Select,
  MenuItem
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { fetchTemplates } from "../../redux/actions/email_template";
import { get_agent_without_pagination } from "../../services/agent.service";
import { getQuestionSetsJobList } from "../../redux/actions/questions";
import { getJob } from "../../redux/actions/jobs";

const SendInviteDialog = ({
  open,
  handleClose,
  confirmAndSendInvite,
  openConfirmationDialog,
  closeConfirmationDialog,
  mcqChecked,
  setMcqChecked,
  codeChecked,
  setCodeChecked,
  aiAssistanceCheck,
  setAiAssistanceCheck,
  emailContent,
  setEmailContent,
  setEmailSubject,
  mcqCount,
  handleMcqCountChange,
  mcqError,
  codeCount,
  handleCodeCountChange,
  codeError,
  interviewError,
  mcqTime,
  handleMcqTimeChange,
  codeTime,
  handleCodeTimeChange,
  aiInterviewTime,
  linkExpTime,
  handleAiInterviewTimeChange,
  handleLinkExpTimeChange,
  showConfirmationDialog,
  reschedule,
  fetchTemplates,
  templates,
  loading,
  optionType,
  setOptionType,
  setPracticalOption,
  practicalOption,
  job_id,
  getQuestionSetsJobList,
  question_sets,
  getJob,
  job,
  interviewCount,
  handleInterviewCountChange
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState("default");
  const [selectedQuestionSetId, setSelectedQuestionSetId] = useState(null);
  const [selectQuestionSet, setSelectQuestionSet] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState({ code: "en", label: "English" });
  const defaultLanguage = { code: "en", label: "English" };
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [allAgents, setAllAgents] = useState([]);
  const orgName = localStorage.getItem('org_name');
  
  const handleOptionAgentChange = (value) => {
    if (!value) {
      const defaultAgent = allAgents.find(
        (agent) => agent.agent_id === "default"
      );
      setSelectedAgentId(defaultAgent.agent_id);
      setSelectedAgent(defaultAgent);
    } else {
      setSelectedAgentId(value.agent_id);
      setSelectedAgent(value);
    }
  };

  const handleOptionQuestionChange = (value) => {
    if (!value) {
      if (job && job.jb_question_set_id_fk) {
        const defaultQuestionSet = question_sets.find(
          (item) => item.qs_id === job.jb_question_set_id_fk
        );
        setSelectedQuestionSetId(defaultQuestionSet?.qs_id || null);
        setSelectQuestionSet(defaultQuestionSet || null);
      } else {
        setSelectedQuestionSetId(null);
        setSelectQuestionSet(null);
      }
    } else {
      setSelectedQuestionSetId(value.qs_id);
      setSelectQuestionSet(value);
    }
  };

  useEffect(() => {
    if (job_id) {
      getJob(job_id);
    }
  }, [job_id]);

  useEffect(() => {
    if (job && job?.jb_question_set_id_fk) {
      setSelectedQuestionSetId(job.jb_question_set_id_fk);
      const defaultQuestionSet = question_sets.filter((item) => item.qs_id === job.jb_question_set_id_fk);
      setSelectQuestionSet(defaultQuestionSet[0]);
    }
  }, [job]);

  useEffect(() => {
    if (open) {
      fetchTemplates(0, 10, "et_name", "asc", "");
    }
  }, [open, fetchTemplates]);

  useEffect(() => {
    if (selectedTemplate) {
      setEmailContent(selectedTemplate.et_body || "");
      setEmailSubject(selectedTemplate.et_subject || "");
    }
  }, [selectedTemplate, setEmailContent, setEmailSubject]);

  useEffect(() => {
    getQuestionSetsJobList();
  }, []);

  const getAllAgents = async () => {
    const result = await get_agent_without_pagination();
    if (result?.status === 200) {
      const defaultAgent = { agent_id: "default", agent_name: "Default Agent" };
      const agentsWithDefault = [defaultAgent, ...result.data.agents];
      setAllAgents(agentsWithDefault);
      setSelectedAgentId(defaultAgent?.agent_id);
      setSelectedAgent(defaultAgent);
    } else {
      console.log(result?.data?.error || "Failed to fetch agents!");
    }
  };

  useEffect(() => {
    getAllAgents();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: "white",
          },
        }}
      >
        <DialogTitle>
          Send Invitation
          <Box sx={{ float: 'right' }}>
            <Autocomplete
              options={[
                { code: "en", label: "English" },
                { code: "fr", label: "French" },
                { code: "es", label: "Spanish" },
                { code: "de", label: "German" },
                { code: "it", label: "Italian" },
                { code: "zh", label: "Chinese" },
                { code: "ja", label: "Japanese" },
                { code: "hi", label: "Hindi" },
              ]}
              getOptionLabel={(option) => option.label}
              loading={loading}
              value={selectedLanguage}
              onChange={(e, value) => setSelectedLanguage(value || defaultLanguage)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Language"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              // sx={{ width: 200 }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend">Select Type</FormLabel>
            <RadioGroup
              row
              value={optionType}
              onChange={(e) => setOptionType(e.target.value)}
            >
              <FormControlLabel value="Standard" control={<Radio />} label="Standard" />
              {orgName === 'org_girikon_india' && <FormControlLabel value="Practical" control={<Radio />} label="Practical" />}
              <FormControlLabel value="Psycomatric" control={<Radio />} label="Psychometric" />
            </RadioGroup>
          </FormControl>

          {optionType === "Practical" && (
            <Select
              value={practicalOption}
              onChange={(e) => setPracticalOption(e.target.value)}
              fullWidth
              variant="outlined"
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select a practical option
              </MenuItem>
              <MenuItem value="Plumber">Plumber</MenuItem>
            </Select>
          )}

          {optionType === "Standard" && (
            <>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mcqChecked}
                        onChange={(e) => setMcqChecked(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="MCQ"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={codeChecked}
                        onChange={(e) => setCodeChecked(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Code"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={aiAssistanceCheck}
                        onChange={(e) => setAiAssistanceCheck(e.target.checked)}
                        color="primary"
                        disabled={aiAssistanceCheck}
                      />
                    }
                    label="AI Interview"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mt: 2 }}>
                {mcqChecked ? (
                  <Grid item xs={4}>
                    <TextField
                      label="MCQ Count"
                      type="number"
                      value={mcqCount}
                      onChange={handleMcqCountChange}
                      error={mcqError}
                      helperText={mcqError ? "MCQs must be a multiple of 5 and within the range of 10 to 100." : ""}
                      variant="standard"
                    />
                  </Grid>
                ) : (<Grid item xs={4}></Grid>)}
                {codeChecked ? (
                  <Grid item xs={4}>
                    <TextField
                      label="Code Count"
                      type="number"
                      value={codeCount}
                      onChange={handleCodeCountChange}
                      error={codeError}
                      helperText={
                        codeError ? "Coding Questions must be in range of 2-10" : ""
                      }
                      variant="standard"
                    />
                  </Grid>
                ) : (<Grid item xs={4}></Grid>)}
                {aiAssistanceCheck && (
                  <Grid item xs={3}>
                    <TextField
                      label="Interview Count"
                      type="number"
                      value={interviewCount}
                      onChange={handleInterviewCountChange}
                      error={interviewError}
                      helperText={
                        interviewError ? "Interview questions must be between 5-10" : ""
                      }
                      variant="standard"
                    />
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={2} sx={{ mt: 2 }}>
                {mcqChecked ? (
                  <Grid item xs={4}>
                    <TextField
                      label="MCQ Time (minutes)"
                      type="number"
                      value={mcqTime}
                      onChange={handleMcqTimeChange}
                      variant="standard"
                    />
                  </Grid>
                ) : (<Grid item xs={4}></Grid>)}
                {codeChecked ? (
                  <Grid item xs={4}>
                    <TextField
                      label="Code Time (minutes)"
                      type="number"
                      value={codeTime}
                      onChange={handleCodeTimeChange}
                      variant="standard"
                    />
                  </Grid>
                ) : (<Grid item xs={4}></Grid>)}
                {aiAssistanceCheck && (
                  <Grid item xs={4}>
                    <TextField
                      label="AI Interview Time (minutes)"
                      type="number"
                      value={aiInterviewTime}
                      onChange={handleAiInterviewTimeChange}
                      variant="standard"
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField
                    label="Link Expire Time (hour)"
                    type="number"
                    value={linkExpTime}
                    onChange={handleLinkExpTimeChange}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Email Template:</Typography>
              <Autocomplete
                options={templates || []}
                getOptionLabel={(option) => option.et_name || ""}
                loading={loading}
                onChange={(e, value) => setSelectedTemplate(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Template"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                sx={{ mb: 2 }}
              />
            </Grid>

            {optionType !== "Practical" && (
              <Grid item xs={4}>
                <Typography variant="subtitle1">Question Set:</Typography>
                <Autocomplete
                  options={question_sets || []}
                  getOptionLabel={(option) => option.qs_name || ""}
                  loading={loading}
                  value={selectQuestionSet}
                  onChange={(e, value) => handleOptionQuestionChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Question Set"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  sx={{ mb: 2 }}
                />
              </Grid>
            )}

            {(optionType !== "Practical" && optionType !== "Psycomatric") && (
              <Grid item xs={4}>
                <Typography variant="subtitle1">Interview Agent:</Typography>
                <Autocomplete
                  options={allAgents || []}
                  getOptionLabel={(option) => option.agent_name || ""}
                  loading={loading}
                  value={selectedAgent}
                  onChange={(e, value) => handleOptionAgentChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Agent"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  sx={{ mb: 2 }}
                />
              </Grid>
            )}
          </Grid>

          <Box sx={{ mt: 3 }}>
            <ReactQuill
              theme="snow"
              value={emailContent}
              onChange={setEmailContent}
              style={{ height: "260px", marginBottom: "50px" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={openConfirmationDialog}
            color="primary"
            disabled={
              (!mcqChecked && !codeChecked && !aiAssistanceCheck) ||
              mcqError ||
              codeError
            }
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmationDialog}
        onClose={closeConfirmationDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to send this invitation?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmationDialog}>Cancel</Button>
          <Button 
            onClick={() => confirmAndSendInvite(selectedAgentId, selectedQuestionSetId, selectedLanguage)} 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  templates: state.emailTemplate.templates?.rows,
  loading: state.emailTemplate.loading,
  question_sets: state.questions.question_sets_list,
  job: state.jobs.job,
});

const mapDispatchToProps = {
  fetchTemplates,
  getQuestionSetsJobList,
  getJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendInviteDialog);