import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Avatar,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
//import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Plot from "react-plotly.js";
import { getAIInterviewScore } from "../../services/dashboard";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  PieChart,
  Pie,
  Cell,
} from "recharts";

import ProctorReport from "../../components/ProctorReport";
import { _getAiReport } from "../../services/leads.service";

const CandidateInfo = ({ candidate }) => (
  <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
    <Typography variant="h6" fontWeight="bold" sx={{ mb: 3 }}>
      Candidate Information
    </Typography>
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} md={3}>
        <Avatar src={candidate.photo} sx={{ width: "100%", height: "100%" }} />
      </Grid>
      <Grid item xs={12} md={7}>
        <Grid container spacing={2}>
          {[
            { label: "Full Name", value: candidate.name },
            { label: "Email", value: candidate.email },
            { label: "Position Applied", value: candidate.position },
            { label: "Experience", value: candidate.experience },
            { label: "Assessment Date", value: candidate.assessmentDate },
            // { label: "Location", value: candidate.location },
          ].map((info, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Typography variant="subtitle2" color="textSecondary">
                {info.label}
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {info.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </Paper>
);

const MCQSection = ({ mcq }) => {
  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 3 }}>
        MCQ Assessment
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <Grid container spacing={4} direction="column">
            {mcq.metrics.map((metric, index) => (
              <Grid item xs={12} md={3} key={index}>
                <Paper
                  sx={{
                    p: 1,
                    backgroundColor:
                      metric.label === "Total Questions"
                        ? "#e3f2fd"
                        : metric.label === "Correct Answers"
                        ? "#e8f5e9"
                        : metric.label === "Incorrect Answers"
                        ? "#ffebee"
                        : "#f3e5f5",
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {metric.label}
                  </Typography>
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    color={
                      metric.label === "Incorrect Answers"
                        ? "error"
                        : metric.label === "Accuracy"
                        ? "primary"
                        : "inherit"
                    }
                  >
                    {metric.value}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
              Performance Chart
            </Typography>
            <Plot data={mcq.chartData} layout={mcq.chartLayout} />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
          Detailed Question Analysis
        </Typography>
        {mcq.questions.map((question, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Typography
              variant="subtitle2"
              fontWeight="bold"
              color="primary"
              sx={{ mb: 1 }}
            >
              Question {index + 1}: {question.question}
            </Typography>
            {/* <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              Options: {question.options.join(", ")}
            </Typography> */}
            <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
              Options:
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              {question.options.map((option, optionIndex) => (
                <Grid item xs={6} key={optionIndex}>
                  <Typography variant="body2" color="textSecondary">
                    {String.fromCharCode(65 + optionIndex)}. {option}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Typography variant="body2" color="green">
              Correct Answer: {question.correctAnswer}
            </Typography>
            <Typography variant="body2" color="primary">
              Candidate's Answer: {question.candidateAnswer}
            </Typography>
            {/* <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              Feedback: {question.feedback}
            </Typography> */}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

const CodingSection = ({ codingChallenges }) => (
  <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 3,
      }}
    >
      <Typography variant="h6" fontWeight="bold">
        Coding Challenges
      </Typography>
    </Box>

    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} md={4}>
        <Grid container spacing={4} direction="column">
          {codingChallenges.metrics.map((metric, index) => (
            <Grid item xs={12} key={index}>
              <Paper
                sx={{
                  p: 1,
                  backgroundColor: metric.label.includes("Accuracy")
                    ? "#f3e5f5"
                    : metric.label.includes("Partial")
                    ? "#ffebee"
                    : metric.label.includes("Correct")
                    ? "#e8f5e9"
                    : "#e3f2fd",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  {metric.label}
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  color={
                    metric.label.includes("Partial")
                      ? "error"
                      : metric.label.includes("Accuracy")
                      ? "primary"
                      : "inherit"
                  }
                >
                  {metric.value}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12} md={8}>
        <Box>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
            Performance Chart
          </Typography>
          {/* <Plot
            data={codingChallenges.chartData}
            layout={codingChallenges.chartLayout}
          /> */}
          <Plot
            data={[
              {
                values: [
                  parseInt(
                    codingChallenges.metrics.find(
                      (m) => m.label === "Correct Solutions"
                    ).value,
                    10
                  ),
                  parseInt(
                    codingChallenges.metrics.find(
                      (m) => m.label === "Partial Solutions"
                    ).value,
                    10
                  ),
                ],
                labels: ["Correct Solutions", "Unanswered"],
                type: "pie",
                textinfo: "label+percent",
                hoverinfo: "label+value",
                marker: {
                  colors: ["#4caf50", "#f44336"],
                },
              },
            ]}
            layout={{
              title: "Coding Challenge Performance Distribution",
            }}
          />
        </Box>
      </Grid>
    </Grid>

    {codingChallenges.challenges.map((challenge, index) => (
      <Box sx={{ mb: 4, mt: 4 }} key={index}>
        <Typography variant="subtitle1" fontWeight="bold" color="primary">
          Question {index + 1}: {challenge.question}
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Paper
              sx={{ p: 2, flex: 1, display: "flex", flexDirection: "column" }}
            >
              <Typography variant="subtitle2" fontWeight="bold" color="green">
                Candidate's Solution:
              </Typography>
              <Box
                component="pre"
                sx={{
                  backgroundColor: "#2d2d2d",
                  color: "#f8f8f2",
                  fontFamily: "'Fira Code', monospace",
                  fontSize: "0.875rem",
                  p: 2,
                  overflow: "auto",
                  borderRadius: 1,
                  flex: 1,
                  border: "1px solid #444",
                  position: "relative",
                  counterReset: "linenumbers",
                }}
              >
                <Box
                  component="code"
                  sx={{
                    display: "block",
                    "&::before": {
                      content: 'counter(linenumbers) " "',
                      counterIncrement: "linenumbers",
                      display: "inline-block",
                      width: "2em",
                      textAlign: "right",
                      marginRight: "1em",
                      color: "#757575",
                    },
                  }}
                >
                  {challenge.candidateSolution}
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Paper
              sx={{ p: 2, flex: 1, display: "flex", flexDirection: "column" }}
            >
              <Typography variant="subtitle2" fontWeight="bold" color="blue">
                Optimal Solution:
              </Typography>
              <Box
                component="pre"
                sx={{
                  backgroundColor: "#2d2d2d",
                  color: "#f8f8f2",
                  fontFamily: "'Fira Code', monospace",
                  fontSize: "0.875rem",
                  p: 2,
                  overflow: "auto",
                  borderRadius: 1,
                  flex: 1,
                  border: "1px solid #444",
                  position: "relative",
                  counterReset: "linenumbers",
                }}
              >
                <Box
                  component="code"
                  sx={{
                    display: "block",
                    "&::before": {
                      content: 'counter(linenumbers) " "',
                      counterIncrement: "linenumbers",
                      display: "inline-block",
                      width: "2em",
                      textAlign: "right",
                      marginRight: "1em",
                      color: "#757575",
                    },
                  }}
                >
                  {challenge.optimalSolution}
                </Box>
              </Box>
            </Paper>
          </Grid> */}
        </Grid>

        {/* <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
            <Card>
                <CardHeader title="Score Distribution" />
                <CardContent>
                  <ResponsiveContainer width="100%" height={250}>
                    <RadarChart cx="50%" cy="50%" outerRadius="80%" data={challenge.scoreData}>
                      <PolarGrid />
                      <PolarAngleAxis dataKey="name" />
                      <PolarRadiusAxis />
                      <Radar
                        name="Score"
                        dataKey="value"
                        stroke="#8884d8"
                        fill="#8884d8"
                        fillOpacity={0.6}
                      />
                      <Tooltip />
                    </RadarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader title="Emotion Analysis" />
                <CardContent>
                  <ResponsiveContainer width="100%" height={250}>
                    <AreaChart data={challenge.emotionData}>
                      <defs>
                        <linearGradient id="emotionGradient" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#0ea5e9" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="#0ea5e9" stopOpacity={0.1} />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="name" />
                      <YAxis domain={[0, 10]} />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#0ea5e9"
                        fill="url(#emotionGradient)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid> */}

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="subtitle2" fontWeight="bold" color="orange">
                Feedback:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {challenge.feedback}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    ))}
  </Paper>
);

const AiSection = ({ aiAssessment }) => {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }} >
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 3 }}>
        {`Face to Face AI Assessment`}
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <Grid container spacing={4} direction="column" id="face-to-face-summary">
            {aiAssessment.metrics.map((metric, index) => (
              <Grid item xs={12} md={3} key={index} className="metric">
                <Paper
                  sx={{
                    p: 1,
                    backgroundColor:
                      metric.label === "Total Questions"
                        ? "#e3f2fd"
                        : metric.label === "Successful Attempts"
                        ? "#e8f5e9"
                        : metric.label === "Unanswered"
                        ? "#ffebee"
                        : "#f3e5f5",
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {metric.label}
                  </Typography>
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    color={
                      metric.label === "Unanswered"
                        ? "error"
                        : metric.label === "Overall Accuracy"
                        ? "primary"
                        : "inherit"
                    }
                  >
                    {metric.value}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
              Performance Chart
            </Typography>
            <Plot
              data={[
                {
                  values: [
                    parseFloat(
                      aiAssessment.metrics.find(
                        (m) => m.label === "Overall Accuracy"
                      ).value
                    ),
                    parseFloat(
                      100 -
                      parseFloat(aiAssessment.metrics.find(
                          (m) => m.label === "Overall Accuracy"
                        ).value)
                    ),
                  ],
                  labels: ["Accuracy (%)", "Inaccuracy (%)"],
                  type: "pie",
                  textinfo: "label+percent",
                  hoverinfo: "label+value",
                  marker: {
                    colors: ["#4caf50", "#f44336"],
                  },
                },
              ]}
              layout={{
                title: "AI Interview Performance Distribution",
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
          Detailed Question Analysis
        </Typography>
        {aiAssessment.questions.map((question, index) => {
          return (
            <Box key={index} sx={{ mb: 4 }} className="ai-question">
              <Typography
                className="ai-question"
                variant="subtitle2"
                fontWeight="bold"
                color="primary"
                sx={{ mb: 1 }}
              >
                {`Question ${index + 1}: ${question.question}`}
              </Typography>
              <Typography className="answer-text" variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                Candidate's Answer:
              </Typography>
              <Typography variant="body2" color="textPrimary" sx={{ mb: 2 }}>
                {question.candidateAnswer}
              </Typography>
              <Typography
                className="feedback-text"
                variant="body2"
                color={
                  question.feedback.includes("Excellent")
                    ? "green"
                    : question.feedback.includes("Good")
                    ? "orange"
                    : "textSecondary"
                }
              >
                Feedback: {question.feedback}
              </Typography>

              <Grid container spacing={4} sx={{ mt: 2 }} className="score-text">
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title="Score Distribution" />
                    <CardContent>
                      <ResponsiveContainer width="100%" height={250}>
                        <PieChart>
                          <Pie
                            data={question.scoreData}
                            cx="50%"
                            cy="50%"
                            labelLine={true}
                            label={({ name, value }) => `${name}: ${value}`}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            {question.scoreData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title="Emotion Analysis" />
                    <CardContent>
                      <ResponsiveContainer width="100%" height={250}>
                        <AreaChart data={question.emotionData}>
                          <defs>
                            <linearGradient
                              id="emotionGradient"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#0ea5e9"
                                stopOpacity={0.8}
                              />
                              <stop
                                offset="95%"
                                stopColor="#0ea5e9"
                                stopOpacity={0.1}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 10]} />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey="value"
                            stroke="#0ea5e9"
                            fill="url(#emotionGradient)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
    </Paper>
  );
};

const ProctoringSection = ({ proctoring }) => (
  <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
    <Typography variant="h6" fontWeight="bold" sx={{ mb: 3 }}>
      Proctoring Report
    </Typography>
    {/* <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2, backgroundColor: "#e8f5e9" }}>
          <Typography variant="subtitle1" fontWeight="bold">
            Session Timeline
          </Typography>
          <Box sx={{ mt: 2 }}>
            {proctoring.sessionTimeline.map((event, index) => (
              <Typography key={index} variant="body2">
                {event}
              </Typography>
            ))}
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2, backgroundColor: "#f3e5f5" }}>
          <Typography variant="subtitle1" fontWeight="bold">
            Environment Check
          </Typography>
          <Box sx={{ mt: 2 }}>
            {proctoring.environmentCheck.map((check, index) => (
              <Typography key={index} variant="body2">
                {check}
              </Typography>
            ))}
          </Box>
        </Paper>
      </Grid>
    </Grid> */}
    <ProctorReport studentData={proctoring} />
  </Paper>
);

const AssessmentSection = ({ overallFeedback }) => {
  const { finalFeedback, strengths, areasForImprovement, finalRecommendation } =
    overallFeedback;

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          AI Assessment Feedback
        </Typography>
        {/* <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            fontWeight="bold"
            color="primary"
            sx={{ mr: 2 }}
          >
            {finalFeedback}
          </Typography>
          <Typography variant="body2" color="success.main">
            ✓
          </Typography>
        </Box> */}
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
          Strengths
        </Typography>
        {strengths.map((strength, index) => (
          <Typography
            key={index}
            variant="body2"
            color="textSecondary"
            sx={{ mb: 1 }}
          >
            • {strength}
          </Typography>
        ))}
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
          Areas for Improvement
        </Typography>
        {areasForImprovement.map((area, index) => (
          <Typography
            key={index}
            variant="body2"
            color="textSecondary"
            sx={{ mb: 1 }}
          >
            • {area}
          </Typography>
        ))}
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
          Final Recommendation
        </Typography>
        <Typography variant="body2" color="textPrimary" sx={{ mb: 2 }}>
          {finalRecommendation}
        </Typography>
      </Box>
    </Paper>
  );
};

const ScoreSection = ({ data }) => {
  // Format number to 2 decimal places
  const formatValue = (value) => {
  // Convert value to string
  const str = value.toString();
  const isPercent = str.includes("%");
  
  // Remove any '%' characters before parsing the number
  const num = parseFloat(str.replace(/%/g, ""));
  if (isNaN(num)) return value;
  
  // Round the number to the nearest integer
  const rounded = Math.round(num);
  
  return isPercent ? `${rounded}%` : rounded.toString();
};
  return (
    <Box
      sx={{
        p: 3,
        mb: 4,
        background: "linear-gradient(to right, #2196f3, #21cbf3)",
        color: "white",
        borderRadius: 2,
      }}
    >
      <Grid container spacing={4}>
        {data.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Typography variant="subtitle1">{item.label}</Typography>
            <Typography variant="h4" fontWeight="bold">
             {`${formatValue(item.value)}/100`}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const ReportPageDemo = () => {
  const { apct_id } = useParams();
  const reportRef = useRef(null);

  

  const generatePDF = async () => {
    try {
      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margin = 15; // Increased margin for better readability
      const sectionSpacing = 10; // Spacing between sections
      const lineHeight = 8; // Line height for text
  
      // Helper to add a section title
      const addSectionTitle = (title, yPosition) => {
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");
        pdf.text(title, margin, yPosition);
        return yPosition + lineHeight;
      };
  
      // Helper to add an image
      const addImage = async (element, yPosition, maxHeight) => {
        const canvas = await html2canvas(element, { scale: 2, useCORS: true });
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        const finalHeight = Math.min(imgHeight, maxHeight);
  
        if (yPosition + finalHeight > pageHeight - margin - 20) {
          pdf.addPage();
          yPosition = margin + 10; // Add top padding on new pages
        }
  
        pdf.addImage(imgData, "JPEG", margin, yPosition, imgWidth, finalHeight);
        return yPosition + finalHeight + sectionSpacing;
      };
  
      let yPosition = margin + 10; // Add padding at the top of the first page
  
      // Header for all pages
      const addHeader = (pageNumber, totalPages) => {
        pdf.setFontSize(10);
        pdf.setFont("helvetica", "italic");
        pdf.text("Assessment Report", margin, margin);
        pdf.text(
          `Page ${pageNumber} of ${totalPages}`,
          pageWidth - margin,
          margin,
          { align: "right" }
        );
      };
  
      // Footer for all pages
      const addFooter = () => {
        const footerText = `© ${new Date().getFullYear()} Girikhire. All rights reserved.`;
        pdf.setFontSize(8);
        pdf.text(footerText, pageWidth / 2, pageHeight - 10, { align: "center" });
      };
  
      // Process content sections
      const reportContent = document.getElementById("report-content");
  
      // Summary Section
      const summarySection = reportContent.querySelector("#summary-section");
      if (summarySection) {
        yPosition = addSectionTitle("Summary", yPosition);
        yPosition = await addImage(summarySection, yPosition, pageHeight / 3);
      }
  
      // Candidate Information Section
      const candidateInfo = reportContent.querySelector("#candidate-info");
      if (candidateInfo) {
        yPosition = addSectionTitle("Candidate Information", yPosition);
        yPosition = await addImage(candidateInfo, yPosition, pageHeight / 3);
      }
  
      // MCQ Assessment Section
      const mcqSection = reportContent.querySelector("#mcq-section");
      if (mcqSection) {
        yPosition = addSectionTitle("MCQ Assessment", yPosition);
        yPosition = await addImage(mcqSection, yPosition, pageHeight / 3);
      }
  
      // Coding Challenges Section
      const codingSection = reportContent.querySelector("#coding-section");
      if (codingSection) {
        yPosition = addSectionTitle("Coding Challenges", yPosition);
        yPosition = await addImage(codingSection, yPosition, pageHeight / 3);
      }
  
      // AI Assessment Section
      const aiSection = reportContent.querySelector("#ai-assessment");
      if (aiSection) {
        yPosition = addSectionTitle("AI Assessment", yPosition);
        yPosition = await addImage(aiSection, yPosition, pageHeight / 2.5);
      }
  
      // Proctoring Report Section
      const proctoringSection = reportContent.querySelector("#proctoring-section");
      if (proctoringSection) {
        yPosition = addSectionTitle("Proctoring Report", yPosition);
        yPosition = await addImage(proctoringSection, yPosition, pageHeight / 3);
      }
  
      // Overall Feedback Section
      const feedbackSection = reportContent.querySelector("#feedback-section");
      if (feedbackSection) {
        yPosition = addSectionTitle("Overall Feedback", yPosition);
        yPosition = await addImage(feedbackSection, yPosition, pageHeight / 3);
      }
  
      // Add headers, footers, and save PDF
      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        addHeader(i, totalPages);
        addFooter();
      }
  
      pdf.save("assessment-report.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert("Failed to generate PDF. Please try again.");
    }
  };
  
  
  const navigate = useNavigate();

  const [reportData, setReportData] = useState({});

  const [loading, setLoading] = useState(true);

  const fetchAIScoreData = async () => {
    let interviewData = await _getAiReport(apct_id);
    setReportData(interviewData);
    setLoading(false);
  };

  useEffect(() => {
    fetchAIScoreData();
  }, []);

  const {
    candidate,
    summary,
    mcq,
    codingChallenges,
    aiAssessment,
    proctoring,
    overallFeedback,
  } = reportData;
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <img
                  src="https://hire.girikon.ai/wp-content/uploads/2025/02/GirikHire-logo.webp"
                  alt="GirikHire Logo"
                  style={{ height: "30px", width: "145px" }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Assessment Report
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Technical Evaluation
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={generatePDF}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {loading ? (
        <CircularProgress />
      ) : (
        <div id="report-content" ref={reportRef}>
          {summary !== null && summary !== undefined && (
            <div id="summary-section">
              <ScoreSection data={summary} />
            </div>
          )}
          {candidate !== null && candidate !== undefined && (
            <div id="candidate-info">
              <CandidateInfo candidate={candidate} />
            </div>
          )}

          {/* MCQ Assessment Section */}
          {mcq !== null && mcq !== undefined && (
            <div id="mcq-section">
              <MCQSection mcq={mcq} />
            </div>
          )}

          {/* Coding Challenges Section */}
          {codingChallenges !== null && codingChallenges !== undefined && (
            <div id="coding-section">
              <CodingSection codingChallenges={codingChallenges} />
            </div>
          )}

          {/* AI Assessment Section */}
          {aiAssessment !== null && aiAssessment !== undefined && (
            <div id="ai-assessment">
              <AiSection aiAssessment={aiAssessment} />
            </div>
          )}

          {/* Proctoring Report Section */}
          {proctoring !== null && proctoring !== undefined && (
            <div id="proctoring-section">
              <ProctoringSection proctoring={proctoring} />
            </div>
          )}

          {/* Overall Feedback Section */}
          {overallFeedback !== null && overallFeedback !== undefined && (
            <div id="feedback-section">
              <AssessmentSection overallFeedback={overallFeedback} />
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default ReportPageDemo;
