import { Route, Routes, Navigate } from "react-router-dom";
import store from '../redux/store.js';
import Dashboard from "../pages/hr/Dashboard.jsx";
import Jobs from "../pages/hr/Jobs.jsx";
import AddJob from "../pages/hr/AddJob.jsx";
import Batches from "../pages/hr/Batches.jsx";
import Candidates from "../pages/hr/Candidates.jsx";
import CandidateProfile from "../pages/hr/CandidateProfile.jsx";
import CreateBatch from "../pages/hr/CreateBatch.jsx";
import Interviews from "../pages/hr/Interviews.jsx";
import Employees from "../pages/hr/Employees.jsx";
import UsersProfile from "../pages/common/UsersProfile.jsx";
import JobDetails from "../pages/hr/JobDetails.jsx";
import BatchDetails from "../pages/hr/BatchDetails.jsx";
import Setting from "../pages/common/Setting.jsx";
import Skills from "../pages/hr/Skills.jsx";
import CandidateDetails from "../pages/hr/CandidateDetails.jsx";
import AiScoreReport from "../pages/hr/AiScoreReport.jsx";
import ReportPage from "../pages/hr/ReportPage.jsx";
import InterviewDetails from "../pages/hr/InterviewDetail.jsx";
import EmployeeProfile from "../pages/hr/EmployeeProfile.jsx";
import Label from "../pages/hr/Label.jsx";
import QuestionSet from "../pages/hr/QuestionSet.jsx";
import CreateQuestionSet from "../pages/hr/CreateQuestionSet.jsx";
import AccessControlManagement from "../pages/hr/AccessControlManagement.jsx";
import CreateQuestion from "../pages/hr/CreateQuestion.jsx";
import AddCandidate from "../pages/hr/AddCandidate.jsx";
import EmailTemplateList  from "../pages/hr/EmailTemplateList.jsx";
import EmailTemplateForm from "../pages/hr/EmailTemplateForm.jsx"
import AiAvatar from "../pages/hr/AiAvatar.jsx";
import CreateAiAgent from "../pages/hr/CreateAiAgent.jsx";
import AgentList from "../pages/hr/AgentList.jsx";
import ViewAiAgent from "../pages/hr/ViewAiAgent.jsx";
import PsychometricAssessmentReport from "../pages/hr/PsychometricAssessmentReport.jsx";
import PsychometricAssessmentReportEn from "../pages/hr/PsychometricAssessmentReportEn.jsx";
import ReportPageEn from "../pages/hr/ReportPageEn.jsx";
import VideoCall from "../pages/candidate/VideoCall.jsx";
import InterviewerVideoCall  from "../pages/interviewer/InterviewerVideoCall.jsx";

const HRRoutes = () => {
  const { user } = store.getState().auth;
  const { permissions } = user;

  // Define routes in an array for better organization
  const routes = [
    { path: "/hr/dashboard", element: <Dashboard /> },
    { path: "/hr/profile", element: <UsersProfile /> },
    { path: "/hr/setting", element: <Setting /> },
    { path: "/hr/ai_score/v1/:apct_id", element: <AiScoreReport /> },
    { path: "/hr/ai_score/v2/:apct_id", element: <ReportPage /> },
    { path: "/hr/ai_score/v2/:apct_id/:lan", element: <ReportPageEn /> },
    { path: "/hr/candidates/add-candidate/:batchId/:fileName", element: <AddCandidate /> },
    { path: "/hr/candidates/candidate-profile/:candidateId", element: <CandidateProfile /> },
    { path: "/hr/candidates/candidate-details/:candidateId", element: <CandidateDetails /> },
    { path: "/hr/candidates/interview-details/:candidateId/:threadId", element: <InterviewDetails /> },
    { path: "/hr/employees/candidate-details/:candidateId", element: <CandidateDetails /> },
    { path: "/hr/employees/employees-profile/:employeeId", element: <EmployeeProfile /> },
    { path: "/hr/email-templates", element: <EmailTemplateList /> },
    { path: "/hr/email-templates/new", element: <EmailTemplateForm /> },
    { path: "/hr/email-templates/edit/:id", element: <EmailTemplateForm /> },
    { path: "/hr/email-templates/view/:id", element: <EmailTemplateForm /> },
    { path: "/hr/ai-avatar", element: <AiAvatar /> },
    { path: "/hr/create-ai-agent", element: <CreateAiAgent /> },
    { path: "/hr/agents", element: <AgentList/> },
    { path: "/hr/edit-ai-agent/:id", element: <CreateAiAgent /> },
    { path: "/hr/view-ai-agent/:id", element: <ViewAiAgent /> },
    { path: "/hr/psychometric-report/:apct_id", element: <PsychometricAssessmentReport /> },
    { path: "/hr/psychometric-report/:apct_id/:lan", element: <PsychometricAssessmentReportEn /> },
    { path: "/hr/room/:roomId",  element: <VideoCall />},
    { path: "/hr/interview/:roomId", element: <InterviewerVideoCall  /> }

    
   
  ];

  return (
    <Routes>
      {routes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
      {permissions.includes('jd-read') && (
        <Route path="/hr/jd-list" element={<Jobs />} />
      )}
      {permissions.includes('jd-read') && (
        <Route path="/hr/jd-detail/:jd_id" element={<JobDetails />} />
      )}
      {permissions.includes('jd-create') && (
        <Route path="/hr/jd-list/create-jd" element={<AddJob />} />
      )}
      {permissions.includes('batch-read') && (
        <Route path="/hr/batches" element={<Batches />} />
      )}
      {permissions.includes('batch-read') && (
        <Route path="/hr/batches/batch-detail/:batch_id" element={<BatchDetails />} />
      )}
      {permissions.includes('batch-create') && (
        <Route path="/hr/batches/create-batch/:jd_id" element={<CreateBatch />} />
      )}
      {permissions.includes('candidate-read') && (
        <Route path="/hr/candidates" element={<Candidates />} />
      )}
      {permissions.includes('interview-read') && (
        <Route path="/hr/interviews" element={<Interviews />} />
      )}
      {permissions.includes('employee-read') && (
        <Route path="/hr/employees" element={<Employees />} />
      )}
      {permissions.includes('skill-read') && (
        <Route path="/hr/skills" element={<Skills />} />
      )}
      {permissions.includes('label-read') && (
        <Route path="/hr/label" element={<Label />} />
      )}
      {permissions.includes('question-read') && (
        <Route path="/hr/question-set" element={<QuestionSet />} />
      )}
      {permissions.includes('question-read') && (
        <Route path="/hr/question-set/create" element={<CreateQuestionSet />} />
      )}
      {permissions.includes('question-read') && (
        <Route path="/hr/question-set/:id" element={<CreateQuestionSet />} />
      )}
      {permissions.includes('question-read') && (
        <Route path="/hr/question-set/:id/create-question" element={<CreateQuestion />} />
      )}
      {user.u_is_super_admin && (
        <Route path="/hr/access-control" element={<AccessControlManagement />} />
      )}
      <Route path="*" element={<Navigate to="/hr/dashboard" replace />} />
    </Routes>
  );
};

export default HRRoutes;
