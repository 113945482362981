import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import {
  Videocam,
  Mic,
  MicOff,
  VideocamOff,
  CallEnd,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  Tooltip,
  Fade,
} from "@mui/material";

// Use a combined ICE configuration that includes a TURN server (replace with your TURN details)
const ICE_SERVERS = [
  { 
    urls: "turn:stun.girikon.ai:3478", 
    username: "user", 
    credential: "pass" 
  }
];

const BASE_URL =
  localStorage.getItem("REACT_APP_WS_URL") || process.env.REACT_APP_WS_URL;
const orgName = localStorage.getItem("org_name");

function CandidateVideoCall() {
  const { roomId } = useParams();
  const [meetingStarted, setMeetingStarted] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const participantsRef = useRef([]);
  const localVideoRef = useRef(null);
  const remoteVideosRef = useRef(new Map());
  const socketRef = useRef(null);
  const peerConnections = useRef(new Map());
  const localStreamRef = useRef(null);
  const iceCandidateQueues = useRef(new Map());
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  // Helper: Update video sender parameters to cap bitrate
  const updateVideoSenderParameters = (peerConnection) => {
    peerConnection.getSenders().forEach((sender) => {
      if (sender.track && sender.track.kind === "video") {
        const parameters = sender.getParameters();
        if (!parameters.encodings) {
          parameters.encodings = [{}];
        }
        // Cap bitrate to 250 kbps
        parameters.encodings[0].maxBitrate = 250000;
        sender
          .setParameters(parameters)
          .then(() => console.log("Debug: Video sender parameters updated"))
          .catch((e) =>
            console.error("Debug: Failed to update video sender parameters", e)
          );
      }
    });
  };

  useEffect(() => {
    if (meetingStarted) {
      console.log("Debug: Meeting started, initializing socket connection");
      socketRef.current = io(BASE_URL, { path: `/${orgName}/socket.io` });
      const init = async () => {
        setIsLoading(true);
        console.log("Debug: Requesting user media");
        try {
          // Lower-quality video constraints
          const stream = await navigator.mediaDevices.getUserMedia({
            video: {
              width: { ideal: 320 },
              height: { ideal: 320 },
              frameRate: { ideal: 15 },
            },
            audio: true,
          });
          console.log("Debug: Local stream obtained", stream);
          localStreamRef.current = stream;
          if (localVideoRef.current) {
            localVideoRef.current.srcObject = stream;
          }
          console.log("Debug: Emitting join-room", roomId, user.u_id, user.u_email);
          socketRef.current.emit("join-room", roomId, user.u_id, user.u_email);

          socketRef.current.on("connect", () => {
            console.log("Debug: Socket connected, ID:", socketRef.current.id);
          });

          socketRef.current.on("connect_error", (error) => {
            console.error("Debug: Socket connection error:", error);
            setError("Failed to connect to server. Please try again.");
          });

          socketRef.current.on("reconnect", () => {
            console.log("Debug: Socket reconnected, rejoining room");
            socketRef.current.emit("join-room", roomId, user.u_id, user.u_email);
          });

          socketRef.current.on("participants-updated", async (participantsInfo) => {
            console.log("Debug: Received participants-updated", participantsInfo);
            if (participantsInfo.roomId === roomId) {
              const newParticipantsList = participantsInfo.participantsList.filter(
                (p) => p.email !== user.u_email
              );
              participantsRef.current = newParticipantsList;
              setParticipants(
                newParticipantsList.map((p) => ({
                  id: p.socket_id,
                  name: p.name || "Participant",
                }))
              );
              for (const userInfo of newParticipantsList) {
                if (
                  userInfo.user_id !== user.u_id &&
                  !peerConnections.current.has(userInfo.user_id)
                ) {
                  console.log("Debug: Creating peer connection for user:", userInfo.user_id);
                  await createPeerConnection(userInfo.user_id);
                }
              }
            }
          });

          socketRef.current.on("offer", handleOffer);
          socketRef.current.on("answer", handleAnswer);
          socketRef.current.on("ice-candidate", handleIceCandidate);
          socketRef.current.on("user-disconnected", handleUserDisconnect);
        } catch (error) {
          console.error("Debug: Error initializing WebRTC:", error);
          setError("Failed to access camera/microphone. Please check permissions.");
        } finally {
          setIsLoading(false);
          console.log("Debug: Finished initialization");
        }
      };

      init();
      return () => {
        console.log("Debug: Cleaning up on unmount");
        cleanup();
      };
    }
  }, [meetingStarted, roomId, user.u_id, user.u_email]);

  // New useEffect for periodic getStats logging to help debug candidate pairing and connectivity
  useEffect(() => {
    let statsInterval;
    if (meetingStarted) {
      statsInterval = setInterval(() => {
        peerConnections.current.forEach((pc, userId) => {
          pc.getStats(null).then((stats) => {
            stats.forEach((report) => {
              if (report.type === "candidate-pair" && report.state === "succeeded") {
                console.log(`Debug: Selected candidate pair for ${userId}:`, report);
              }
              if (report.type === "local-candidate" || report.type === "remote-candidate") {
                console.log(`Debug: ${report.type} for ${userId}:`, report);
              }
            });
          }).catch((err) => {
            console.error(`Debug: Error fetching stats for ${userId}:`, err);
          });
        });
      }, 5000);
    }
    return () => {
      if (statsInterval) clearInterval(statsInterval);
    };
  }, [meetingStarted]);

  const createPeerConnection = async (userId) => {
    console.log(`Debug: Creating RTCPeerConnection for user ${userId}`);
    const peerConnection = new RTCPeerConnection({
      iceServers: ICE_SERVERS,
    });

    peerConnection.oniceconnectionstatechange = () => {
      console.log(`Debug: ICE connection state for ${userId}: ${peerConnection.iceConnectionState}`);
    };
    peerConnection.onconnectionstatechange = () => {
      console.log(`Debug: Connection state for ${userId}: ${peerConnection.connectionState}`);
    };

    // Add local tracks
    localStreamRef.current?.getTracks().forEach((track) => {
      console.log("Debug: Adding local track", track.kind, "to peer connection for user", userId);
      peerConnection.addTrack(track, localStreamRef.current);
    });
    updateVideoSenderParameters(peerConnection);

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        // Log candidate type and public IP (if available)
        if (event.candidate.type === "srflx") {
          console.log("Debug: STUN candidate received. Public IP:", event.candidate.address);
        }
        if (event.candidate.type === "relay") {
          console.log("Debug: TURN candidate received.");
        }
        console.log("Debug: ICE candidate generated for", userId, event.candidate);
        socketRef.current.emit("ice-candidate", {
          candidate: event.candidate,
          roomId,
          to: userId,
        });
      }
    };

    peerConnection.ontrack = (event) => {
      const stream = event.streams[0];
      console.log("Debug: Received remote track from interviewer:", userId, "Stream:", stream);
      if (!remoteVideosRef.current.has(userId)) {
        remoteVideosRef.current.set(userId, stream);
        setParticipants((prev) => [
          ...prev,
          { id: userId, name: "Interviewer" },
        ]);
      }
    };

    peerConnection.onnegotiationneeded = async () => {
      console.log("Debug: Negotiation needed for user", userId);
      try {
        const offer = await peerConnection.createOffer();
        console.log("Debug: Offer created for user", userId, offer);
        await peerConnection.setLocalDescription(offer);
        console.log("Debug: Local description set for user", userId);
        socketRef.current.emit("offer", { offer, roomId, to: userId });
      } catch (err) {
        console.error("Debug: Error during negotiation for user", userId, err);
      }
    };

    peerConnections.current.set(userId, peerConnection);
    iceCandidateQueues.current.set(userId, []);
  };

  const handleOffer = async ({ offer, from }) => {
    console.log("Debug: Received offer from", from, offer);
    if (!offer || !offer.type || !offer.sdp) {
      console.error("Debug: Invalid offer received:", offer);
      return;
    }
    if (!peerConnections.current.has(from)) {
      console.log("Debug: No peer connection found for offer sender, creating one for", from);
      const peerConnection = new RTCPeerConnection({
        iceServers: ICE_SERVERS,
      });
      peerConnection.oniceconnectionstatechange = () => {
        console.log(`Debug: ICE connection state for ${from}: ${peerConnection.iceConnectionState}`);
      };
      peerConnection.onconnectionstatechange = () => {
        console.log(`Debug: Connection state for ${from}: ${peerConnection.connectionState}`);
      };
      localStreamRef.current?.getTracks().forEach((track) => {
        console.log("Debug: Adding local track", track.kind, "to peer connection for", from);
        peerConnection.addTrack(track, localStreamRef.current);
      });
      updateVideoSenderParameters(peerConnection);

      peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          if (event.candidate.type === "srflx") {
            console.log("Debug: STUN candidate (offer) received. Public IP:", event.candidate.address);
          }
          if (event.candidate.type === "relay") {
            console.log("Debug: TURN candidate (offer) received.");
          }
          const toInfo = participantsRef.current.find((p) => p.socket_id === from);
          if (!toInfo) return;
          console.log("Debug: ICE candidate (offer) from", from, event.candidate);
          socketRef.current.emit("ice-candidate", {
            candidate: event.candidate,
            roomId,
            to: toInfo.user_id,
          });
        }
      };

      peerConnection.ontrack = (event) => {
        const stream = event.streams[0];
        console.log("Debug: Received remote track (offer) from interviewer:", from, stream);
        if (!remoteVideosRef.current.has(from)) {
          remoteVideosRef.current.set(from, stream);
          setParticipants((prev) => [
            ...prev,
            { id: from, name: "Interviewer" },
          ]);
        }
      };

      peerConnections.current.set(from, peerConnection);
      iceCandidateQueues.current.set(from, []);
    }

    const peerConnection = peerConnections.current.get(from);
    try {
      console.log("Debug: Setting remote description for offer from", from);
      await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
      console.log("Debug: Remote description set for", from);
      const answer = await peerConnection.createAnswer();
      console.log("Debug: Answer created for", from, answer);
      await peerConnection.setLocalDescription(answer);
      console.log("Debug: Local description set for answer to", from);
      const toInfo = participantsRef.current.find((p) => p.socket_id === from);
      if (toInfo && answer) {
        console.log("Debug: Emitting answer to", toInfo.user_id);
        socketRef.current.emit("answer", { answer, roomId, to: toInfo.user_id });
      }
      const queue = iceCandidateQueues.current.get(from) || [];
      while (queue.length) {
        const candidate = queue.shift();
        console.log("Debug: Adding queued ICE candidate for", from, candidate);
        await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      }
    } catch (error) {
      console.error("Debug: Error handling offer from", from, error);
    }
  };

  const handleAnswer = async ({ answer, from }) => {
    console.log("Debug: Received answer from", from, answer);
    const answerInfo = participantsRef.current.find((p) => p.socket_id === from);
    if (!answerInfo) {
      console.error(`Debug: Participant with socket_id ${from} not found`);
      return;
    }
    const peerConnection = peerConnections.current.get(answerInfo.user_id);
    if (!peerConnection) {
      console.error(`Debug: No peer connection found for user ${from}`);
      return;
    }
    try {
      console.log("Debug: Setting remote description for answer from", from);
      await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
      console.log("Debug: Remote description set for answer from", from);
      const queue = iceCandidateQueues.current.get(answerInfo.user_id) || [];
      while (queue.length) {
        const candidate = queue.shift();
        console.log("Debug: Adding queued ICE candidate for", answerInfo.user_id, candidate);
        await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      }
    } catch (error) {
      console.error("Debug: Error handling answer from", from, error);
    }
  };

  const handleIceCandidate = async ({ candidate, from }) => {
    console.log("Debug: Received ICE candidate from", from, candidate);
    const candidateInfo = participantsRef.current.find((p) => p.socket_id === from);
    if (!candidateInfo) {
      console.error("Debug: No candidate info for", from);
      return;
    }
    const peerConnection = peerConnections.current.get(candidateInfo.user_id);
    if (!peerConnection) {
      console.error(`Debug: No peer connection for user ${candidateInfo.user_id}`);
      return;
    }
    if (peerConnection.remoteDescription) {
      console.log("Debug: Adding ICE candidate immediately for", candidateInfo.user_id);
      try {
        await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      } catch (err) {
        console.error("Debug: Error adding ICE candidate immediately:", err);
      }
    } else {
      console.log("Debug: Queueing ICE candidate for", candidateInfo.user_id, candidate);
      const queue = iceCandidateQueues.current.get(candidateInfo.user_id) || [];
      queue.push(candidate);
      iceCandidateQueues.current.set(candidateInfo.user_id, queue);
    }
  };

  const handleUserDisconnect = (userId) => {
    console.log("Debug: Handling user disconnect for", userId);
    const peerConnection = peerConnections.current.get(userId);
    if (peerConnection) {
      peerConnection.close();
      peerConnections.current.delete(userId);
      remoteVideosRef.current.delete(userId);
      iceCandidateQueues.current.delete(userId);
      setParticipants((prev) => prev.filter((p) => p.id !== userId));
      console.log("Debug: User disconnected and cleaned up:", userId);
    }
  };

  const cleanup = () => {
    console.log("Debug: Running cleanup");
    localStreamRef.current?.getTracks().forEach((track) => {
      console.log("Debug: Stopping local track", track.kind);
      track.stop();
    });
    peerConnections.current.forEach((peer, userId) => {
      console.log("Debug: Closing peer connection for", userId);
      peer.close();
    });
    if (socketRef.current && socketRef.current.connected) {
      console.log("Debug: Disconnecting socket");
      socketRef.current.disconnect();
    }
    peerConnections.current.clear();
    remoteVideosRef.current.clear();
    iceCandidateQueues.current.clear();
    localStreamRef.current = null;
    console.log("Debug: Cleanup complete");
  };

  const startMeeting = () => {
    console.log("Debug: Starting meeting");
    setMeetingStarted(true);
  };
  const endCall = () => {
    console.log("Debug: Ending call");
    cleanup();
    setMeetingStarted(false);
    setParticipants([]);
    setIsAudioEnabled(true);
    setIsVideoEnabled(true);
    setError(null);
  };

  const toggleAudio = () => {
    if (localStreamRef.current) {
      const audioTrack = localStreamRef.current.getAudioTracks()[0];
      audioTrack.enabled = !audioTrack.enabled;
      console.log("Debug: Toggled audio to", audioTrack.enabled);
      setIsAudioEnabled(audioTrack.enabled);
    }
  };

  const toggleVideo = () => {
    if (localStreamRef.current) {
      const videoTrack = localStreamRef.current.getVideoTracks()[0];
      videoTrack.enabled = !videoTrack.enabled;
      console.log("Debug: Toggled video to", videoTrack.enabled);
      setIsVideoEnabled(videoTrack.enabled);
    }
  };

  useEffect(() => {
    console.log("Debug: Updating remote video elements");
    participants.forEach(({ id }) => {
      const el = document.getElementById(`remote-video-${id}`);
      if (el && remoteVideosRef.current.has(id)) {
        console.log("Debug: Setting remote video element source for", id);
        el.srcObject = remoteVideosRef.current.get(id);
      }
    });
  }, [participants]);

  return (
    <Box
      sx={{
        height: "80vh",
        bgcolor: "#1c2526",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Roboto",
      }}
    >
      {!meetingStarted ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 4,
              bgcolor: "#2d3839",
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "#e0e7e9", mb: 2 }}>
              Ready to join the interview?
            </Typography>
            <Button
              onClick={startMeeting}
              variant="contained"
              sx={{
                bgcolor: "#4285f4",
                "&:hover": { bgcolor: "#3267d6" },
                borderRadius: 1,
                px: 4,
                py: 1,
                fontWeight: 500,
              }}
            >
              Join Interview
            </Button>
          </Paper>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              bgcolor: "#2d3839",
              p: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" sx={{ color: "#e0e7e9" }}>
              Interview Room
            </Typography>
            <Typography variant="caption" sx={{ color: "#b0bec5" }}>
              {new Date().toLocaleTimeString()}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, p: 2, overflow: "auto", bgcolor: "#1c2526" }}>
            {error && (
              <Typography color="error" sx={{ p: 2 }}>
                {error}
              </Typography>
            )}
            {isLoading && (
              <Typography sx={{ p: 2, color: "#e0e7e9" }}>
                Loading...
              </Typography>
            )}
            <Grid
              container
              spacing={2}
              sx={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {remoteVideosRef.current.size > 0 ? (
                Array.from(remoteVideosRef.current.entries()).map(
                  ([userId, stream]) => (
                    <Grid
                      item
                      xs={remoteVideosRef.current.size > 1 ? 6 : 12}
                      key={userId}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          height: "100%",
                          bgcolor: "#000",
                          borderRadius: 2,
                          overflow: "hidden",
                        }}
                      >
                        <video
                          id={`remote-video-${userId}`}
                          autoPlay
                          playsInline
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            maxHeight: "calc(80vh - 120px)",
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            position: "absolute",
                            bottom: 8,
                            left: 8,
                            bgcolor: "rgba(0,0,0,0.7)",
                            color: "#fff",
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                          }}
                        >
                          Interviewer
                        </Typography>
                      </Box>
                    </Grid>
                  )
                )
              ) : (
                <Grid item xs={12}>
                  <Typography sx={{ color: "#e0e7e9", textAlign: "center" }}>
                    Waiting for interviewer to join...
                  </Typography>
                </Grid>
              )}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 50,
                  right: 30,
                  width: { xs: 120, md: 200 },
                  height: { xs: 90, md: 150 },
                  bgcolor: "#000",
                  borderRadius: 2,
                  overflow: "hidden",
                  border: "2px solid #e0e7e9",
                  zIndex: 10,
                }}
              >
                <video
                  ref={localVideoRef}
                  autoPlay
                  muted
                  playsInline
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    position: "absolute",
                    bottom: 4,
                    left: 4,
                    bgcolor: "rgba(0,0,0,0.7)",
                    color: "#fff",
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                  }}
                >
                  You (Candidate)
                </Typography>
              </Box>
            </Grid>
            <Fade in={true} timeout={500}>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 16,
                  left: "50%",
                  transform: "translateX(-50%)",
                  bgcolor: "rgba(45,56,57,0.9)",
                  borderRadius: 10,
                  p: 1,
                  display: "flex",
                  gap: 2,
                  boxShadow: "0 4px 12px rgba(0,0,0,0.3)",
                  "&:hover": { bgcolor: "rgba(45,56,57,1)" },
                }}
              >
                <Tooltip title={isAudioEnabled ? "Mute" : "Unmute"}>
                  <IconButton
                    onClick={toggleAudio}
                    sx={{
                      bgcolor: isAudioEnabled ? "#616161" : "#d93025",
                      color: "#fff",
                      "&:hover": {
                        bgcolor: isAudioEnabled ? "#757575" : "#b71c1c",
                      },
                    }}
                  >
                    {isAudioEnabled ? <Mic /> : <MicOff />}
                  </IconButton>
                </Tooltip>
                <Tooltip title={isVideoEnabled ? "Turn Off Camera" : "Turn On Camera"}>
                  <IconButton
                    onClick={toggleVideo}
                    sx={{
                      bgcolor: isVideoEnabled ? "#616161" : "#d93025",
                      color: "#fff",
                      "&:hover": {
                        bgcolor: isVideoEnabled ? "#757575" : "#b71c1c",
                      },
                    }}
                  >
                    {isVideoEnabled ? <Videocam /> : <VideocamOff />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Leave Call">
                  <IconButton
                    onClick={endCall}
                    sx={{
                      bgcolor: "#d93025",
                      color: "#fff",
                      "&:hover": { bgcolor: "#b71c1c" },
                    }}
                  >
                    <CallEnd />
                  </IconButton>
                </Tooltip>
              </Box>
            </Fade>
          </Box>
        </>
      )}
    </Box>
  );
}

export default CandidateVideoCall;
