/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  CardContent,
  CircularProgress,
  Card,
  Box,
  Modal,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import AlertDialog from "../../components/Dialog";
import { hideSidebar } from "../../redux/actions/sidebar";
import useFullscreen from "../../hooks/fullScreenWindow";
import { saveInterviewSession } from "../../redux/actions/candidate";
import { useScreenRecorder } from "../../contexts/ScreenRecorderContext";
import {
  getPsycometricTest,
  submitPsychometricTest,
} from "../../services/dashboard";
import throttle from "lodash/throttle";

// Modal popup styles
const modalPopUpStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "8px",
  textAlign: "center",
};

// Loader styles
const loaderStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1300,
  color: "#fff",
  fontSize: "1.5rem",
};

export const PsycometricMcq = ({
  saveInterviewSession,
  userData,
  auth: { user },
  hideSidebar,
}) => {
  const navigate = useNavigate();
  const [answerSheet, setAnswerSheet] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const { isFullScreen, enterFullScreen } = useFullscreen();
  const [isFaceDetected, setIsFaceDetected] = useState(true);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [videoStream, setVideoStream] = useState(null);
  const [faceapi, setFaceApi] = useState(null);
  const [consentGiven, setConsentGiven] = useState(true);
  const [consentModalOpen, setConsentModalOpen] = useState(false);
  const [submitConfirmOpen, setSubmitConfirmOpen] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [loadedCategories, setLoadedCategories] = useState({});
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);
  const [totalQuestionsLoaded, setTotalQuestionsLoaded] = useState(0);
  const containerRef = useRef(null);
  const debounceTimeout = useRef(null);

  const categories = [
    "Key Personality Traits",
    "Workplace Managerial Skills",
    "Comprehensive Personality Factors (leadership index, locus of control and coping mechanisms)",
  ];
  const TOTAL_REQUIRED_QUESTIONS = 25; // 10 + 10 + 5

  const { isRecording: isScreenRecording, startRecording: startScreenRecording, stopRecording: stopScreenRecording } = useScreenRecorder();
  
  const loadNextCategory = async () => {
    if (
      currentCategoryIndex >= categories.length || 
      isLoadingCategory || 
      totalQuestionsLoaded >= TOTAL_REQUIRED_QUESTIONS
    ) return;

    setIsLoadingCategory(true);
    try {
      const category = categories[currentCategoryIndex];
      // Collect previous category questions
      const previousQuestions = Object.values(loadedCategories).flat();
      const response = await getPsycometricTest(category, previousQuestions); // Pass previous questions
      setLoadedCategories(prev => ({
        ...prev,
        [category]: response
      }));
      setTotalQuestionsLoaded(prev => prev + (response?.length || 0));
      setCurrentCategoryIndex(prev => prev + 1);
    } catch (error) {
      console.error("Error fetching category:", error);
      showErrorModal("Failed to load next category. Please try again.");
    } finally {
      setIsLoadingCategory(false);
    }
  };

  useEffect(() => {
    startScreenRecording("psycometric");
  }, []);

  useEffect(() => {
    const loadFaceApi = async () => {
      const faceapi = await import("face-api.js");
      await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
      await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
      await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
      setFaceApi(faceapi);
    };
    loadFaceApi();
  }, []);

  // const checkScroll = useCallback(
  //   throttle(() => {
  //     if (
  //       !containerRef.current || 
  //       isLoadingCategory || 
  //       currentCategoryIndex >= categories.length || 
  //       totalQuestionsLoaded >= TOTAL_REQUIRED_QUESTIONS
  //     ) return;

  //     const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
  //     if (scrollTop + clientHeight >= scrollHeight - 100) {
  //       loadNextCategory();
  //     }
  //   }, 300),
  //   [currentCategoryIndex, isLoadingCategory, totalQuestionsLoaded]
  // );

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 10 &&
        totalQuestionsLoaded < TOTAL_REQUIRED_QUESTIONS && !isLoadingCategory
      ) {
        // Apply Debounce: Clear previous timeout and set a new one
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
          loadNextCategory();
        }, 300); // Adjust debounce time if needed
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [TOTAL_REQUIRED_QUESTIONS, totalQuestionsLoaded, loadedCategories, isLoadingCategory, loadNextCategory]);

  

  useEffect(() => {
    if (user && Object.keys(loadedCategories).length === 0) {
      loadNextCategory();
    }
  }, [user]);

  // useEffect(() => {
  //   const container = containerRef.current;
  //   if (container) {
  //     container.addEventListener("scroll", checkScroll);
  //   }
  //   return () => {
  //     if (container) {
  //       container.removeEventListener("scroll", checkScroll);
  //     }
  //   };
  // }, [checkScroll]);

  useEffect(() => {
    if (totalQuestionsLoaded === TOTAL_REQUIRED_QUESTIONS) {
      setIsSubmitDisabled(false);
    }
  }, [totalQuestionsLoaded]);

  const showErrorModal = (message) => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    enterFullScreen();
  };

  const getUserMedia = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setVideoStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      showErrorModal("Camera and microphone access is required");
    }
  };

  useEffect(() => {
    if (consentGiven) {
      setConsentModalOpen(false);
      getUserMedia();
    }
  }, [consentGiven]);

  const handleConsentAccept = () => {
    setConsentGiven(true);
    setConsentModalOpen(false);
    getUserMedia();
  };

  const drawFaceDetections = (detections) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    detections.forEach((detection) => {
      const { x, y, width, height } = detection.box;
      ctx.strokeStyle = "red";
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, width, height);
    });
  };

  useEffect(() => {
    let faceDetectionInterval;
    const detectFaces = async () => {
      if (videoRef.current && videoRef.current.readyState === 4 && faceapi) {
        const detections = await faceapi.detectAllFaces(
          videoRef.current,
          new faceapi.TinyFaceDetectorOptions({
            inputSize: 160,
            scoreThreshold: 0.5,
          })
        );

        if (detections.length > 0) {
          drawFaceDetections(detections);
        }

        if (detections.length > 1) {
          saveInterviewSession({
            multiFaceDetected: true,
            apct_id: user.u_apct_id_fk,
          });
        }

        setIsFaceDetected(detections.length === 1);
      }
    };

    if (faceapi && consentGiven) {
      faceDetectionInterval = setInterval(detectFaces, 2000);
    }

    return () => clearInterval(faceDetectionInterval);
  }, [faceapi, consentGiven]);

  useEffect(() => {
    if (consentGiven && !isFaceDetected) {
      // showErrorModal("Face not detected. Please ensure only one face is visible.");
    }
  }, [isFaceDetected, consentGiven]);

  const onClickAnswer = (e, q_id, opt) => {
    const tempAnswerSheet = [...answerSheet];
    const existingAnswer = tempAnswerSheet.find(ans => ans.q_id === q_id);
    
    if (existingAnswer) {
      if (existingAnswer.answer === opt) {
        setAnswerSheet(tempAnswerSheet.filter(ans => ans.q_id !== q_id));
      } else {
        setAnswerSheet(tempAnswerSheet.map(ans => 
          ans.q_id === q_id ? { ...ans, answer: opt } : ans
        ));
      }
    } else {
      tempAnswerSheet.push({ q_id, answer: opt });
      setAnswerSheet(tempAnswerSheet);
    }
  };

  const handleSubmitConfirm = () => {
    setSubmitConfirmOpen(true);
  };

  const submitFinalData = async () => {
    setOpen(false);
    setConfirm(false);
    try {
      const totalQuestions = Object.values(loadedCategories).reduce((sum, cat) => sum + (cat?.length || 0), 0);
      const data = {
        totalQues: totalQuestions,
        answerSheetMCQ: answerSheet,
      };
      await submitPsychometricTest(data);
      if (videoStream) {
        videoStream.getTracks().forEach(track => track.stop());
      }
      stopScreenRecording();
      navigate("/candidate/dashboard", { replace: true });
    } catch (error) {
      showErrorModal("Failed to submit test. Please try again.");
    }
  };

  useEffect(() => {
    if (confirm) {
      submitFinalData();
    }
  }, [confirm]);

  useEffect(() => {
    hideSidebar();
  }, []);

  useEffect(() => {
    const handleCopy = (e) => e.preventDefault();
    const handleCut = (e) => e.preventDefault();
    const handlePaste = (e) => e.preventDefault();

    let mcqsPage = document.getElementById("mcqPage");
    mcqsPage.addEventListener("copy", handleCopy);
    mcqsPage.addEventListener("cut", handleCut);
    mcqsPage.addEventListener("paste", handlePaste);

    return () => {
      mcqsPage.removeEventListener("copy", handleCopy);
      mcqsPage.removeEventListener("cut", handleCut);
      mcqsPage.removeEventListener("paste", handlePaste);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && (e.key === "c" || e.key === "x" || e.key === "v")) {
        e.preventDefault();
      }
    };

    let mcqPage = document.getElementById("mcqPage");
    mcqPage.addEventListener("keydown", handleKeyDown);

    return () => {
      mcqPage.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleContextMenu = (e) => e.preventDefault();
    document.addEventListener("contextmenu", handleContextMenu);
    return () => document.removeEventListener("contextmenu", handleContextMenu);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        saveInterviewSession({ isFullScreen, apct_id: user.u_apct_id_fk });
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  useEffect(() => {
    const handleMouseOut = (event) => {
      if (!event.relatedTarget) {
        saveInterviewSession({ isFullScreen, apct_id: user.u_apct_id_fk });
      }
    };
    window.addEventListener("mouseout", handleMouseOut);
    return () => window.removeEventListener("mouseout", handleMouseOut);
  }, []);

  useEffect(() => {
    if (!isFullScreen) {
      saveInterviewSession({ isFullScreen, apct_id: user.u_apct_id_fk });
      showErrorModal("Full screen exit not allowed");
    }
  }, [isFullScreen]);

  return (
    <>
      <Modal
        open={consentModalOpen}
        onClose={() => {}}
        aria-labelledby="consent-modal-title"
        aria-describedby="consent-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="consent-modal-title">
            Consent Required
          </Typography>
          <Typography sx={{ mt: 2 }}>
            This test requires access to your camera and microphone for verification. Do you consent?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConsentAccept}
            sx={{ mt: 3 }}
          >
            Accept
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openErrorModal}
        onClose={() => {}}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="error-modal-title">
            Error
          </Typography>
          <Typography sx={{ mt: 2 }}>{errorMessage}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseErrorModal}
            sx={{ mt: 3 }}
          >
            OK
          </Button>
        </Box>
      </Modal>

      <Modal
        open={submitConfirmOpen}
        onClose={() => setSubmitConfirmOpen(false)}
        aria-labelledby="submit-confirm-modal-title"
        aria-describedby="submit-confirm-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="submit-confirm-modal-title">
            Confirm Submission
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Are you sure you want to submit your answers?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={submitFinalData}
            sx={{ mt: 3 }}
          >
            Yes, Submit
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setSubmitConfirmOpen(false)}
            sx={{ mt: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <CardContent id="mcqPage">
        <Stack direction="column" alignItems="center" spacing={3}>
          <Box 
            component="main" 
            sx={{ width: "100%", position: "relative" }}
          >
            <video
              ref={videoRef}
              autoPlay
              muted
              style={{
                position: "fixed",
                bottom: "450px",
                right: "20px",
                width: "200px",
                height: "150px",
                border: "1px solid black",
                borderRadius: "8px",
                zIndex: 9999,
                display: consentGiven ? "block" : "none",
              }}
            />
            <canvas
              ref={canvasRef}
              style={{
                position: "fixed",
                bottom: "460px",
                right: "20px",
                width: "200px",
                height: "150px",
                zIndex: 10000,
                pointerEvents: "none",
              }}
            />
            
            <AlertDialog
              open={open}
              setOpen={setOpen}
              title={title}
              setConfirm={setConfirm}
              content={content}
            />


            <Stack direction="column" spacing={4}>
              {Object.entries(loadedCategories).map(([heading, categoryQuestions]) => (
                <Box key={heading}>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    {heading} ({categoryQuestions?.length || 0} Questions)
                  </Typography>
                  <Stack direction="column" spacing={2}>
                    {categoryQuestions?.map((item, index) => (
                      <Card key={item.q_id} sx={{ mb: 2, userSelect: "none" }}>
                        <CardContent>
                          <Typography variant="h6">
                            {index + 1}. {item.q_name}
                          </Typography>
                          <Stack direction="column">
                            {item?.options?.map((opt) => (
                              <FormControlLabel
                                key={opt.a_id}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={!!answerSheet.find(ans => 
                                      ans.q_id === item.q_id && ans.answer === opt.a_label
                                    )}
                                    onChange={(e) => onClickAnswer(e, item.q_id, opt.a_label)}
                                  />
                                }
                                label={opt.a_text}
                              />
                            ))}
                          </Stack>
                        </CardContent>
                      </Card>
                    ))}
                  </Stack>
                </Box>
              ))}
            </Stack>
       

            {isLoadingCategory && totalQuestionsLoaded < TOTAL_REQUIRED_QUESTIONS && (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                <CircularProgress />
              </Box>
            )}

              
          </Box>
        </Stack>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitConfirm}
            disabled={isSubmitDisabled}
          >
            Submit
          </Button>
        </Box>
        {totalQuestionsLoaded < TOTAL_REQUIRED_QUESTIONS && (
          <Typography sx={{ textAlign: "center", mt: 2 }}>
            {totalQuestionsLoaded} of {TOTAL_REQUIRED_QUESTIONS} questions loaded
          </Typography>
        )}
      </CardContent>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  userData: state.auth.userData,
});

const mapDispatchToProps = {
  hideSidebar,
  saveInterviewSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(PsycometricMcq);