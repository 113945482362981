import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardContent,
    Grid,
    Typography,
    TableContainer,
    TablePagination,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { delete_agent, get_all_agents } from "../../services/agent.service";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const AgentList = () => {
    const navigate = useNavigate();
    const [agentData, setAgentData] = useState([]);
    const [deletePopup, setDeletePopup] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [total, setTotal] = useState(0);

    const getAgentsList = async () => {
        try {
            const result = await get_all_agents(page, rowsPerPage, false);
            if (result?.status === 200 && !result.data.error) {
                setAgentData(result.data.rows);
                setTotal(result.data.total);
            } else {
                toast.error(result?.data?.error || "Failed to fetch agents!");
            }
        } catch (error) {
            console.error(error);
            toast.error("Error fetching agents!");
        }
    };

    useEffect(() => {
        getAgentsList();
    }, [page, rowsPerPage]);

    const handleCreateClick = () => {
        navigate(`/hr/create-ai-agent`);
    };

    const handleDeleteAgent = async () => {
        try {
            const result = await delete_agent(selectedId);
            if (result?.status === 200 && !result.data.error) {
                toast.success("Agent deleted successfully!");
                getAgentsList();
                setDeletePopup(false);
            } else {
                toast.error(result?.data?.error || "Failed to delete agent!");
            }
        } catch (error) {
            console.error(error);
            toast.error("Error deleting agent!");
        }
    };

    const handleEditAgent = (id) => {
        navigate(`/hr/edit-ai-agent/${id}`);
    };

    const handleViewAgent = (id) => {
        navigate(`/hr/view-ai-agent/${id}`);
    };
    const truncateText = (text, length = 25) => {
        return text.length > length ? `${text.substring(0, length)}...` : text;
    };

    return (
        <>
            <Card>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                <SupportAgentIcon sx={{ mr: 1 }} />
                                Agents
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
                            <Button variant="contained" onClick={handleCreateClick}>
                                + Create
                            </Button>
                        </Grid>
                    </Grid>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Agent configuration allows you to customize the look and feel of the widget.
                    </Typography>

                    <TableContainer component={Paper} sx={{ mt: 3 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Prompt</TableCell>
                                    <TableCell>Do's</TableCell>
                                    <TableCell>Don't</TableCell>
                                    <TableCell>Guiderails</TableCell>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {agentData.length > 0 ? (
                                    agentData.map((ag) => (
                                        <TableRow key={ag.agent_id}>
                                            <TableCell onClick={() => handleViewAgent(ag.agent_id)} sx={{cursor:"pointer"}}>{ag.agent_name}</TableCell>
                                            <TableCell> <Typography>{truncateText(ag.agent_summary)}</Typography></TableCell>
                                            <TableCell> <Typography >{truncateText(ag.agent_dos)}</Typography></TableCell>
                                            <TableCell><Typography >{truncateText(ag.agent_dont)}</Typography></TableCell>
                                            <TableCell><Typography >{truncateText(ag.agent_guide_rails)}</Typography></TableCell>
                                            <TableCell>{moment(ag.agent_created_at).format("MMM Do, YYYY")}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleEditAgent(ag.agent_id)} sx={{ mr: 1 }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => { setSelectedId(ag.agent_id); setDeletePopup(true); }}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            No agents found.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                    />
                </CardContent>
            </Card>

            {/* Delete Confirmation Dialog */}
            <Dialog open={deletePopup} onClose={() => setDeletePopup(false)}>
                <DialogTitle>Delete Agent</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this agent?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeletePopup(false)}>Cancel</Button>
                    <Button onClick={handleDeleteAgent} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AgentList;
