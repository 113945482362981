import axios from "axios";


export const _createAiAgent = async (data) => {
    try {
        const url = `/agent/create_agent`;
        const response = await axios.post(url,data)
        return response;
      } catch (error) {
        throw error;
      }
};

export const get_all_agents = async () => {
    try {
        const url = `/agent`;
        const response = await axios.get(url);
        return response;
      } catch (error) {
        throw error;
      }
};

export const get_agent_by_id = async (id) => {
  try {
      const url = `/agent/${id}`;
      const response = await axios.get(url);
      return response;
    } catch (error) {
      throw error;
    }
};

export const delete_agent = async (id) => {
    try {
        const url = `/agent/${id}`;
        const response = await axios.delete(url);
        return response;
      } catch (error) {
        throw error;
      }
}

export const update_agent = async (id, data) => {
    try {
        const url = `/agent/${id}`;
        const response = await axios.put(url, data);
        return response;
      } catch (error) {
        throw error;
      }
}

export const get_agent_without_pagination= async (email) => {
  try {
    const url = `/agent/all`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}