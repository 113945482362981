import { createContext, useContext, useRef, useState, useEffect } from 'react';
import { io } from 'socket.io-client';

const ScreenRecorderContext = createContext();
let BASE_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;
let orgName = localStorage.getItem('org_name');

if (orgName == null) {
  orgName = "org_girikon_india";
}

const socket = io(BASE_URL, {
  path: `/${orgName}/socket.io`,  // Adjust the path if necessary
});

export const ScreenRecorderProvider = ({ children }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const mediaRecorder = useRef(null);
  const mediaStream = useRef(null);
  const recordingStartTime = useRef(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [isEntireScreen, setIsEntireScreen] = useState(false);
  // Connect to the backend server using Socket.IO

  const startRecording = async (type) => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          displaySurface: 'monitor', // Ensures entire screen selection
          preferCurrentTab: false,  // Avoid selecting the current tab
          cursor: 'always', // Shows the cursor
        },
        audio: true,
      });

      const videoTrack = stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities();

      // Check if the entire screen is selected
      if (capabilities.displaySurface === 'monitor') {
        setIsEntireScreen(true);
      } else {
        setIsEntireScreen(false);
        alert("Please select 'Entire Screen' to continue recording.");
        stream.getTracks().forEach(track => track.stop()); // Stop stream
        return;
      }

      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream, {
        mimeType: 'video/webm; codecs=vp9',
        videoBitsPerSecond: 2500000,
      });


      // const videoMediaRecorder = new MediaRecorder(stream);
      // videoRecorder.current = videoMediaRecorder;
      // videoMediaRecorder.ondataavailable = (event) => {
      //   socket.emit("video-chunk", {
      //     data: event.data,
      //     vdUserId: userId,
      //     streamId: qaId,
      //   });
      // };

      mediaRecorder.current.ondataavailable = (event) => {
        socket.emit("video-chunk", {
          data: event.data,
          vdUserId: userData?.u_id,
          streamId: userData?.u_id,
          type:type
        });
      };

      mediaRecorder.current.onstop = () => {
        socket.emit('stop-recording');
        setRecordingTime(0);
        setIsEntireScreen(false);
      };

      mediaRecorder.current.start(1000); // Capture 1-second chunks
      setIsRecording(true);
      recordingStartTime.current = Date.now();
      startRecordingTimer();
    } catch (err) {
      console.error('Error starting recording:', err);
    }
  };

  const startRecordingTimer = () => {
    setInterval(() => {
      setRecordingTime(Math.floor((Date.now() - recordingStartTime.current) / 1000));
    }, 1000);
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      mediaStream.current.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
    }
  };

  // Function to check if entire screen was selected
  const isEntireScreenSelected = isEntireScreen;

  return (
    <ScreenRecorderContext.Provider
      value={{
        isRecording,
        showPopup,
        recordingTime,
        startRecording,
        stopRecording,
        isEntireScreenSelected,
        togglePopup: () => setShowPopup(!showPopup),
      }}
    >
      {children}
    </ScreenRecorderContext.Provider>
  );
};

export const useScreenRecorder = () => useContext(ScreenRecorderContext);
