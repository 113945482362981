import { Route, Routes } from "react-router-dom";
import SiginIn from "../pages/common/SiginIn";
import SignUp from "../pages/common/SignUp";
import VerifyOtp from "../pages/common/VerifyOtp";
import ForgotPassword from "../pages/common/ForgotPassword";
import ResetPassword from "../pages/common/ResetPassword";
import { Navigate } from "react-router-dom";
import CandidateSignin from "../pages/common/CandidateSignin";
import ReportPage from "../pages/common/ReportPage";
import ReportPageEn from "../pages/common/ReportPageEn";
import ResumeUploadPage from "../pages/common/ResumeUpload";
import MockInterview from "../pages/common/MockAiInterview";
import ReportPageDemo from "../pages/common/AiScoreReport";
import InterviewerSignIn from "../pages/common/InterviewerSignIn";
import PsychometricAssessmentReport from "../pages/common/PsychometricAssessmentReport.jsx";
import PsychometricAssessmentReportEn from "../pages/common/PsychometricAssessmentReportEn.jsx";
const PublicRoute = () => {
  // console.log("PublicRoute");
  return (
    <Routes>
      <Route path="/signin" element={<SiginIn />} exact />
      <Route path="/signup" element={<SignUp />} exact />
      <Route path="/forgot-password" element={<ForgotPassword />} exact />
      <Route path="/verify-otp" element={<VerifyOtp />} />
      <Route path="/reset-password/" element={<ResetPassword />} />
      <Route path="/:org_name/report/:apct_id" element={<ReportPage />} />
      <Route path="/:org_name/report/:apct_id/:lan" element={<ReportPageEn />} />
      <Route path="/:org_name/psychometric-report/:apct_id" element={<PsychometricAssessmentReport />} />
      <Route path="/:org_name/psychometric-report/:apct_id/:lan" element={<PsychometricAssessmentReportEn />} />
      <Route path="/:org_name/candidate-signin" element={<CandidateSignin />}></Route>
      <Route path="/:org_name/interviewer-signin" element={<InterviewerSignIn />}></Route>
      <Route path="/upload-resume" element={<ResumeUploadPage/>} />
      <Route path="/ai-interview/:id" element={<MockInterview/>} />
      <Route path="/ai-report/:apct_id" element={<ReportPageDemo/>} />
      <Route path="*" element={<Navigate to="/signin" replace />} />
    </Routes>
  );
};

export default PublicRoute;
