import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "../pages/employee/Dashboard.jsx";
import TestMCQ from "../pages/employee/TestMCQ.jsx";
import TestCoding from "../pages/employee/TestCoding.jsx";
import SuggestedCourses from "../pages/employee/SuggestedCourse.jsx";
import InterviewPage from "../pages/employee/AIInterview.jsx";
import SkillGapAnalysis from "../pages/employee/SkillGapAnalysis.jsx";
import AddExperience from "../pages/employee/AddExperience.jsx";
import AddSkills from "../pages/employee/AddSkills.jsx";
import EvaluateSkills from "../pages/employee/EvaluateSkills.jsx"
import UsersProfile from "../pages/common/UsersProfile.jsx";
import SelfAssessment from "../pages/employee/SelfAssessment.jsx";
import TrainingCourse from "../pages/employee/TrainingCourse.jsx";
import Setting from "../pages/common/Setting.jsx";
// import SkillDetail from "../pages/employee/SkillDetail.jsx";

const EmployeeRoutes = () => {
  return (
    <Routes>
      <Route path="/employee/dashboard" element={<Dashboard />} />
      <Route path="/employee/self-assessments" element={<SelfAssessment />} />
      <Route
        path="/employee/self-assessment/add-exprience"
        element={<AddExperience />}
      />

      <Route path="/employee/my-skills" element={<AddSkills />} />

      <Route
        path="/employee/self-assessment/skill-assesment"
        element={<EvaluateSkills />}
      />

      <Route path="/employee/self-assessment/mcq" element={<TestMCQ />} />

      <Route path="/employee/self-assessment/mcq" element={<TestMCQ />} />
      <Route path="/employee/self-assessment/coding" element={<TestCoding />} />
      <Route path="/employee/ai-assistant" element={<InterviewPage />} />
      <Route
        path="/employee/skill-gap-analysis"
        element={<SkillGapAnalysis />}
      />
      <Route
        path="/employee/suggested-courses/:skill_Id"
        element={<SuggestedCourses />}
      />
      <Route
        path="/employee/training-course/:course_name/:ses_id"
        element={<TrainingCourse />}
      />

      <Route path="/employee/profile" element={<UsersProfile />} />
      <Route path="/employee/setting" element={<Setting />} />

      <Route path="*" element={<Navigate to="employee/dashboard" replace />} />
    </Routes>
  );
};

export default EmployeeRoutes;
