import axios from "axios";

export const _loginCandidate = async (data) => {
  try {
    const login_url = `/candidates/sign_in`;
    const response = await axios.post(login_url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

// _insertCandidate

export const _insertCandidate = async (data, id) => {
  try {
    const url = `/candidates/add-candidate/${id}`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
}

//_updateCandidate

export const _updateCandidate = async (data, id) => {
  try {
    const url = `/candidates/${id}`;
    const response = await axios.put(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};





export const _scheduleSecRound = async (data) => {
  try {
    const url = `/jobs/schedule/second-round`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getCandDashboard = async (id) => {
  try {
    const dashboard_url = `/candidate/dashboard/${id}`;
    const response = await axios.get(dashboard_url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _startCandInterview = async (id, interviewThread="") => {
  try {
    const start_interview_url = `/applicants/start_interview/${id}?interview_thread=${interviewThread}`;
    const response = await axios.get(start_interview_url);
    return response;
  } catch (error) {
    throw error;
  }
};



// _getInterviewStatus

export const _getInterviewStatus = async (id) => {
  try {
    const start_interview_url = `/applicants/status_interview/${id}`;
    const response = await axios.get(start_interview_url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getSampleTextTrannslated = async (data) => {
  try {
    const start_interview_url = `/candidate/post/sampleTextTranscript`;
    const response = await axios.post(start_interview_url, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const _getInterviewEndTime = async (id) => {
  try {
    const start_interview_url = `/applicants/end_interview_time/${id}`;
    const response = await axios.get(start_interview_url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getJobsList = async (id) => {
  try {
    const _url = `/candidates/jobs/list`;
    const response = await axios.get(_url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _submitCandInterviewQuestion = async (data) => {
  try {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const submit_interview_url = `/applicants/submit_interview_question`;
    const response = await axios.post(submit_interview_url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _endCandInterview = async (data) => {
  try {
    const end_interview_url = `/applicants/conclude_interview`;
    const response = await axios.post(end_interview_url, data);
    return response;
  } catch (error) {
    throw error;
  }
};
//_getCandidatesByBatchId
export const _getCandidatesByBatchId = async (bt_id, page, size, sortField, sortType) => {
  try {
    const url = `/jobs/candidatesByBatchId/${bt_id}?page=${page}&pageSize=${size}&sortField=${sortField}&sortType=${sortType}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};
export const _getCandidates = async (jb_id, page, size, filters, search, sortField, sortType) => {
  try {
    let url = `/candidates/${jb_id}?page=${page}&pageSize=${size}&sortField=${sortField}&sortType=${sortType}`;
    if (filters) {
      url = url + filters;
    }
    if (search) {
      url = url + search;
    }

    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

//_getCandidatesFilter

export const _getCandidatesFilter = async (jb_id, page, size, filters) => {
  try {
    const url = `/candidates/${jb_id}?page=${page}&pageSize=${size}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getQAs = async (candidateId, threadId) => {
  try {
    const url = `/candidates/ques_ans/${candidateId}/${threadId}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _sendInvite = async (id, bodyData) => {
  try {
    const url = `/jobs/send_invite`;
    if (Array.isArray(id)) {
      const response = await axios.post(url, { ...bodyData, candidateIds: id });
      return response;
    } else {
      const response = await axios.post(url + '/' + id, bodyData);
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const _submitAiAns = async (data) => {
  try {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const url = `/candidates/audio`;
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _endInt = async (data) => {
  try {
    const url = `/candidates/conclude`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _addHrRemarks = async (data) => {
  try {
    const url = `/candidates/add_remarks`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _addViolation = async (data) => {
  try {
    const url = `/candidates/add_violations`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _removeViolation = async (data) => {
  try {
    const url = `/candidates/remove_violations`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _releaseOffer = async (id) => {
  try {
    const url = `/candidates/release_offer/${id}`;
    const response = await axios.put(url);
    return response;
  } catch (error) {
    throw error;
  }
};
export const _giveUserConsent = async (id) => {
  try {
    const url = `/applicants/give_consent/${id}`;
    const response = await axios.put(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _saveInterviewSession = async (interviewData) => {
  try {
    const url = `/applicants/interview_session`;
    const response = await axios.post(url, interviewData);
    return response;
  } catch (error) {
    throw error;
  }
};