import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { _getMeetings } from "../../services/meeting.service";
import MeetingEventModal from "../../components/meetings/MeetingEventModal"; // Adjust path as needed

const localizer = momentLocalizer(moment);

const MeetingCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const getMeetings = async () => {
    try {
      const response = await _getMeetings();
      console.log("response", response);
      if (response.status === 200 && response.data.meetings) {
        const data = response.data.meetings.map((item, index) => ({
          id: index,
          title: item.meeting_title,
          start: new Date(item.start_time), // e.g., "2025-03-11T09:00:00.000Z"
          end: new Date(item.end_time),       // e.g., "2025-03-11T09:45:00.000Z"
          description: item.meeting_description,
          roomId: item.room_id,
          location: item.location,
        }));
        setEvents(data);
      }
    } catch (error) {
      console.error("Error fetching meetings:", error);
    }
  };

  useEffect(() => {
    getMeetings();
  }, []);

  // Optional: Customize event styling
  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: "#3174ad",
      color: "#fff",
      borderRadius: "4px",
      border: "none",
      padding: "4px",
    };
    return { style };
  };

  // Handle event selection
  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
  };

  return (
    <div style={{ height: "90vh", padding: "20px" }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView="month"
        views={["month", "week", "day", "agenda"]}
        style={{ height: "100%" }}
        eventPropGetter={eventStyleGetter}
        tooltipAccessor="description"
        onSelectEvent={handleEventSelect}
      />
      <MeetingEventModal
        event={selectedEvent}
        open={modalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default MeetingCalendar;
