import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useSpeechSynthesis } from "react-speech-kit";

import {
  Stack,
  Card,
  Typography,
  CardHeader,
  CardContent,
  Button,
  IconButton,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import WebcamVideo from "../../components/Recorder/VideoRecorder";
import { getSkillLevel } from "../../utils/getSkillLevelName";
import {
  startInterview,
  concludeInt,
  submitAIanswer,
} from "../../redux/actions/employee";
import AlertDialog from "../../components/Dialog";
import { useNavigate } from "react-router-dom";
import { Userprofile } from "../../redux/actions/auth";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

export const InterviewPage = ({
  submitAIanswer,
  concludeInt,
  // dashboard: { uploadCVRes, submitAnsRes },
  Userprofile,
  currentUserDetails,
  interviewData,
  submitAnsRes,
  startInterview,
  interviewEnded,
  skillData,
}) => {
  const navigate = useNavigate();
  // const { speak } = useSpeechSynthesis();

  const [chatThread, setChatThread] = useState([]);
  const [response, setResponse] = useState({});
  const [threadId, setThreadId] = useState(null);
  const [intwId, setIntwId] = useState(null);
  const [assistId, setAssistId] = useState(null);
  const [vectId, setVectId] = useState(null);
  const [qaId, setQaId] = useState(null);
  const [questionCount, setQuestionCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [userId, setUserId] = useState("");
  const [startInt, setStartInt] = useState(false);
  const [audio, setAudio] = useState(null);
  const [mute, setMute] = useState(false);
  const [finalSkills, setFinalSkills] = useState([]);
  const [seId, setSeId] = useState(null);
  const [speaking, setSpeaking] = useState(false);
  const [showWaitMessage, setshowWaitMessage] = useState(false);
  const [interviewConclude, setInterviewConclude] = useState(false);
  const [showConcludeDialog, setShowConcludeDialog] = useState(false);
  const [startCapturing, setStartCapturing] = useState(false);
  const [violationData, setViolationData] = useState("");
  const [nextQestion, setNextQuestion] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false); // State to control loader visibility
  const { speak } = useSpeechSynthesis({
    onStart:()=>{
      // console.log("Helooooooooooooo");
      setSpeaking(true);
    },
    onEnd: () => {
      // console.log("Speech has stopped.");
      setSpeaking(false);
      setshowWaitMessage(false);
      // setStartCapturing(true);  // Start recording once speech stops
      if (interviewConclude) {
        setShowConcludeDialog(true);
      }
    },
  });

  // const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   setShowExitPrompt(!showExitPrompt);
  // };

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   setShowExitPrompt(!showExitPrompt);
  // };

  // useEffect(() => {
  //   return () => {
  //     setShowExitPrompt(false);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (!interviewEnded) {
  //     handleClick();
  //   }
  // }, [interviewEnded]);

  useEffect(() => {
    if (interviewEnded) {
      window.speechSynthesis.cancel();
      setIsLoaderVisible(true);
      setOpen(false);
      setConfirm(false);
      navigate("/dashboard");
      window.location.reload();
    }
  }, [interviewEnded]);

  useEffect(() => {
    if (response && response.question && !mute) {
      setshowWaitMessage(true);
      setStartCapturing(true); // Stop capturing while the system is speaking
      speak({ text: response.question });
      setSpeaking(true);
    }
  }, [response, mute]);

  useEffect(() => {
    // console.log("startInt", skillData && skillData);

  if (startInt) {
    console.log("========>", skillData);
    if (skillData && skillData.length > 0) {
      let newSkills = skillData.map((skill) => {
        return {
          name: skill.ses_skills_name,
          level: getSkillLevel(skill.ses_skills_level),
          experience: skill.ses_skills_exp,
          ses_skills_id: skill.ses_skills_id,
          sk_level: skill.ses_skills_level,
        };
      });
      // console.log("newSkills", newSkills);
      setFinalSkills(newSkills)
      let ses_id = skillData[0].ses_id;

      setSeId(ses_id);

      startInterview(ses_id, newSkills);
    }
  }
  }, [startInt]);

  useEffect(() => {
    let timmer = setTimeout(() => Userprofile());
    return () => {
      clearTimeout(timmer);
    };
  }, []);

  useEffect(() => {
    if (currentUserDetails) {
      setUserId(currentUserDetails?.u_id);
    }
  }, [currentUserDetails]);

  useEffect(() => {
    if (interviewData) {
      setThreadId(interviewData.thread_id);
      setIntwId(interviewData.intw_id);
      setAssistId(interviewData.assistant_id);
      setVectId(interviewData.vector_store_id);
      setQaId(interviewData.intqa_id);
      setQuestionCount(1);
      let obj = {
        id: uuidv4(),
        question: interviewData.question,
        answer: "",
      };
      setResponse(obj);
      // setChatThread([...chatThread, obj]);
    }
  }, [interviewData]);

  useEffect(() => {
    if (submitAnsRes) {
      setThreadId(submitAnsRes.thread_id);
      setIntwId(submitAnsRes.intw_id);
      setAssistId(submitAnsRes.assistant_id);
      setVectId(submitAnsRes.vector_store_id);
      setQaId(submitAnsRes.intqa_id);
      setQuestionCount(submitAnsRes.questionsCount);

      let obj = {
        id: uuidv4(),
        question: submitAnsRes.response,
        answer: submitAnsRes.transcription,
      };
      setResponse(obj);
      setChatThread(submitAnsRes.QAs);
      setNextQuestion(false);
      setIsLoaderVisible(false);
    }
  }, [submitAnsRes]);

  const submitData = async () => {
    // setIsLoaderVisible(true);
    setTitle("Conclude Interview");
    setContent("Are you sure you want to conclude this interview?");
    setOpen(true);
  };

  useEffect(() => {
    if (confirm) {
      window.speechSynthesis.cancel();
      setMute(true);
      setStartCapturing(false);
      concludeInt({
        intwId,
        threadId,
        skills: finalSkills,
        seId: seId,
      });
    }
  }, [confirm]);

  useEffect(() => {
    if (audio) {
      const data = {
        audio_id: audio,
        thread_id: threadId,
        intw_id: intwId,
        assistant_id: assistId,
        vector_store_id: vectId,
        intqa_id: qaId,
        skills: finalSkills,
        seId : seId
      };

      submitAIanswer(data);
      setAudio(null);
    }
  }, [audio]);

  const startInterviewed = () => {
    console.log("Heloooo", startInt);
    setStartInt(true);
  }

  return (
    
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLoaderVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "black", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensure it overlays all other elements
          }}
        >
          <Typography style={{ color: "white", fontSize: "24px" }}>
            Processing, please wait...
          </Typography>
        </div>
      )}
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title={title}
        setConfirm={setConfirm}
        content={content}
      />

      {/* <button onClick={handleClick}>Show/Hide the prompt</button> */}
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2" textAlign="center" color="primary">
          Welcome to
        </Typography>
        <Typography variant="h4" textAlign="center" color="primary">
          {" "}
          AI Interview Assitant
        </Typography>
        {threadId && (
          <>
          <div style={{display:"flex"}}>
            <Button style={{ marginTop: "10px", width: "10vw", textAlign: "center", marginRight:"5px", background:"red", color:"white"}} variant="contained" onClick={submitData} disabled={!threadId}>
              End Test
            </Button>
            <Button  onClick={()=>setNextQuestion(true)} style={{ marginTop: "10px", width: "10vw", textAlign: "center", marginLeft:"5px"}} variant="contained" color="primary" autoFocus>
              Next Question
            </Button>
          </div>
          <Typography variant="caption" color="green" sx={{ mt: 1 }}>
              * Please conclude interview before leaving the page or it will auto conclude when interview is done 
            </Typography>
          </>

        )}
      </Stack>

      {threadId && threadId ? (
        <Stack
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            gap: 3,
          }}
        >
          <Card sx={{ width: "50%" }}>
            <Typography textAlign="center"></Typography>
            <WebcamVideo
              threadId={threadId && threadId}
              userId={userId && userId}
              setAudio={setAudio}
              qaId={qaId && qaId}
              intwId={intwId && intwId}
              startCapturing={startCapturing}
              setViolationData={setViolationData}
              nextQestion={nextQestion}
              setStartCapturing={setStartCapturing}
              setIsLoaderVisible={setIsLoaderVisible}
            />
          </Card>
          <Card sx={{ width: "50%" }}>
            <CardHeader />
            <CardContent>
              {response.question && (
                <>
                  <Typography>
                    {/* {" "}
                    <IconButton onClick={() => setMute(!mute)}>
                      {mute ? <VolumeOffIcon /> : <VolumeUpIcon />} :
                    </IconButton>{" "} */}
                    {response.question}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Stack>
      ) : (
        <Stack
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button variant="contained" onClick={() => startInterviewed()}>
            Start
          </Button>
        </Stack>
      )}

      {chatThread.length > 0 && (
        <Stack
          sx={{
            mt: 5,
            ml: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6">AI Post Interview Analysis</Typography>
          {chatThread.map((res, index) => (
            <>
              {res.intqa_answer ? (
                <Card key={res.intqa_id} sx={{ minWidth: "30vw" }}>
                  <CardContent>
                    <Typography variant="h6">
                      🤖 : {res.intqa_question}
                    </Typography>
                    <Typography>🧑 : {res.intqa_answer}</Typography>
                  </CardContent>
                </Card>
              ) : (
                ""
              )}
            </>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  currentUserDetails: state.auth.user,
  interviewData: state.employee.interviewData,
  submitAnsRes: state.employee.submitAnsRes,
  interviewEnded: state.employee.interviewEnded,
  skillData: state.skills.data?state.skills.data:JSON.parse(localStorage.getItem("skillData")),
});

const mapDispatchToProps = {
  startInterview,
  submitAIanswer,
  concludeInt,
  Userprofile,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewPage);
