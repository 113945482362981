import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  TextField,
  Button,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  getBatch,
  updateBatchTitle,
  getBatchProgress,
} from "../../redux/actions/batches";
import { uploadCVs,clearCreatedJD } from "../../redux/actions/jobs";
import { connect } from "react-redux";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import CandidatesBatch from "./CandidatesBatch";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddIcon from '@mui/icons-material/Add';
import { formatDate } from "../../utils/dateUtils";
import ProgressBarComponent from "../../components/ProgressBar";
import Spinner from "react-spinkit";

const BatchDetails = ({
  getBatch,
  updateBatchTitle,
  batch,
  clearCreatedJD,
  getBatchProgress,
  batchProgress,
  uploadCVs
}) => {
  const [batchData, setBatchData] = useState(null);
  const [newBatchName, setNewBatchName] = useState("");
  const [jobInfo, setJobInfo] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [failedCVs, setFailedCVs] = useState([]);
  const [duplicateCVs, setDuplicateCVs] = useState([]);
  const [relaodData, setRelaodData] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [reloadDataprogress, setRelaodDataProgress] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const location = useLocation();
  const type = location.state?.type || "default";

  const { batch_id } = useParams();
  const navigate = useNavigate();
  let BASE_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;
  let orgName = localStorage.getItem('org_name');
  let API_URL = '';
  if (orgName) {
      API_URL = `${BASE_URL}/${orgName}/api/v1`;
  } else {
      API_URL = `${BASE_URL}/api/v1`;
  }

   // console.log("File data : ", fileData);


  useEffect(() => {
    clearCreatedJD();
  }, []);

  useEffect(() => {
    getBatch(batch_id);
  }, []);

  useEffect(() => {
    if (batchData && batchData.batch && batchData.batch.stepFour) {
      // console.log("batchData : yes", );
     
    } else {
      // console.log("batchData : no", batchData);
       const intervalId = setInterval(() => {
         getBatchProgress(batch_id);
       }, 1000 * 3); // in milliseconds
       return () => clearInterval(intervalId);
    }
  }, [batchData]);

  useEffect(() => {
    if (relaodData) {
      getBatch(batch_id);
      setRelaodData(false);
    }
  }, [relaodData]);

  useEffect(() => {
    if (batchProgress && batchProgress.bp_step_one) setCurrentStep(1);
    if (batchProgress && batchProgress.bp_step_two) setCurrentStep(2);
    if (batchProgress && batchProgress.bp_step_three) setCurrentStep(3);
    if (batchProgress && batchProgress.bp_step_four) {
      setRelaodDataProgress(true);
      setCurrentStep(4);
      //navigate(0);
    }
  }, [batchProgress]);

  useEffect(() => {
    if (currentStep === 4 && reloadDataprogress) {
      setTimeout(() => {
        getBatch(batch_id);
        setRelaodDataProgress(false);
      }, 2000);
    }
  }, [currentStep]);

  const retrycvs=async (jb_id,bt_id) => {
    // console.log({},jb_id,bt_id);
    await uploadCVs({},jb_id,bt_id)
    navigate(0);
  }

  const addManually=async (bt_id, cvName ) => {
    navigate(`/hr/candidates/add-candidate/${bt_id}/${cvName}`);
  }

  useEffect(() => {
    if (batch && batch.batch && batch.batch.bt_id===batch_id) {
      setJobInfo(batch.job);
      setBatchData(batch);
      if (batch.batch && batch.batch.bt_duplicate_cvs) {
        setDuplicateCVs(JSON.parse(batch.batch.bt_duplicate_cvs));
      }
      if (batch.batch && batch.batch.bt_parse_failed_cvs) {
        setFailedCVs(JSON.parse(batch.batch.bt_parse_failed_cvs));
        setFileData(batch.files);
      }
    }
    if (batch && batch.batch && batch.batch.bt_name) {
      setNewBatchName(batch.batch.bt_name);
      // setNewBatchName("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,");
    }

    if (batch && batch.batch && batch.batch.stepOne) setCurrentStep(1);
    if (batch && batch.batch && batch.batch.stepTwo) setCurrentStep(2);
    if (batch && batch.batch && batch.batch.stepThree) setCurrentStep(3);
    if (batch && batch.batch && batch.batch.stepFour) {
      setCurrentStep(4);
    }
  }, [batch]);

  const handleBatchNameUpdate = async () => {
    try {
      await updateBatchTitle(batch_id, { batch_name: newBatchName });
      getBatch(batch_id);
    } catch (error) {
      console.error("Error in updating batch name", error);
    }
    setIsEdit(false);
  };

  // const reloadDataBatch = () => {
  //   setRelaodData(!relaodData);
  // };
  
  return (
    <>
      {batchData && batchData.batch && batchData.batch.bt_id ? (
        <Box sx={{ mt: 3 }}>
          {currentStep && currentStep < 4 ? (
            <Card xs={12} sx={{ mt: 1, }}>
              <ProgressBarComponent currentStep={currentStep} type={type}/>
            </Card>
          ) : null}
          <Card xs={12} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5" sx={{ ml: 2, mt: 2 }}>
                Batch Details
              </Typography>

              {isEdit ? (
                <div>
                  <Button
                    variant="text"
                    size={"small"}
                    onClick={() => setIsEdit(false)}
                    sx={{ mt: 1, color: "red" }}
                  >
                    <CloseIcon /> Cancel
                  </Button>
                  <Button
                    variant="text"
                    size={"small"}
                    onClick={handleBatchNameUpdate}
                    sx={{ mr: 2, mt: 1, color: "green" }}
                  >
                    <SaveIcon /> Save
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    variant="text"
                    color="primary"
                    size={"small"}
                    onClick={() => setRelaodData(true)}
                    sx={{ mr: 2, mt: 1 }}
                  >
                    <AutorenewIcon /> Reload
                  </Button>

                  <Button
                    variant="text"
                    color="primary"
                    size={"small"}
                    onClick={() => setIsEdit(true)}
                    sx={{ mr: 2, mt: 1 }}
                  >
                    <EditIcon /> Edit
                  </Button>
                </div>
              )}
            </Box>
            <Divider />
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={4} sm={2}>
                <Typography variant="subtitle2" fontSize={14}>
                  Batch Name :
                </Typography>
              </Grid>
              <Grid item xs={8} sm={4}>
                {isEdit ? (
                  <TextField
                    name="newBatchName"
                    value={newBatchName}
                    onChange={(e) => setNewBatchName(e.target.value)}
                  />
                ) : (
                  <Typography variant="subtitle1" fontSize={14} noWrap>
                    {batchData ? batchData.batch?.bt_name : ""}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={4} sm={2}>
                <Typography variant="subtitle2" fontSize={14}>
                  Batch Number :
                </Typography>
              </Grid>
              <Grid item xs={8} sm={4}>
                <Typography variant="subtitle1" fontSize={14}>
                  {batchData ? batchData.batch?.bt_number : ""}
                </Typography>
              </Grid>

              <Grid item xs={4} sm={2}>
                <Typography variant="subtitle2" fontSize={14}>
                  Job Name :
                </Typography>
              </Grid>
              <Grid item xs={8} sm={4}>
                <Link
                  to={`/hr/jd-detail/${
                    batchData && batchData.job && batchData.job.jb_id
                  }`}
                >
                  {batchData && batchData.job && batchData.job.jb_name}
                </Link>
                {/* <Typography variant="subtitle1" fontSize={14} noWrap>
              {batchData ? batchData.job?.jb_name : ""}
            </Typography> */}
              </Grid>
              <Grid item xs={4} sm={2}>
                <Typography variant="subtitle2" fontSize={14}>
                  Created At :
                </Typography>
              </Grid>
              <Grid item xs={8} sm={4}>
                <Typography variant="subtitle1" fontSize={14}>
                  {batchData && batchData.batch?.bt_created_at
                    ? formatDate(
                        JSON.parse(localStorage.getItem("user")),
                        batchData?.batch?.bt_created_at
                      )
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Card>

          {duplicateCVs && duplicateCVs.length > 0 && (
            <Card xs={12} sx={{ mt: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5" sx={{ ml: 2, mt: 2 }}>
                  Duplicate CVs : {duplicateCVs.length}
                </Typography>
              </Box>
              <Divider />
              <TableContainer
                style={{
                  marginTop: "10px",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" width={20}>
                        SN
                      </TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">CV</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {duplicateCVs.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.cv}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}


          {currentStep && currentStep === 4 ? (
            <>
              {batchData && batchData.batch?.bt_id ? (
                <CandidatesBatch bT_ID={batch_id} relaodData={relaodData} setRelaodData={setRelaodData} job_id={jobInfo.jb_id}/>
              ) : null}
            </>
          ) : null}
            {failedCVs && failedCVs.length > 0 && (
            <Card xs={12} sx={{ mt: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5" sx={{ ml: 2, mt: 2 }}>
                  Parsing Failed CVs : {failedCVs.length}
                </Typography>
                <Typography variant="h5" sx={{ ml: 2, mt: 2, mr:4 }}>
                <Button
                    variant="text"
                    color="primary"
                    size={"small"}
                    onClick={() => retrycvs(batchData.job.jb_id,batch_id)}
                    sx={{ mr: 2, mt: 1 }}
                  >
                    <AutorenewIcon /> Retry
                  </Button>
                </Typography>
              </Box>
              <Divider />
              <TableContainer
                style={{
                  marginTop: "10px",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" width={20}>
                        SN
                      </TableCell>
                      <TableCell align="left">CV</TableCell>
                      <TableCell align="left">Error</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fileData &&
                      fileData.map((row, index) => (
                        <TableRow
                          key={index.toString()}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell>
                            <Link to={`${API_URL}/failed_resume_applicant/${row.id}`} target="_blank" rel="noopener noreferrer">
                            {row.original_name ? row.original_name : row.cv}                            
                            </Link>
                          </TableCell>
                          <TableCell>{row.reason}</TableCell>
                          <TableCell>
                            <Button
                              variant="text"
                              color="primary"
                              size={"small"}
                              onClick={() => addManually(batch_id, row.cv)}
                              sx={{ mr: 2, mt: 1 }}
                            >
                              <AddIcon /> Add
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}

        </Box>
      ) : (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(16, 16, 16, 0.5)",
            zIndex: 999990,
          }}
        >
          <Spinner
            name="three-bounce"
            style={{
              marginTop: "50vh",
              marginLeft: "50vw",
              zIndex: 999999,
              color: "#0959AA",
            }}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  batch: state.batches.batchData,
  batchProgress: state.batches.batchProgress,
});

const mapDispatchToProps = {
  getBatch,
  updateBatchTitle,
  clearCreatedJD,
  getBatchProgress,
  uploadCVs
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchDetails);
