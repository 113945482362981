import React, { useState } from "react";
import {
  Stack,
  Card,
  CardContent,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HistoryIcon from "@mui/icons-material/History";
import FeedbackIcon from "@mui/icons-material/Feedback";

const InterviewerDashboard = () => {
  // Dummy data for upcoming interviews
  const [upcomingInterviews] = useState([
    {
      interview_id: "int1",
      candidateName: "Alice Johnson",
      scheduledTime: "2025-03-11T10:00:00",
      meetingLink: "https://example.com/meeting/int1",
      status: "Pending",
    },
    {
      interview_id: "int2",
      candidateName: "Bob Smith",
      scheduledTime: "2025-03-11T11:30:00",
      meetingLink: "https://example.com/meeting/int2",
      status: "Confirmed",
    },
  ]);

  // Dummy data for interview requests sent by HR for time and date confirmation
  const [interviewRequests] = useState([
    {
      request_id: "req1",
      candidateName: "Charlie Brown",
      proposedTime: "2025-03-11T14:00:00",
      status: "Awaiting Confirmation",
    },
    {
      request_id: "req2",
      candidateName: "Dana White",
      proposedTime: "2025-03-11T15:30:00",
      status: "Confirmed",
    },
  ]);

  // Dummy data for previous interviews (completed or cancelled)
  const [previousInterviews] = useState([
    {
      interview_id: "prev1",
      candidateName: "Eve Adams",
      completedTime: "2025-03-10T09:00:00",
      meetingLink: "https://example.com/meeting/prev1",
      status: "Completed",
    },
    {
      interview_id: "prev2",
      candidateName: "Frank Miller",
      completedTime: "2025-03-09T15:00:00",
      meetingLink: "https://example.com/meeting/prev2",
      status: "Cancelled",
    },
  ]);

  // Dummy data for interview feedback
  const [interviewFeedback] = useState([
    {
      feedback_id: "fb1",
      candidateName: "Alice Johnson",
      feedback: "Strong technical skills, but needs better communication.",
      status: "Reviewed",
    },
    {
      feedback_id: "fb2",
      candidateName: "Bob Smith",
      feedback: "Excellent problem-solving abilities.",
      status: "Pending",
    },
  ]);

  const totalUpcoming = upcomingInterviews.length;
  const totalRequests = interviewRequests.length;
  const previousInterviewsCount = previousInterviews.length;
  const interviewFeedbackCount = interviewFeedback.length;

  return (
    <div style={{ padding: "20px" }}>
      {/* Dashboard Header */}
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="center"
        sx={{ mb: 4 }}
      >
        <EventIcon sx={{ fontSize: 50 }} />
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Interviewer Dashboard
        </Typography>
      </Stack>

      {/* Overview Cards */}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        sx={{ mb: 4, flexWrap: "wrap" }}
      >
        <Card
          sx={{
            width: "23%",
            backgroundColor: "#e3f2fd",
          }}
        >
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="h4" textAlign="center" color="#0d47a1">
                  {totalUpcoming}
                </Typography>
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{ fontWeight: "bold", color: "#0d47a1" }}
                >
                  Upcoming Interviews
                </Typography>
              </Stack>
              <AccessTimeIcon sx={{ fontSize: 60, color: "#0d47a1" }} />
            </Stack>
          </CardContent>
        </Card>

        <Card
          sx={{
            width: "23%",
            backgroundColor: "#f3e5f5",
          }}
        >
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="h4" textAlign="center" color="#4a148c">
                  {totalRequests}
                </Typography>
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{ fontWeight: "bold", color: "#4a148c" }}
                >
                  Interview Requests
                </Typography>
              </Stack>
              <CheckCircleIcon sx={{ fontSize: 60, color: "#4a148c" }} />
            </Stack>
          </CardContent>
        </Card>

        <Card
          sx={{
            width: "23%",
            backgroundColor: "#e8f5e9",
          }}
        >
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="h4" textAlign="center" color="#1b5e20">
                  {previousInterviewsCount}
                </Typography>
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{ fontWeight: "bold", color: "#1b5e20" }}
                >
                  Previous Interviews
                </Typography>
              </Stack>
              <HistoryIcon sx={{ fontSize: 60, color: "#1b5e20" }} />
            </Stack>
          </CardContent>
        </Card>

        <Card
          sx={{
            width: "23%",
            backgroundColor: "#fce4ec",
          }}
        >
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="h4" textAlign="center" color="#880e4f">
                  {interviewFeedbackCount}
                </Typography>
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{ fontWeight: "bold", color: "#880e4f" }}
                >
                  Interview Feedback
                </Typography>
              </Stack>
              <FeedbackIcon sx={{ fontSize: 60, color: "#880e4f" }} />
            </Stack>
          </CardContent>
        </Card>
      </Stack>

      {/* Tables Section */}
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          {/* Upcoming Interviews Table */}
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="h6">Upcoming Interviews</Typography>
                <Button
                  component={Link}
                  to="/interviews"
                  variant="text"
                  color="primary"
                  size="small"
                >
                  <VisibilityIcon /> View All
                </Button>
              </Stack>
              <Divider />
              <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>
                {upcomingInterviews.length > 0 ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Candidate Name</TableCell>
                        <TableCell>Scheduled Time</TableCell>
                        <TableCell>Meeting Link</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {upcomingInterviews.map((interview) => (
                        <TableRow key={interview.interview_id}>
                          <TableCell>{interview.candidateName}</TableCell>
                          <TableCell>
                            {new Date(interview.scheduledTime).toLocaleString()}
                          </TableCell>
                          <TableCell>
                            <a
                              href={interview.meetingLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Join
                            </a>
                          </TableCell>
                          <TableCell>{interview.status}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    No upcoming interviews scheduled.
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Interview Request Confirmations Table */}
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="h6">
                  Interview Request Confirmations
                </Typography>
                <Button
                  component={Link}
                  to="/interview-requests"
                  variant="text"
                  color="primary"
                  size="small"
                >
                  <VisibilityIcon /> View All
                </Button>
              </Stack>
              <Divider />
              <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>
                {interviewRequests.length > 0 ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Candidate Name</TableCell>
                        <TableCell>Proposed Time</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {interviewRequests.map((request) => (
                        <TableRow key={request.request_id}>
                          <TableCell>{request.candidateName}</TableCell>
                          <TableCell>
                            {new Date(request.proposedTime).toLocaleString()}
                          </TableCell>
                          <TableCell>{request.status}</TableCell>
                          <TableCell>
                            {request.status === "Awaiting Confirmation" && (
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                              >
                                Confirm
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    No interview requests available.
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {/* Previous Interviews Table */}
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="h6">Previous Interviews</Typography>
                <Button
                  component={Link}
                  to="/previous-interviews"
                  variant="text"
                  color="primary"
                  size="small"
                >
                  <VisibilityIcon /> View All
                </Button>
              </Stack>
              <Divider />
              <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>
                {previousInterviews.length > 0 ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Candidate Name</TableCell>
                        <TableCell>Completed Time</TableCell>
                        <TableCell>Meeting Link</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {previousInterviews.map((interview) => (
                        <TableRow key={interview.interview_id}>
                          <TableCell>{interview.candidateName}</TableCell>
                          <TableCell>
                            {new Date(interview.completedTime).toLocaleString()}
                          </TableCell>
                          <TableCell>
                            <a
                              href={interview.meetingLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View
                            </a>
                          </TableCell>
                          <TableCell>{interview.status}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    No previous interviews found.
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Interview Feedback Table */}
          <Card>
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="h6">Interview Feedback</Typography>
                <Button
                  component={Link}
                  to="/interview-feedback"
                  variant="text"
                  color="primary"
                  size="small"
                >
                  <VisibilityIcon /> View All
                </Button>
              </Stack>
              <Divider />
              <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>
                {interviewFeedback.length > 0 ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Candidate Name</TableCell>
                        <TableCell>Feedback</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {interviewFeedback.map((feedback) => (
                        <TableRow key={feedback.feedback_id}>
                          <TableCell>{feedback.candidateName}</TableCell>
                          <TableCell>{feedback.feedback}</TableCell>
                          <TableCell>{feedback.status}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    No interview feedback available.
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default InterviewerDashboard;
