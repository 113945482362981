import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import LinkIcon from "@mui/icons-material/Link";

const MeetingEventModal = ({ event, open, onClose }) => {
  if (!event) return null;

  // Use event.meetingLink if provided, otherwise fallback to event.location.
  const joinLink = event.meetingLink || event.location;

  // Format the interview date, start time, and end time separately.
  const interviewDate = new Date(event.start).toLocaleDateString();
  const startTime = new Date(event.start).toLocaleTimeString();
  const endTime = new Date(event.end).toLocaleTimeString();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          background: "linear-gradient(45deg, #2196F3, #21CBF3)",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EventIcon sx={{ mr: 1 }} />
          <Typography variant="h6">{event.title}</Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ color: "#fff" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 3 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Description:
          </Typography>
          <Typography variant="body1">{event.description}</Typography>
        </Box>
        <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
          <CalendarTodayIcon sx={{ mr: 1, color: "text.secondary" }} />
          <Typography variant="body2">
            <strong>Date:</strong> {interviewDate}
          </Typography>
        </Box>
        <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
          <PlayCircleOutlineIcon sx={{ mr: 1, color: "text.secondary" }} />
          <Typography variant="body2">
            <strong>Start Time:</strong> {startTime}
          </Typography>
        </Box>
        <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
          <StopCircleOutlinedIcon sx={{ mr: 1, color: "text.secondary" }} />
          <Typography variant="body2">
            <strong>End Time:</strong> {endTime}
          </Typography>
        </Box>
        <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
          <LocationOnIcon sx={{ mr: 1, color: "text.secondary" }} />
          <Typography variant="body2">
            <strong>Location:</strong> Girikhire
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<LinkIcon />}
          href={joinLink}
          target="_blank"
        >
          Join Meeting
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MeetingEventModal;
