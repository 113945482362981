import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import {
  Badge,
  IconButton,
  TextField,
  Typography,
  Button,
  Box,
  Grid,
  Drawer,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Switch,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Userprofile } from "../../redux/actions/auth";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import StopScreenShareIcon from "@mui/icons-material/StopScreenShare";
import ChatIcon from "@mui/icons-material/Chat";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StopIcon from "@mui/icons-material/Stop";
import { hideSidebar, showSidebar } from "../../redux/actions/sidebar";
import { hideHeader, showHeader } from "../../redux/actions/header";
import { useParams } from "react-router-dom";

const theme = createTheme({
  palette: {
    mode: "dark",
    text: { primary: "#fff", secondary: "rgba(255, 255, 255, 0.7)" },
    background: { default: "#121212", paper: "#1e1e1e" },
  },
  components: {
    MuiButton: { styleOverrides: { root: { textTransform: "none" } } },
    MuiTypography: { styleOverrides: { root: { color: "#fff" } } },
  },
});

const BASE_URL =
  localStorage.getItem("REACT_APP_WS_URL") || process.env.REACT_APP_WS_URL;
const orgName = localStorage.getItem("org_name");

const peerConfigConnections = {
  iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
};

export const VideoCall = ({
  Userprofile,
  currentUserDetails,
  hideSidebar,
  showSidebar,
  hideHeader,
  showHeader,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const { roomId } = useParams();
  const socketRef = useRef();
  const socketIdRef = useRef();
  const localVideoref = useRef();
  const videoRefs = useRef({});
  const audioAnalyzers = useRef({});
  const previewVideoref = useRef();
  const connectionsRef = useRef({});

  const [videoAvailable, setVideoAvailable] = useState(true);
  const [audioAvailable, setAudioAvailable] = useState(true);
  const [audioDevices, setAudioDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [video, setVideo] = useState(true);
  const [audio, setAudio] = useState(true);
  const [screen, setScreen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [showChatDrawer, setShowChatDrawer] = useState(false);
  const [showAIDrawer, setShowAIDrawer] = useState(false);
  const [screenAvailable, setScreenAvailable] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [newMessages, setNewMessages] = useState(0);
  const [askForUsername, setAskForUsername] = useState(true);
  const [videos, setVideos] = useState([]);
  const [callTime, setCallTime] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const [activeSpeaker, setActiveSpeaker] = useState(null);
  const [cameraOn, setCameraOn] = useState(true);
  const [micOn, setMicOn] = useState(true);
  const [audioDevice, setAudioDevice] = useState("");
  const [videoDevice, setVideoDevice] = useState("");
  const [volume, setVolume] = useState(50);
  const [previewStream, setPreviewStream] = useState(null);
  const [cameraError, setCameraError] = useState(null);
  const [localVideoReady, setLocalVideoReady] = useState(false);

  const baaliAgent = {
    socketId: "baali-ai-agent",
    stream: null,
    autoplay: true,
    playsinline: true,
    name: "Baali AI Interview Agent",
  };

  const allParticipants = [baaliAgent, ...videos];

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setVideoAvailable(true);
        stream.getTracks().forEach((track) => track.stop());
      } catch (err) {
        setVideoAvailable(false);
        setCameraError("Camera access denied");
      }
    };
    checkPermissions();
  }, []);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
      if (window.localStream) {
        window.localStream.getTracks().forEach((track) => track.stop());
        window.localStream = null;
      }
      if (previewStream) {
        previewStream.getTracks().forEach((track) => track.stop());
        setPreviewStream(null);
      }
      Object.values(audioAnalyzers.current).forEach((analyzer) => {
        analyzer.source?.disconnect();
        analyzer.analyser?.disconnect();
        analyzer.audioContext?.close();
      });
      Object.values(connectionsRef.current).forEach((conn) => conn.close());
    };
  }, [previewStream]);

  useEffect(() => {
    let isMounted = true;
    const toggleCameraPreview = async () => {
      if (!isMounted) return;
      if (cameraOn && videoAvailable) {
        try {
          const constraints = {
            video:
              videoDevice && videoDevice !== "none"
                ? { deviceId: { exact: videoDevice } }
                : { width: { ideal: 1280 }, height: { ideal: 720 } },
          };
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          if (isMounted && previewVideoref.current) {
            setPreviewStream(stream);
            previewVideoref.current.srcObject = stream;
            previewVideoref.current
              .play()
              .catch((e) => console.log("Play error:", e));
            setCameraError(null);
          }
        } catch (error) {
          if (isMounted) {
            console.error("Camera error:", error);
            setCameraOn(false);
            setCameraError("Unable to access camera");
          }
        }
      } else if (previewStream && isMounted) {
        previewStream.getTracks().forEach((track) => track.stop());
        setPreviewStream(null);
        if (previewVideoref.current) previewVideoref.current.srcObject = null;
        setCameraError(null);
      }
    };
    toggleCameraPreview();
    return () => {
      isMounted = false;
      if (previewStream)
        previewStream.getTracks().forEach((track) => track.stop());
    };
  }, [cameraOn, videoAvailable, videoDevice]);

  useEffect(() => {
    if (isMounted) getPermissions();
  }, [isMounted]);

  useEffect(() => {
    if (isMounted) {
      hideSidebar();
      hideHeader();
    }
  }, [isMounted, hideSidebar, hideHeader]);

  useEffect(() => {
    if (!askForUsername) {
      const timer = setInterval(() => setCallTime((prev) => prev + 1), 1000);
      return () => clearInterval(timer);
    }
  }, [askForUsername]);

  useEffect(() => {
    if (window.localStream && localVideoref.current) {
      console.log("Assigning local stream to video ref");
      localVideoref.current.srcObject = window.localStream;
      localVideoref.current
        .play()
        .catch((e) => console.error("Local video play error:", e));
    }
  }, [localVideoReady, askForUsername]);

  useEffect(() => {
    console.log("Videos state updated:", videos);
    videos.forEach((video) => {
      if (video.stream && videoRefs.current[video.socketId]) {
        const videoEl = videoRefs.current[video.socketId];
        if (videoEl.srcObject !== video.stream) {
          console.log(`Assigning stream to ${video.socketId}`);
          videoEl.srcObject = video.stream;
          videoEl
            .play()
            .catch((e) =>
              console.error(`Play error for ${video.socketId}:`, e)
            );
        }
      }
    });
  }, [videos]);

  const analyzeAudio = (stream, id) => {
    if (!stream || !stream.getAudioTracks().length) return 0;
    try {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      audioAnalyzers.current[id] = { audioContext, analyser, source };
      const getVolume = () => {
        analyser.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((a, b) => a + b, 0);
        return sum / bufferLength;
      };
      return getVolume;
    } catch (error) {
      console.error(`Error analyzing audio for ${id}:`, error);
      return 0;
    }
  };

  useEffect(() => {
    if (!askForUsername) {
      const simulateBaaliSpeaking = () => {
        setActiveSpeaker("baali-ai-agent");
        setTimeout(() => setActiveSpeaker(null), 3000);
      };
      const interval = setInterval(simulateBaaliSpeaking, 10000);
      return () => clearInterval(interval);
    }
  }, [askForUsername]);

  useEffect(() => {
    if (!askForUsername) {
      const monitorAudioLevels = () => {
        const volumes = {};
        if (window.localStream && window.localStream.getAudioTracks().length) {
          volumes["local"] = analyzeAudio(window.localStream, "local");
        }
        videos.forEach((video) => {
          if (video.stream && video.stream.getAudioTracks().length) {
            volumes[video.socketId] = analyzeAudio(
              video.stream,
              video.socketId
            );
          }
        });
        let maxVolume = 0;
        let speakingId = null;
        Object.entries(volumes).forEach(([id, volume]) => {
          if (volume > maxVolume && volume > 10) {
            maxVolume = volume;
            speakingId = id;
          }
        });
        if (activeSpeaker !== "baali-ai-agent") setActiveSpeaker(speakingId);
      };
      const interval = setInterval(monitorAudioLevels, 100);
      return () => clearInterval(interval);
    }
  }, [videos, askForUsername, activeSpeaker]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const getPermissions = async () => {
    console.log("Starting getPermissions");
    try {
      const videoConstraints =
        videoDevice && videoDevice !== "none"
          ? { video: { deviceId: { exact: videoDevice } } }
          : { video: true };
      const videoPermission = await navigator.mediaDevices.getUserMedia(
        videoConstraints
      );
      setVideoAvailable(!!videoPermission);
      videoPermission.getTracks().forEach((track) => track.stop());

      setScreenAvailable(!!navigator.mediaDevices.getDisplayMedia);

      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log("Devices enumerated:", devices);
      const audioDevicesList = devices.filter((d) => d.kind === "audioinput");
      const videoDevicesList = devices.filter((d) => d.kind === "videoinput");
      setAudioDevices(audioDevicesList);
      setVideoDevices(videoDevicesList);
      console.log("Audio devices:", audioDevicesList);
      console.log("Video devices:", videoDevicesList);

      const audioConstraints = {
        audio:
          audioDevice && audioDevice !== "none"
            ? { deviceId: { exact: audioDevice } }
            : true,
      };
      const audioPermission = await navigator.mediaDevices.getUserMedia(
        audioConstraints
      );
      setAudioAvailable(!!audioPermission);
      audioPermission.getTracks().forEach((track) => track.stop());

      if (videoAvailable || audioAvailable) {
        const userMediaConstraints = {
          video:
            videoDevice && videoDevice !== "none"
              ? { deviceId: { exact: videoDevice } }
              : videoAvailable,
          audio:
            audioDevice && audioDevice !== "none"
              ? { deviceId: { exact: audioDevice } }
              : audioAvailable,
        };
        const userMediaStream = await navigator.mediaDevices.getUserMedia(
          userMediaConstraints
        );
        window.localStream = userMediaStream;
        if (localVideoref.current) {
          localVideoref.current.srcObject = userMediaStream;
          localVideoref.current
            .play()
            .catch((e) => console.log("Play error in getPermissions:", e));
          setLocalVideoReady(true);
        }
      }
    } catch (error) {
      console.error("Error getting permissions:", error);
      if (error.name === "NotAllowedError") {
        setAudioAvailable(false);
        setVideoAvailable(false);
        setCameraError("Permission denied for audio/video");
      } else if (error.name === "NotFoundError") {
        setAudioAvailable(false);
        setVideoAvailable(false);
        setCameraError("Selected device not found");
      }
    }
  };

  useEffect(() => {
    if (
      isMounted &&
      video !== undefined &&
      audio !== undefined &&
      !askForUsername
    ) {
      getUserMedia();
    }
  }, [video, audio, isMounted, askForUsername, videoDevice, audioDevice]);

  const getUserMedia = async () => {
    if ((video && videoAvailable) || (audio && audioAvailable)) {
      const constraints = {
        video:
          video && videoAvailable
            ? videoDevice && videoDevice !== "none"
              ? { deviceId: { exact: videoDevice } }
              : true
            : false,
        audio:
          audio && audioAvailable
            ? audioDevice && audioDevice !== "none"
              ? { deviceId: { exact: audioDevice } }
              : true
            : false,
      };
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        getUserMediaSuccess(stream);
      } catch (e) {
        console.log("Error in getUserMedia:", e);
        setCameraError("Failed to access selected device");
      }
    } else {
      try {
        if (localVideoref.current && localVideoref.current.srcObject) {
          localVideoref.current.srcObject
            .getTracks()
            .forEach((track) => track.stop());
        }
      } catch (e) {
        console.log("Error stopping tracks:", e);
      }
    }
  };

  const getUserMediaSuccess = (stream) => {
    if (window.localStream)
      window.localStream.getTracks().forEach((track) => track.stop());
    window.localStream = stream;
    if (localVideoref.current) {
      localVideoref.current.srcObject = stream;
      localVideoref.current
        .play()
        .catch((e) => console.log("Play error in getUserMediaSuccess:", e));
      setLocalVideoReady(true);
    }

    Object.keys(connectionsRef.current).forEach((id) => {
      if (id !== socketIdRef.current) {
        connectionsRef.current[id].addTrack(stream.getVideoTracks()[0], stream);
        connectionsRef.current[id].addTrack(stream.getAudioTracks()[0], stream);
        connectionsRef.current[id]
          .createOffer()
          .then((description) => {
            connectionsRef.current[id]
              .setLocalDescription(description)
              .then(() => {
                socketRef.current.emit(
                  "signal",
                  id,
                  JSON.stringify({
                    sdp: connectionsRef.current[id].localDescription,
                  })
                );
              })
              .catch((e) => console.log("Error setting local description:", e));
          })
          .catch((e) => console.log("Error creating offer:", e));
      }
    });

    stream.getTracks().forEach((track) => {
      track.onended = () => {
        setVideo(false);
        setAudio(false);
        try {
          if (localVideoref.current && localVideoref.current.srcObject) {
            localVideoref.current.srcObject
              .getTracks()
              .forEach((track) => track.stop());
          }
        } catch (e) {
          console.log("Error stopping tracks on end:", e);
        }
        window.localStream = new MediaStream([black(), silence()]);
        if (localVideoref.current)
          localVideoref.current.srcObject = window.localStream;
        Object.keys(connectionsRef.current).forEach((id) => {
          connectionsRef.current[id].addTrack(
            window.localStream.getVideoTracks()[0],
            window.localStream
          );
          connectionsRef.current[id].addTrack(
            window.localStream.getAudioTracks()[0],
            window.localStream
          );
          connectionsRef.current[id].createOffer().then((description) => {
            connectionsRef.current[id]
              .setLocalDescription(description)
              .then(() => {
                socketRef.current.emit(
                  "signal",
                  id,
                  JSON.stringify({
                    sdp: connectionsRef.current[id].localDescription,
                  })
                );
              })
              .catch((e) =>
                console.log("Error creating offer on track end:", e)
              );
          });
        });
      };
    });
  };

  const getDislayMedia = () => {
    if (screen && navigator.mediaDevices.getDisplayMedia) {
      navigator.mediaDevices
        .getDisplayMedia({ video: true, audio: true })
        .then(getDislayMediaSuccess)
        .catch((e) => console.log("Error in getDisplayMedia:", e));
    }
  };

  const getDislayMediaSuccess = (stream) => {
    if (window.localStream)
      window.localStream.getTracks().forEach((track) => track.stop());
    window.localStream = stream;
    if (localVideoref.current) {
      localVideoref.current.srcObject = stream;
      localVideoref.current
        .play()
        .catch((e) => console.log("Play error in getDislayMediaSuccess:", e));
    }
    Object.keys(connectionsRef.current).forEach((id) => {
      if (id !== socketIdRef.current) {
        connectionsRef.current[id].addTrack(stream.getVideoTracks()[0], stream);
        connectionsRef.current[id].addTrack(stream.getAudioTracks()[0], stream);
        connectionsRef.current[id].createOffer().then((description) => {
          connectionsRef.current[id]
            .setLocalDescription(description)
            .then(() => {
              socketRef.current.emit(
                "signal",
                id,
                JSON.stringify({
                  sdp: connectionsRef.current[id].localDescription,
                })
              );
            })
            .catch((e) =>
              console.log("Error creating offer in getDisplayMedia:", e)
            );
        });
      }
    });
    stream.getTracks().forEach((track) => {
      track.onended = () => {
        setScreen(false);
        try {
          if (localVideoref.current && localVideoref.current.srcObject) {
            localVideoref.current.srcObject
              .getTracks()
              .forEach((track) => track.stop());
          }
        } catch (e) {
          console.log("Error stopping tracks on display media end:", e);
        }
        window.localStream = new MediaStream([black(), silence()]);
        if (localVideoref.current)
          localVideoref.current.srcObject = window.localStream;
        getUserMedia();
      };
    });
  };

  const connectToSocketServer = async () => {
    socketRef.current = io.connect(BASE_URL, {
      path: `/${orgName}/socket.io`,
      secure: false,
    });
    socketRef.current.on("signal", gotMessageFromServer);
    socketRef.current.on("connect", async () => {
      socketRef.current.emit(
        "join-room",
        roomId,
        userInfo.u_id,
        userInfo.u_email
      );
      socketIdRef.current = socketRef.current.id;

      socketRef.current.on("chat-message", addMessage);
      socketRef.current.on("user-left", (id) => {
        setVideos((prevVideos) =>
          prevVideos.filter((video) => video.socketId !== id)
        );
        if (videoRefs.current[id] && videoRefs.current[id].srcObject) {
          videoRefs.current[id].srcObject
            .getTracks()
            .forEach((track) => track.stop());
          videoRefs.current[id].srcObject = null;
        }
        if (audioAnalyzers.current[id]) {
          const analyzer = audioAnalyzers.current[id];
          analyzer.source?.disconnect();
          analyzer.analyser?.disconnect();
          analyzer.audioContext?.close();
          delete audioAnalyzers.current[id];
        }
        if (connectionsRef.current[id]) {
          connectionsRef.current[id].close();
          delete connectionsRef.current[id];
        }
      });

      socketRef.current.on("participants-updated", ({ participantsList }) => {
        const filterList = participantsList.filter(
          (p) => p.email !== userInfo.u_email
        );
        setVideos(
          filterList.map((p) => ({
            socketId: p.socket_id || null,
            stream: null,
            autoplay: true,
            playsinline: true,
            name: p.name,
            email: p.email,
            role: p.role,
          }))
        );
      });

      socketRef.current.on("user-joined", (id, clients) => {
        clients.forEach((socketListId) => {
          if (socketListId === socketIdRef.current) return;
          if (!connectionsRef.current[socketListId]) {
            connectionsRef.current[socketListId] = new RTCPeerConnection(
              peerConfigConnections
            );
            connectionsRef.current[socketListId].onicecandidate = (event) => {
              if (event.candidate) {
                socketRef.current.emit(
                  "signal",
                  socketListId,
                  JSON.stringify({ ice: event.candidate })
                );
              }
            };
            connectionsRef.current[socketListId].ontrack = (event) => {
              const stream = event.streams[0];
              console.log(`Received stream for ${socketListId}`);
              setVideos((prev) => {
                const exists = prev.find((v) => v.socketId === socketListId);
                if (exists && exists.stream === stream) return prev;
                return prev.map((v) =>
                  v.socketId === socketListId ? { ...v, stream } : v
                );
              });
            };
            if (window.localStream) {
              window.localStream
                .getTracks()
                .forEach((track) =>
                  connectionsRef.current[socketListId].addTrack(
                    track,
                    window.localStream
                  )
                );
            }
            connectionsRef.current[socketListId]
              .createOffer()
              .then((description) => {
                connectionsRef.current[socketListId]
                  .setLocalDescription(description)
                  .then(() => {
                    socketRef.current.emit(
                      "signal",
                      socketListId,
                      JSON.stringify({
                        sdp: connectionsRef.current[socketListId]
                          .localDescription,
                      })
                    );
                  })
                  .catch((e) =>
                    console.log("Error setting local description:", e)
                  );
              })
              .catch((e) => console.log("Error creating offer:", e));
          }
        });
      });
    });
  };

  const gotMessageFromServer = (fromId, message) => {
    const signal = JSON.parse(message);
    if (fromId === socketIdRef.current) return;

    if (
      !connectionsRef.current[fromId] ||
      connectionsRef.current[fromId].connectionState === "closed"
    ) {
      connectionsRef.current[fromId] = new RTCPeerConnection(
        peerConfigConnections
      );
      connectionsRef.current[fromId].onicecandidate = (event) => {
        if (event.candidate) {
          socketRef.current.emit(
            "signal",
            fromId,
            JSON.stringify({ ice: event.candidate })
          );
        }
      };
      connectionsRef.current[fromId].ontrack = (event) => {
        const stream = event.streams[0];
        console.log(`Received stream from ${fromId}`);
        setVideos((prev) => {
          const exists = prev.find((v) => v.socketId === fromId);
          if (exists && exists.stream === stream) return prev;
          return prev.map((v) =>
            v.socketId === fromId ? { ...v, stream } : v
          );
        });
      };
      if (window.localStream) {
        window.localStream
          .getTracks()
          .forEach((track) =>
            connectionsRef.current[fromId].addTrack(track, window.localStream)
          );
      }
    }

    if (signal.sdp) {
      connectionsRef.current[fromId]
        .setRemoteDescription(new RTCSessionDescription(signal.sdp))
        .then(() => {
          if (signal.sdp.type === "offer") {
            connectionsRef.current[fromId]
              .createAnswer()
              .then((description) => {
                connectionsRef.current[fromId]
                  .setLocalDescription(description)
                  .then(() => {
                    socketRef.current.emit(
                      "signal",
                      fromId,
                      JSON.stringify({
                        sdp: connectionsRef.current[fromId].localDescription,
                      })
                    );
                  })
                  .catch((e) =>
                    console.log("Error setting local description:", e)
                  );
              })
              .catch((e) => console.log("Error creating answer:", e));
          }
        })
        .catch((e) => console.log("Error setting remote description:", e));
    }
    if (signal.ice) {
      connectionsRef.current[fromId]
        .addIceCandidate(new RTCIceCandidate(signal.ice))
        .catch((e) => console.log("Error adding ICE candidate:", e));
    }
  };

  const silence = () => {
    const ctx = new AudioContext();
    const oscillator = ctx.createOscillator();
    const dst = oscillator.connect(ctx.createMediaStreamDestination());
    oscillator.start();
    ctx.resume();
    return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
  };

  const black = ({ width = 640, height = 480 } = {}) => {
    const canvas = Object.assign(document.createElement("canvas"), {
      width,
      height,
    });
    canvas.getContext("2d").fillRect(0, 0, width, height);
    const stream = canvas.captureStream();
    return Object.assign(stream.getVideoTracks()[0], { enabled: false });
  };

  const handleVideo = () => setVideo(!video);
  const handleAudio = () => setAudio(!audio);
  const handleScreen = () => setScreen(!screen);
  const handleEndCall = () => {
    try {
      if (window.localStream)
        window.localStream.getTracks().forEach((track) => track.stop());
      if (localVideoref.current && localVideoref.current.srcObject) {
        localVideoref.current.srcObject
          .getTracks()
          .forEach((track) => track.stop());
      }
    } catch (e) {
      console.log("Error stopping tracks on end call:", e);
    }
    window.location.href = "/";
  };

  const handleRecord = () => {
    setIsRecording(!isRecording);
    console.log(isRecording ? "Recording stopped..." : "Recording started...");
  };

  const openChatDrawer = () => {
    setShowChatDrawer(true);
    setNewMessages(0);
  };
  const closeChatDrawer = () => setShowChatDrawer(false);
  const openAIDrawer = () => setShowAIDrawer(true);
  const closeAIDrawer = () => setShowAIDrawer(false);
  const handleMessage = (e) => setMessage(e.target.value);

  const addMessage = (data, sender, socketIdSender) => {
    setMessages((prevMessages) => [...prevMessages, { sender, data }]);
    if (socketIdSender !== socketIdRef.current)
      setNewMessages((prev) => prev + 1);
  };

  const sendMessage = () => {
    socketRef.current.emit("chat-message", message, userInfo.u_name);
    setMessage("");
  };

  const connect = () => {
    if (!userInfo?.u_name) {
      console.error("User name not found in localStorage");
      return;
    }
    setAskForUsername(false);
    setVideo(cameraOn && videoAvailable);
    setAudio(micOn && audioAvailable);
    connectToSocketServer();
  };

  useEffect(() => {
    if (screen !== undefined && !askForUsername) getDislayMedia();
  }, [screen, askForUsername]);

  const getInitials = (name) => {
    if (!name) return "?";
    const parts = name.split(" ");
    return parts.length === 1
      ? name.charAt(0).toUpperCase()
      : parts
          .slice(0, 2)
          .map((part) => part.charAt(0).toUpperCase())
          .join("");
  };

  const hasActiveVideoTrack = (stream) => {
    if (!stream) return false;
    const videoTracks = stream.getVideoTracks();
    return videoTracks.length > 0 && videoTracks.some((track) => track.enabled);
  };

  const hasActiveAudioTrack = (stream) => {
    if (!stream) return false;
    const audioTracks = stream.getAudioTracks();
    return audioTracks.length > 0 && audioTracks.some((track) => track.enabled);
  };

  const getAvatarUrl = (seed) => {
    return `https://api.dicebear.com/9.x/avataaars/svg?seed=${encodeURIComponent(
      seed
    )}`;
  };

  const handleAudioDeviceSelection = async (event) => {
    const newAudioDeviceId = event.target.value;
    setAudioDevice(newAudioDeviceId);
    if (newAudioDeviceId === "none") {
      setAudio(false);
      if (window.localStream)
        window.localStream.getAudioTracks().forEach((track) => track.stop());
      return;
    }
    try {
      if (window.localStream)
        window.localStream.getAudioTracks().forEach((track) => track.stop());
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: newAudioDeviceId } },
        video:
          videoDevice && videoDevice !== "none"
            ? { deviceId: { exact: videoDevice } }
            : video && videoAvailable,
      });
      if (window.localStream) {
        const videoTracks = window.localStream.getVideoTracks();
        videoTracks.forEach((track) => audioStream.addTrack(track));
        window.localStream = audioStream;
      } else {
        window.localStream = audioStream;
      }
      if (localVideoref.current) {
        localVideoref.current.srcObject = window.localStream;
        await localVideoref.current.play();
      }
      Object.keys(connectionsRef.current).forEach((id) => {
        if (id !== socketIdRef.current) {
          connectionsRef.current[id].addTrack(
            audioStream.getAudioTracks()[0],
            audioStream
          );
          connectionsRef.current[id].createOffer().then((description) => {
            connectionsRef.current[id]
              .setLocalDescription(description)
              .then(() => {
                socketRef.current.emit(
                  "signal",
                  id,
                  JSON.stringify({
                    sdp: connectionsRef.current[id].localDescription,
                  })
                );
              });
          });
        }
      });
      setAudioAvailable(true);
      setCameraError(null);
    } catch (error) {
      console.error("Error changing audio device:", error);
      setAudioAvailable(false);
      setCameraError("Failed to switch audio device");
    }
  };

  const handleVideoDeviceSelection = async (event) => {
    const newVideoDeviceId = event.target.value;
    setVideoDevice(newVideoDeviceId);
    if (newVideoDeviceId === "none") {
      setVideo(false);
      if (window.localStream)
        window.localStream.getVideoTracks().forEach((track) => track.stop());
      return;
    }
    try {
      if (window.localStream)
        window.localStream.getVideoTracks().forEach((track) => track.stop());
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: newVideoDeviceId } },
        audio:
          audioDevice && audioDevice !== "none"
            ? { deviceId: { exact: audioDevice } }
            : audio && audioAvailable,
      });
      if (window.localStream) {
        const audioTracks = window.localStream.getAudioTracks();
        audioTracks.forEach((track) => videoStream.addTrack(track));
        window.localStream = videoStream;
      } else {
        window.localStream = videoStream;
      }
      if (localVideoref.current) {
        localVideoref.current.srcObject = window.localStream;
        await localVideoref.current.play();
      }
      Object.keys(connectionsRef.current).forEach((id) => {
        if (id !== socketIdRef.current) {
          connectionsRef.current[id].addTrack(
            videoStream.getVideoTracks()[0],
            videoStream
          );
          connectionsRef.current[id].createOffer().then((description) => {
            connectionsRef.current[id]
              .setLocalDescription(description)
              .then(() => {
                socketRef.current.emit(
                  "signal",
                  id,
                  JSON.stringify({
                    sdp: connectionsRef.current[id].localDescription,
                  })
                );
              });
          });
        }
      });
      setVideoAvailable(true);
      setCameraError(null);
    } catch (error) {
      console.error("Error changing video device:", error);
      setVideoAvailable(false);
      setCameraError("Failed to switch video device");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: "93vh",
          bgcolor: "#121212",
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        {askForUsername ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              height: "100%",
              bgcolor: "#121212",
              animation: "fadeIn 1s ease-in-out",
            }}
          >
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "#1e1e1e",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    color: "#fff",
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  Video Call
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "rgba(255, 255, 255, 0.8)", mt: 0.5 }}
                >
                  Join the Video Call with Baali AI as{" "}
                  {userInfo?.u_name || "Guest"}
                </Typography>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 4,
                  bgcolor: "transparent",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", sm: "400px" },
                    height: { xs: "200px", sm: "300px" },
                    borderRadius: "16px",
                    overflow: "hidden",
                    position: "relative",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.5)",
                    background: cameraOn
                      ? "transparent"
                      : "rgba(255, 255, 255, 0.05)",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <video
                    ref={previewVideoref}
                    autoPlay
                    muted
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      display: cameraOn && previewStream ? "block" : "none",
                    }}
                  />
                  {!cameraOn && (
                    <Typography
                      variant="body1"
                      sx={{
                        color: "rgba(255, 255, 255, 0.7)",
                        textAlign: "center",
                        px: 2,
                      }}
                    >
                      Your camera is turned off
                    </Typography>
                  )}
                </Box>
                {cameraError && (
                  <Typography variant="body2" sx={{ color: "#ff4d4d", mt: 2 }}>
                    {cameraError}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: "#1e1e1e",
                  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                }}
              >
                <Switch
                  checked={cameraOn}
                  onChange={() => setCameraOn(!cameraOn)}
                  icon={<VideocamOffIcon sx={{ color: "#fff" }} />}
                  checkedIcon={<VideocamIcon sx={{ color: "#fff" }} />}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: "rgba(255, 255, 255, 0.8)",
                    ml: 1,
                    fontWeight: 500,
                  }}
                >
                  Camera
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", sm: "320px" },
                bgcolor: "rgba(255, 255, 255, 0.03)",
                p: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "#fff", mb: 3, fontWeight: 600 }}
              >
                Media Settings
              </Typography>
              <FormControl variant="outlined" sx={{ mb: 3 }}>
                <InputLabel sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
                  Video Device
                </InputLabel>
                <Select
                  value={videoDevice}
                  onChange={handleVideoDeviceSelection}
                  label="Video Device"
                >
                  <MenuItem value="none">Don't use video</MenuItem>
                  {videoDevices.map((device) => (
                    <MenuItem key={device.deviceId} value={device.deviceId}>
                      {device.label || `Camera ${device.deviceId}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{ mb: 3 }}>
                <InputLabel sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
                  Audio Device
                </InputLabel>
                <Select
                  value={audioDevice}
                  onChange={handleAudioDeviceSelection}
                  label="Audio Device"
                >
                  <MenuItem value="none">Don't use audio</MenuItem>
                  {audioDevices.map((device) => (
                    <MenuItem key={device.deviceId} value={device.deviceId}>
                      {device.label || `Microphone ${device.deviceId}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {audioDevice !== "none" && (
                <>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Switch
                      checked={micOn}
                      onChange={() => setMicOn(!micOn)}
                      icon={<MicOffIcon sx={{ color: "#fff" }} />}
                      checkedIcon={<MicIcon sx={{ color: "#fff" }} />}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        color: "rgba(255, 255, 255, 0.8)",
                        ml: 1,
                        fontWeight: 500,
                      }}
                    >
                      Microphone
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "rgba(255, 255, 255, 0.8)",
                        mb: 1,
                        fontWeight: 500,
                      }}
                    >
                      Volume
                    </Typography>
                    <Slider
                      value={volume}
                      onChange={(e, newValue) => setVolume(newValue)}
                      min={0}
                      max={100}
                    />
                  </Box>
                </>
              )}
              <Box
                sx={{
                  mt: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => (window.location.href = "/")}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={connect}
                  disabled={!userInfo?.u_name}
                >
                  Join now
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column", p: 2 }}>
            <Grid container spacing={2} sx={{ flex: 1, overflow: "auto" }}>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    position: "relative",
                    bgcolor: "#2e2e2e",
                    borderRadius: 2,
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    border:
                      activeSpeaker === "local" ? "3px solid #00ff00" : "none",
                    animation:
                      activeSpeaker === "local"
                        ? "glow 1s ease-in-out infinite alternate"
                        : "none",
                    "@keyframes glow": {
                      "0%": { boxShadow: "0 0 5px #00ff00" },
                      "100%": { boxShadow: "0 0 20px #00ff00" },
                    },
                  }}
                >
                  {hasActiveVideoTrack(window.localStream) && video ? (
                    <video
                      ref={localVideoref}
                      autoPlay
                      muted
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Avatar
                      src={getAvatarUrl(userInfo?.u_name || "default")}
                      sx={{
                        width: 100,
                        height: 100,
                        bgcolor: "#555",
                        fontSize: 40,
                      }}
                    >
                      {getInitials(userInfo?.u_name)}
                    </Avatar>
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      bgcolor: "rgba(0, 0, 0, 0.6)",
                      color: "#fff",
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                    }}
                  >
                    {userInfo?.u_name || "You"}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 8,
                      left: 8,
                      bgcolor: "rgba(0, 0, 0, 0.6)",
                      borderRadius: "50%",
                      p: 0.5,
                    }}
                  >
                    {audio && hasActiveAudioTrack(window.localStream) ? (
                      <MicIcon sx={{ color: "#fff", fontSize: 20 }} />
                    ) : (
                      <MicOffIcon sx={{ color: "#ff0000", fontSize: 20 }} />
                    )}
                  </Box>
                </Box>
              </Grid>
              {allParticipants.map((video) => (
                <Grid item xs={12} sm={6} md={4} key={video.socketId}>
                  <Box
                    sx={{
                      position: "relative",
                      bgcolor: "#2e2e2e",
                      borderRadius: 2,
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      border:
                        activeSpeaker === video.socketId
                          ? "3px solid #00ff00"
                          : "none",
                      animation:
                        activeSpeaker === video.socketId
                          ? "glow 1s ease-in-out infinite alternate"
                          : "none",
                      "@keyframes glow": {
                        "0%": { boxShadow: "0 0 5px #00ff00" },
                        "100%": { boxShadow: "0 0 20px #00ff00" },
                      },
                    }}
                  >
                    {video.socketId === "baali-ai-agent" ? (
                      <Avatar
                        src={getAvatarUrl(video.name || video.socketId)}
                        sx={{
                          width: 100,
                          height: 100,
                          bgcolor: "#555",
                          fontSize: 40,
                        }}
                      >
                        {getInitials(video.name || video.socketId)}
                      </Avatar>
                    ) : hasActiveVideoTrack(video.stream) ? (
                      <video
                        data-socket={video.socketId}
                        ref={(ref) => (videoRefs.current[video.socketId] = ref)}
                        autoPlay
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Avatar
                        src={getAvatarUrl(video.name || video.socketId)}
                        sx={{
                          width: 100,
                          height: 100,
                          bgcolor: "#555",
                          fontSize: 40,
                        }}
                      >
                        {getInitials(video.name || video.socketId)}
                      </Avatar>
                    )}
                    <Typography
                      variant="caption"
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        bgcolor: "rgba(0, 0, 0, 0.6)",
                        color: "#fff",
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                      }}
                    >
                      {video.name || video.socketId}
                    </Typography>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 8,
                        left: 8,
                        bgcolor: "rgba(0, 0, 0, 0.6)",
                        borderRadius: "50%",
                        p: 0.5,
                      }}
                    >
                      {hasActiveAudioTrack(video.stream) ? (
                        <MicIcon sx={{ color: "#fff", fontSize: 20 }} />
                      ) : (
                        <MicOffIcon sx={{ color: "#ff0000", fontSize: 20 }} />
                      )}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 2,
                px: 3,
                bgcolor: "#1c2526",
                mt: 4,
                borderRadius: "8px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    bgcolor: "rgba(0, 0, 0, 0.6)",
                    px: 2,
                    py: 1,
                    borderRadius: 1,
                  }}
                >
                  Time Remaining: {formatTime(callTime)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <IconButton
                  onClick={handleAudio}
                  sx={{ bgcolor: "white", "&:hover": { bgcolor: "#e0e0e0" } }}
                >
                  {audio ? (
                    <MicIcon sx={{ color: "black" }} />
                  ) : (
                    <MicOffIcon sx={{ color: "black" }} />
                  )}
                </IconButton>
                <IconButton
                  onClick={handleVideo}
                  sx={{ bgcolor: "white", "&:hover": { bgcolor: "#e0e0e0" } }}
                >
                  {video ? (
                    <VideocamIcon sx={{ color: "black" }} />
                  ) : (
                    <VideocamOffIcon sx={{ color: "black" }} />
                  )}
                </IconButton>
                {screenAvailable && (
                  <IconButton
                    onClick={handleScreen}
                    sx={{ bgcolor: "white", "&:hover": { bgcolor: "#e0e0e0" } }}
                  >
                    {screen ? (
                      <ScreenShareIcon sx={{ color: "black" }} />
                    ) : (
                      <StopScreenShareIcon sx={{ color: "black" }} />
                    )}
                  </IconButton>
                )}
                <IconButton
                  onClick={handleRecord}
                  sx={{ bgcolor: "white", "&:hover": { bgcolor: "#e0e0e0" } }}
                >
                  {isRecording ? (
                    <StopIcon sx={{ color: "black" }} />
                  ) : (
                    <FiberManualRecordIcon sx={{ color: "black" }} />
                  )}
                </IconButton>
                <IconButton
                  sx={{ bgcolor: "red", "&:hover": { bgcolor: "#d32f2f" } }}
                  onClick={handleEndCall}
                >
                  <CallEndIcon sx={{ color: "white" }} />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <IconButton
                  onClick={openChatDrawer}
                  sx={{ bgcolor: "white", "&:hover": { bgcolor: "#e0e0e0" } }}
                >
                  <Badge badgeContent={newMessages} color="error">
                    <ChatIcon sx={{ color: "black" }} />
                  </Badge>
                </IconButton>
                <IconButton
                  onClick={openAIDrawer}
                  sx={{ bgcolor: "white", "&:hover": { bgcolor: "#e0e0e0" } }}
                >
                  <SmartToyIcon sx={{ color: "black" }} />
                </IconButton>
              </Box>
            </Box>
            <Drawer
              anchor="right"
              open={showChatDrawer}
              onClose={closeChatDrawer}
              sx={{
                "& .MuiDrawer-paper": {
                  width: 350,
                  bgcolor: "#1e1e1e",
                  color: "#fff",
                  p: 2,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6">Chat</Typography>
                <IconButton onClick={closeChatDrawer} sx={{ color: "#fff" }}>
                  <ChatIcon />
                </IconButton>
              </Box>
              <Box sx={{ flex: 1, overflowY: "auto", mb: 2 }}>
                {messages.length > 0 ? (
                  messages.map((item, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                      >
                        {item.sender}
                      </Typography>
                      <Typography variant="body2">{item.data}</Typography>
                    </Box>
                  ))
                ) : (
                  <Typography>No Messages Yet</Typography>
                )}
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <TextField
                  value={message}
                  onChange={handleMessage}
                  label="Type a message"
                  variant="outlined"
                  size="small"
                  sx={{ flex: 1, bgcolor: "#fff", borderRadius: 1 }}
                />
                <Button
                  onClick={sendMessage}
                  variant="contained"
                  color="primary"
                >
                  Send
                </Button>
              </Box>
            </Drawer>
            <Drawer
              anchor="right"
              open={showAIDrawer}
              onClose={closeAIDrawer}
              sx={{
                "& .MuiDrawer-paper": {
                  width: 350,
                  bgcolor: "#1e1e1e",
                  color: "#fff",
                  p: 2,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6">AI Chat</Typography>
                <IconButton onClick={closeAIDrawer} sx={{ color: "#fff" }}>
                  <SmartToyIcon />
                </IconButton>
              </Box>
              <Box sx={{ flex: 1, overflowY: "auto", mb: 2 }}>
                <Typography>AI Chat functionality coming soon...</Typography>
              </Box>
            </Drawer>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  currentUserDetails: state.auth.user,
});

const mapDispatchToProps = {
  Userprofile,
  hideSidebar,
  showSidebar,
  hideHeader,
  showHeader,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(VideoCall));
