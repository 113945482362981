import { SHOW_HEADER, HIDE_HEADER } from "../actions/types";

const initialState = {
    isHeader: true,
};

const header = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SHOW_HEADER:
      return {
        ...state,
        isHeader: true,
      };
    case HIDE_HEADER:
      return {
        ...state,
        isHeader: false,
      };

    default:
      return state;
  }
};
export default header;
