import React, { useEffect, useState, useRef } from "react";
import { Tooltip } from "@mui/material";

import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Box,
  Stack,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import { connect } from "react-redux";
import { getQAs } from "../../redux/actions/candidate";
import { useParams, useNavigate } from "react-router-dom";

let BASE_URL =
  localStorage.getItem("REACT_APP_API_URL") || process.env.REACT_APP_API_URL;
let orgName = localStorage.getItem("org_name");
let API_URL = "";
if (orgName) {
  API_URL = `${BASE_URL}/${orgName}/api/v1`;
} else {
  API_URL = `${BASE_URL}/api/v1`;
}

const InterviewDetails = ({ getQAs, qaData }) => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const { candidateId, threadId } = useParams();
  const [currentAns, setCurrentAns] = useState("");
  const [currentQues, setCurrentQues] = useState("");
  const [videos, setVideos] = useState([]);
  const [qaId, setQaId] = useState(null);
  const [currentVideoNumber, setCurrentVideoNumber] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [userName, setUserName] = useState("");

  // Selected tab state: 0 = Interview, 1 = MCQ, 2 = Coding, 3 = Screen Recording
  const [selectedTab, setSelectedTab] = useState(0);
  const [mcqStatus, setMcqStatus] = useState(false);
  const [codingStatus, setCodingStatus] = useState(false);

  useEffect(() => {
    if (candidateId && threadId) getQAs(candidateId, threadId);
  }, [threadId, candidateId]);

  useEffect(() => {
    if (qaData && qaData.QAs && qaData.QAs.length > 0) {
      console.log('qaData.QAs', qaData);
      setTotalVideos(qaData.QAs.length);
      setVideos(qaData.QAs);
      setQaId(qaData.QAs[0].intqa_id);
      setCurrentAns(qaData.QAs[0].intqa_answer);
      setCurrentQues(qaData.QAs[0].intqa_question);
    }
    if (qaData && qaData.user && qaData.user.u_name) {
      setUserName(qaData.user.u_name);
    }
    if(qaData && qaData.applicant) {
      setMcqStatus(qaData.applicant.apct_mcq_complete);
      setCodingStatus(qaData.applicant.apct_coding_complete);
    }
  }, [qaData]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.removeAttribute("src");
      videoRef.current.load();
    }
  });

  const handleVideoEnd = () => {
    if (currentVideoNumber + 1 < totalVideos) {
      const newV = videos[currentVideoNumber + 1];
      onClickPlay(newV, currentVideoNumber + 1);
    }
  };

  const startAgain = () => {
    const newV = videos[0];
    onClickPlay(newV, 0);
  };

  const onClickPlay = (qa, i) => {
    setCurrentVideoNumber(i);
    setCurrentAns(qa.intqa_answer);
    setCurrentQues(qa.intqa_question);
    setQaId(qa.intqa_id);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          // height: "55vh",
        }}
      >
        <Grid
          xs={12}
          sm={3}
          item
          sx={{
            padding: "10px",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              padding: "0 10px",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              <strong>{userName}</strong>
            </Typography>
            <Typography>
              Question : {currentVideoNumber + 1}/{totalVideos}
              {/* {currentVideoNumber + 1 === totalVideos ? (
              <IconButton onClick={startAgain}>
                <ReplayIcon />
              </IconButton>
            ) : null} */}
            </Typography>
          </Stack>
          <div className="div-ques-list">
            {videos.map((c, i) => (
              <Card
                sx={{
                  display: "flex",
                  backgroundColor:
                    i === currentVideoNumber ? "lightgray" : "white",
                }}
                key={c.intqa_id}
                onClick={() => onClickPlay(c, i)}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <div className="div-ques">
                      <strong> {i + 1} : </strong> {c.intqa_question}
                    </div>
                    <div>
                      {c.violations?.voiceValidation && (
                        <Tooltip title={c.violations.voiceValidation} arrow>
                          <span style={{ color: "red" }}>⚠ Voice Issue</span>
                        </Tooltip>
                      )}
                      {c.violations?.faceValidation && (
                        <Tooltip title={c.violations.faceValidation} arrow>
                          <span style={{ color: "red" }}>⚠ Face Issue</span>
                        </Tooltip>
                      )}
                      {c.violations?.lipSyncValidation && (
                        <Tooltip title={c.violations.lipSyncValidation} arrow>
                          <span style={{ color: "red" }}>⚠ Lip Sync Issue</span>
                        </Tooltip>
                      )}
                      {c.violations?.phoneValidation && (
                        <Tooltip title={"Cell Phone Detected"} arrow>
                          <span style={{ color: "red" }}>
                            ✔ Cell Phone Detected
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </CardContent>
                </Box>

                {/* <CardMedia component="img" sx={{ width: 200 }} alt="" /> */}
              </Card>
            ))}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <video
              ref={videoRef}
              width="100%"
              height="100%"
              autoPlay
              controls
              onEnded={handleVideoEnd}
              className="shadow-video"
            >
              <source
                src={`${API_URL}/candidates/ans_video/${candidateId}/${qaId}`}
                type="video/mp4"
              ></source>
              Your browser does not support the video tag.
            </video>
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={3}
          item
          sx={{
            paddingLeft: "10px",
            marginTop: "-15px",
          }}
        >
          <div className="div-ques-list">
            {currentQues && (
              <Card>
                <CardContent>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="justify"
                    flexGrow={1}
                  >
                    <strong> Question : </strong>
                    {currentQues}
                  </Typography>
                </CardContent>
              </Card>
            )}
            {currentAns && (
              <Card>
                <CardContent>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="justify"
                    flexGrow={1}
                  >
                    <strong> Answer : </strong>
                    {currentAns}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </div>
        </Grid>
      </Grid>
      <Divider />

      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <Tab label="MCQ" id="tab-0" aria-controls="tabpanel-0" />
        <Tab label="Coding" id="tab-1" aria-controls="tabpanel-1" />
        <Tab label="Interview" id="tab-2" aria-controls="tabpanel-2" />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        {/* <Typography variant="h5" gutterBottom>
          MCQ Screen Recording
        </Typography> */}
        <Box sx={{ marginTop: "10px", textAlign: "center" }}>
        {mcqStatus ? (
            <video style={{ width: "70%", margin: "0 auto", display: "block" }} controls>
              <source
                src={`${API_URL}/candidates/screen_recording/${candidateId}/mcq`}
                type="video/webm"
              />
              Your browser does not support the video tag.
            </video>
          ) : (
            <Typography variant="body1" align="center">
              MCQ screen recording not available.
            </Typography>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {/* <Typography variant="h5" gutterBottom>
          Coding Screen Recording
        </Typography> */}
        <Box sx={{ marginTop: "10px", textAlign: "center" }}>
          {codingStatus ? (
            <video style={{ width: "70%", margin: "0 auto", display: "block" }} controls>
              <source
                src={`${API_URL}/candidates/screen_recording/${candidateId}/coding`}
                type="video/webm"
              />
              Your browser does not support the video tag.
            </video>
          ) : (
            <Typography variant="body1" align="center">
              coding screen recording not available.
            </Typography>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        {/* <Typography variant="h5" gutterBottom>
          Interview Screen Recording
        </Typography> */}
        <Box sx={{ marginTop: "10px", textAlign: "center" }}>
          <video style={{ width: "70%", margin: "0 auto", display: "block" }} controls>
            <source
              src={`${API_URL}/candidates/screen_recording/${candidateId}/${"interview"}`}
              type="video/webm"
            />
            Your browser does not support the video tag.
          </video>
        </Box>
      </TabPanel>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <Button
          variant="text"
          size={"small"}
          sx={{ mt: 1, color: "primary" }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Stack>
    </>
  );
};

const mapStateToProps = (state) => ({
  qaData: state.candidate.qaData,
  user: state.auth.user,
});

const mapDispatchToProps = {
  getQAs,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewDetails);
