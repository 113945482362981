import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useSpeechSynthesis } from "react-speech-kit";
import {
  Stack,
  Card,
  Typography,
  CardHeader,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Box,
  Modal,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import WebcamVideo from "../../components/Recorder/VideoRecorder";
import AlertDialog from "../../components/Dialog";
import { useNavigate } from "react-router-dom";
import { Userprofile } from "../../redux/actions/auth";
import useExitPrompt from "../../hooks/exitPropmts";
import { toast } from "react-toastify";
import { hideSidebar, showSidebar } from "../../redux/actions/sidebar";
import { getAvatar } from "../../services/avatar.service";
import useFullscreen from "../../hooks/fullScreenWindow";
import CircularProgress from '@mui/material/CircularProgress';
import { _startCandInterview, _submitCandInterviewQuestion, _giveUserConsent, _endCandInterview, _getInterviewEndTime, _saveInterviewSession } from "../../services/candidate.service";
import LipSyncAvatar from "../../components/Avtar"; // Adjust the path as needed
import { useScreenRecorder } from '../../contexts/ScreenRecorderContext';
// Modal popup styles
const modalPopUpStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '8px',
  textAlign: 'center',
};

export const InterviewPage = ({
  Userprofile,
  currentUserDetails,
  hideSidebar,
  showSidebar,
  dashboard: { submitIntAnsResRetry },
  InterViewStage,
  interViewThreadId,
  streamRef,
  startInterviewButton,
  interviewEnded,
  setInterviewEnded,
  language
}) => {
  // console.log("InterViewStage===>", startInterviewButton);
  const navigate = useNavigate();
  const [chatThread, setChatThread] = useState([]);
  const [response, setResponse] = useState({});
  const [threadId, setThreadId] = useState(null);
  const [intwId, setIntwId] = useState(null);
  const [assistId, setAssistId] = useState(null);
  const [vectId, setVectId] = useState(null);
  const [qaId, setQaId] = useState(null);
  const [questionCount, setQuestionCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [userId, setUserId] = useState("");
  const [startInt, setStartInt] = useState(false);
  const [audio, setAudio] = useState(null);
  const [mute, setMute] = useState(false);
  const [showConsentPopup, setShowConsentPopup] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [interviewStarted, setInterviewStarted] = useState(false); // State to track if the interview is started
  const [conclusionAttempted, setConclusionAttempted] = useState(false); // State to track if interview conclusion was attempted
  const [showConcludeDialog, setShowConcludeDialog] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const { isFullScreen, enterFullScreen } = useFullscreen();
  const [startCapturing, setStartCapturing] = useState(false);
  const [transcriptData, setTranscriptData] = useState("");
  const [interviewConclude, setInterviewConclude] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [resumeInterView, setResumeInterView] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [speaking, setSpeaking] = useState(false);
  const [violationData, setViolationData] = useState("");
  const [nextQestion, setNextQuestion] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showWaitMessage, setshowWaitMessage] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false); // State to control loader visibility
  const [avatarData, setAvatarData] = useState(null);
  const [submitAnsRes, setSubmitAnsRes] = useState(null);
  const currentDate = new Date().toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [interviewData, setInterviewData] = useState(null);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  const { speak } = useSpeechSynthesis({
    onStart: () => {
      // console.log("Helooooooooooooo");
      setSpeaking(true);
    },
    onEnd: () => {
      // console.log("Speech has stopped.");
      setSpeaking(false);
      setshowWaitMessage(false);
      // setStartCapturing(true);  // Start recording once speech stops
      if (interviewConclude) {
        setShowConcludeDialog(true);
      }
    },
  });
  const endInterviewPhrases = [
    "please end interview",
    "conclude interview",
    "terminate interview",
    "stop interview",
    "end this conversation",
    "finish interview",
    "end interview"
  ];

  const checkForEndInterview = (inputText) => {
    return endInterviewPhrases.some((phrase) =>
      inputText.toLowerCase().includes(phrase)
    );
  };

  useEffect(() => {
    // console.log(transcriptData);
    if (!interviewConclude && transcriptData !== "" && checkForEndInterview(transcriptData)) {
      console.log("Interview Ending...");
      // Add logic to end the interview
      speak({ text: "Okay, since you want to conclude the interview, I will now end it. For any further queries, you can reach out to our HR team." });
      setInterviewConclude(true);
      setNextQuestion(false);
    }
  }, [transcriptData]);

  const {
    isRecording: isScreenRecording,
    startRecording: startScreenRecording,
    stopRecording: stopScreenRecording,
    recordingUrl: screenRecordingUrl,
    isEntireScreenSelected
  } = useScreenRecorder();

  useEffect(() => {
    const fetchVoices = () => {
      console.log("Fetching voices...", language);
      const availableVoices = window.speechSynthesis.getVoices();
      console.log("DDDDDD=======>",availableVoices);
      setVoices(availableVoices);
      if (availableVoices.length) {
        console.log(`${language}-${language.toUpperCase()}`);
        const lng = `${language}-${language}`;
        // Set default to the first female voice if available
        const defaultVoice = availableVoices.find((v) =>
          v.lang.toLowerCase().includes(language)
        );
        console.log("Default=====>", defaultVoice);
        // console.log("Default=====>", defaultVoice);
        setSelectedVoice(defaultVoice || availableVoices[0]);
      }
    };

    // Fetch voices when they become available
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = fetchVoices;
    }
    if (!selectedVoice) {
      fetchVoices();
    }
    
  }, [selectedVoice]);
  const [interviewEndStatus, setInterviewEndStatus] = useState(false);
  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const avatar = await getAvatar();
        // console.log("AVATAR===>", avatar);
        if (avatar && avatar?.avatar) {
          setAvatarData(avatar.avatar);
        }
      } catch (error) {
        console.error("Error fetching avatar:", error);
      }
    }
    fetchAvatar();
  }, [])
  useEffect(() => {
    // Define the function to fetch and update the status
    const fetchInterviewEndStatus = async () => {
      try {
        const status = await _getInterviewEndTime(currentUserDetails.u_apct_id_fk);
        if (status.message) {
          setInterviewEndStatus(true);
        }

      } catch (error) {
        console.error("Error fetching interview end status:", error);
      }
    };

    if (interviewStarted) {
      fetchInterviewEndStatus();

      // Start the interval
      const intervalId = setInterval(() => {
        fetchInterviewEndStatus();
      }, 60000); // 1 minute

      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    }

    // Fetch immediately on component mount or when dependency changes

  }, [currentUserDetails.u_apct_id_fk, interviewStarted]);

  const stopAllStreams = async () => {
    try {
      // Get all active media streams
      const streams = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

      // Stop all video and audio tracks
      streams.getTracks().forEach(track => {
        track.stop();
        console.log(`Stopped ${track.kind} track from ${track.label}`);
      });

      // Force browser to release the camera
      navigator.mediaDevices.getUserMedia({ video: false, audio: false })
        .then(emptyStream => {
          emptyStream.getTracks().forEach(track => track.stop());
        })
        .catch(() => console.log("No active media streams."));

      console.log("All media streams stopped successfully.");

      // Ensure no video elements are keeping the stream alive
      const videoElements = document.querySelectorAll("video");
      videoElements.forEach(video => {
        video.srcObject = null; // Clear video element stream
      });

    } catch (error) {
      console.error("Error stopping media streams:", error);
    }
  };

  useEffect(() => {
    if (interviewEndStatus) {
      setConfirm(true);
    }
  }, [interviewEndStatus]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const checkSpeachEnd = localStorage.getItem("speakEnd");
  //     if (checkSpeachEnd === "true") {
  //       console.log("Speech has stopped.");
  //       setStartCapturing(true); // Start recording once speech stops
  //       if (interviewConclude) {
  //         setMute(true);
  //         setShowConcludeDialog(true);
  //         localStorage.removeItem("interviewStarted");
  //         localStorage.removeItem("speakEnd");
  //         localStorage.removeItem("questiontext");
  //       }
  //     }
  //   }, 500); // Check every 500ms

  //   // Cleanup
  //   return () => clearInterval(interval);
  // }, [interviewConclude]);

  const confirmConclude = () => {
    setShowConcludeDialog(false);
    window.speechSynthesis.cancel();
    setIsLoaderVisible(true);
    concludeInterview();
    // setTimeout(() => {
    //     // Auto call after 10 seconds
    // }, 10000);
  };

  useEffect(() => {
    if (interviewEnded) {
      setInterviewStarted(false); // Reset flag when the interview ends
      setOpen(false);
      setConfirm(false);
      let currenOrg = localStorage.getItem("org_name");
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 10000);
    }
  }, [interviewEnded]);

  useEffect(() => {
    if (response && response.question && !mute) {
      setshowWaitMessage(true);
      setStartCapturing(true); // Stop capturing while the system is speaking
      speak({ text: response.question, voice: selectedVoice });
      setSpeaking(true);
      setIsLoaderVisible(false);
    }
  }, [response, mute]);

  // Handle the starting of the interview
  const initiateInterview = async () => {
    if (!interviewStarted) { // Only start interview if it hasn't been started
      try {
        setIsLoaderVisible(true);
        let intdata = null;
        if (resumeInterView) {
          intdata = await _startCandInterview(currentUserDetails.u_apct_id_fk, interViewThreadId);
        } else {
          intdata = await _startCandInterview(currentUserDetails.u_apct_id_fk);
        }
        // console.log("Intdata=====>", intdata?.data);
        setInterviewData(intdata?.data);
        setInterviewStarted(true); // Set flag to true after successful API call
      } catch (error) {
        console.error("Failed to start the interview", error);
        setInterviewStarted(false); // Reset flag if there was an error
      } finally {
        setIsLoaderVisible(false);
      }
    }
  };

  const timeoutRef = useRef(null);

  const handleStartButtonClick = async () => {
    startScreenRecording("interview");
    timeoutRef.current = setTimeout(() => {
      if (!isEntireScreenSelected) {
        alert("Entire screen share mandatory to process interview");
        window.location.reload();
      }
    }, 10000);
  };

  useEffect(() => {
    if (isEntireScreenSelected) {
      localStorage.setItem("interviewStarted", "true");
      enterFullScreen();
      setStartInt(true);

      // Clear timeout when screen is selected
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    }
  }, [isEntireScreenSelected]);
  const handleResumeButtonClick = async () => {
    localStorage.setItem("interviewStarted", "true");
    enterFullScreen();
    setStartInt(true);
    setResumeInterView(true);
  };

  useEffect(() => {
    if (startInt && !interviewStarted) {
      setShowConsentPopup(true);
      // if (!userConsent) {
      //   setShowConsentPopup(true); // Show consent popup if consent is not given
      // } else {
      //   initiateInterview(); // Start the interview directly if consent is already given
      // }
    }
  }, [startInt]);

  const showErrorModal = (message) => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    enterFullScreen();
  };

  useEffect(() => {
    const submitSession = async () => {
      await _saveInterviewSession({ isFullScreen, apct_id: currentUserDetails.u_apct_id_fk })
      showErrorModal("Full screen exit not allowed");
    }
    if (!isFullScreen) {
      submitSession();
    }
  }, [isFullScreen]);

  useEffect(() => {
    let timer = setTimeout(() => Userprofile(), 1000); // Adjust timeout duration as needed
    return () => {
      clearTimeout(timer);
    };
  }, [Userprofile]);

  useEffect(() => {
    hideSidebar();
  }, [hideSidebar]);

  useEffect(() => {
    if (currentUserDetails) {
      setUserId(currentUserDetails?.u_id);
    }
  }, [currentUserDetails]);

  useEffect(() => {
    if (interviewData) {
      setThreadId(interviewData.thread_id);
      setIntwId(interviewData.intw_id);
      setAssistId(interviewData.assistant_id);
      setVectId(interviewData.vector_store_id);
      setQaId(interviewData.intqa_id);
      setQuestionCount(1);
      if (resumeInterView) {
        setQuestionCount(interviewData.questionsCount);
        setChatThread(interviewData.QAs);
      }
      setTimeLeft(interviewData.time * 60);

      setResponse({
        id: uuidv4(),
        question: interviewData.question,
        answer: "",
      });
      localStorage.setItem("questiontext", interviewData.question);
    }
  }, [interviewData]);

  useEffect(() => {
    if (submitAnsRes) {
      setThreadId(submitAnsRes.thread_id);
      setIntwId(submitAnsRes.intw_id);
      setAssistId(submitAnsRes.assistant_id);
      setVectId(submitAnsRes.vector_store_id);
      setQaId(submitAnsRes.intqa_id);
      setTimeLeft(submitAnsRes.responseTime * 60);
      setQuestionCount(submitAnsRes.questionsCount);
      setResponse({
        id: uuidv4(),
        question: submitAnsRes.response,
        answer: submitAnsRes.transcription,
      });
      setChatThread(submitAnsRes.QAs);
      setInterviewConclude(submitAnsRes.isLast);
      setNextQuestion(false);
      localStorage.setItem("questiontext", submitAnsRes.response);
    }
  }, [submitAnsRes]);

  const submitData = () => {
    setTitle("Conclude Interview");
    setContent("Are you sure you want to conclude this interview?");
    setOpen(true);
  };

  useEffect(() => {
    if (confirm && !conclusionAttempted) { // Only attempt if not already tried
      window.speechSynthesis.cancel();
      if (!interviewEndStatus) {
        setNextQuestion(true);
      }
      setTimeout(() => {
        concludeInterview();
      }, 3000);
    }
  }, [confirm]);

  const concludeInterview = async () => {
    try {
      window.speechSynthesis.cancel();
      setMute(true);
      const interviewendstatusfinal = await _endCandInterview({
        intwId,
        threadId,
        apct_id: currentUserDetails.u_apct_id_fk,
        violationData: violationData,
      });
      if (interviewendstatusfinal?.status == 200) {
        setInterviewEnded(true);
        stopAllStreams();
      }
      setConclusionAttempted(true); // Set flag to true after successful conclusion
      // cleanupResources();
      // showSidebar();
    } catch (error) {
      window.speechSynthesis.cancel();
      console.error("Failed to conclude the interview", error);
      setConfirm(false);
      setConclusionAttempted(false); // Allow retry on failure
    }
  };

  useEffect(() => {
    const subAiAnswer = async () => {
      const data = {
        audio_id: audio,
        thread_id: threadId,
        intw_id: intwId,
        assistant_id: assistId,
        vector_store_id: vectId,
        intqa_id: qaId,
        confirm: confirm
      };

      const resData = await _submitCandInterviewQuestion(data);
      setSubmitAnsRes(resData?.data);
      setAudio(null);
    }
    if (audio) {
      subAiAnswer();
    }
  }, [audio, threadId, intwId, assistId, vectId, qaId, submitIntAnsResRetry]);

  const handleConsentChange = (event) => {
    setConsentGiven(event.target.checked);
  };

  const handleConsentApproval = async () => {
    if (consentGiven) {
      await requestMediaPermissions();
      setShowConsentPopup(false);
    }
  };

  const requestMediaPermissions = async () => {
    try {
      // Retrieve the selected device ID from localStorage
      const selectedDeviceId = localStorage.getItem('selectedDeviceId');
      const selectedDeviceIdAudio = localStorage.getItem('selectedDeviceIdAudio');
      // Build constraints with the selected device ID for video input
      const constraints = {
        video: {
          deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
        },
        audio: selectedDeviceIdAudio ? { deviceId: { exact: selectedDeviceIdAudio } } : undefined, // Enable audio
      };


      // Request media permissions
      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      // Store the media stream in state
      setMediaStream(stream);

      // Execute additional async tasks in order
      await _giveUserConsent(currentUserDetails.u_apct_id_fk);
      // await getApplicant(currentUserDetails.u_apct_id_fk);

      // Start the interview
      initiateInterview();
    } catch (error) {
      console.error("Media permissions denied", error);
      toast.error("Media permissions are required to proceed with the interview.");
    }
  };

  // Function to stop all media tracks (camera and microphone) and exit fullscreen
  const cleanupResources = () => {
    stopMediaTracks();
    exitFullscreen();
  };

  // Function to stop all media tracks (camera and microphone)
  const stopMediaTracks = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null); // Clear the media stream from state
    }
  };

  // Function to exit fullscreen mode
  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error("Error exiting fullscreen:", err);
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.key === 'F12' ||
        (event.altKey && event.key === 'F4') ||
        event.key === 'Escape'
      ) {
        event.preventDefault();
        toast.error("Invalid Action");
      }

      if (event.ctrlKey && (event.key === 'c' || event.key === 'x' || event.key === 'v')) {
        event.preventDefault();
        const actions = { c: 'Copying', x: 'Cutting', v: 'Pasting' };
        toast.error(`${actions[event.key]} is not allowed`);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleTranscript = (transcript) => {
    setTranscriptData(transcript); // Save the transcript data in the state
  };


  // Convert minutes to seconds

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000); // Decrease time every second

      return () => clearInterval(timer); // Cleanup on unmount or index change
    }
  }, [timeLeft]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Add custom logic here to detect hard reload
      window.speechSynthesis.cancel();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // useEffect(() => {
  //   if (avatarData) {
  //     if (avatarData.avog_avatar_voice == "female") {
  //       setSelectedVoice(voices[2]);
  //     } else {
  //       setSelectedVoice(voices[0]);
  //     }

  //   }
  // }, [avatarData]);

  return (
    <Stack sx={{ display: "flex", flexDirection: "column" }}>
      {isLoaderVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "black", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensure it overlays all other elements
          }}
        >
          <Typography style={{ color: "white", fontSize: "24px" }}>
            Processing, please wait...
          </Typography>
        </div>
      )}
      <Dialog open={showConsentPopup} onClose={() => setShowConsentPopup(false)}>
        <DialogTitle>Consent Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To proceed, we need your consent to capture audio and video for the interview process.
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={consentGiven}
                onChange={handleConsentChange}
                name="consent"
                color="primary"
              />
            }
            label="We would like to capture your audio and video for this session.
                By clicking 'I Consent,' you agree to allow us to capture and use your audio and video as needed."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConsentApproval} disabled={!consentGiven} color="primary">
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      {/* Error Modal */}
      <Modal
        open={openErrorModal}
        onClose={() => { }}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="error-modal-title">
            Error
          </Typography>
          <Typography sx={{ mt: 2 }}>{errorMessage}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseErrorModal}
            sx={{ mt: 3 }}
          >
            OK
          </Button>
        </Box>
      </Modal>
      <AlertDialog open={open} setOpen={setOpen} title={title} setConfirm={setConfirm} content={content} />

      <Stack sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="subtitle2" textAlign="center" color="primary">Welcome to</Typography>
        <Typography variant="h4" textAlign="center" color="primary">AI Interview Assistant</Typography>
        {!threadId && <Typography variant="caption" color="green" sx={{ mt: 1, fontSize: "16px" }}>
          * Please before start <a href="/help.pdf" target="_blank">click here</a> to understand the interview instructions for help.
        </Typography>}
        {threadId && (
          <>
            <div style={{ display: "flex" }}>
              <Button style={{ marginTop: "10px", width: "10vw", textAlign: "center", marginRight: "5px", background: "red", color: "white" }} variant="contained" onClick={submitData} disabled={!threadId}>
                End Test
              </Button>
              {!interviewConclude && !speaking && transcriptData !== "" && (
                <Button
                  onClick={() => setNextQuestion(true)}
                  style={{ marginTop: "10px", width: "10vw", textAlign: "center", marginLeft: "5px" }}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Next Question
                </Button>
              )}
            </div>
            <Typography variant="caption" color="green" sx={{ mt: 1 }}>
              * Please conclude interview before leaving the page or it will auto conclude when interview is done
            </Typography>
          </>
        )}
      </Stack>

      {threadId ? (
        <Stack sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 3 }}>
          <Card sx={{ width: "50%" }}>
            <Typography textAlign="center"></Typography>
            <WebcamVideo streamRef={streamRef} threadId={threadId} userId={userId} setAudio={setAudio} qaId={qaId} intwId={intwId} startCapturing={startCapturing} onTranscriptUpdate={handleTranscript} timeLeft={timeLeft} setConfirm={setConfirm} setViolationData={setViolationData} nextQestion={nextQestion} setStartCapturing={setStartCapturing} showWaitMessage={showWaitMessage} setIsLoaderVisible={setIsLoaderVisible} interviewEnded={interviewEnded} />
          </Card>
          <Card sx={{ width: "50%" }}>
            <CardHeader />
            <CardContent>
              {response.question && (
                <>
                  {avatarData && <LipSyncAvatar speechText={response.question} speaking={speaking} avatarData={avatarData} />}
                  <Typography style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: "5%",
                    userSelect: "none", // Disables text selection
                    MozUserSelect: "none", // For Firefox
                    WebkitUserSelect: "none", // For Safari and Chrome
                    msUserSelect: "none", // For Internet Explorer/Edge
                  }}>
                    {/* <IconButton onClick={() => setMute(!mute)}>
                      {mute ? <VolumeOffIcon /> : <VolumeUpIcon />} :
                    </IconButton> */}
                    <span>{response.question}</span>
                    {timeLeft > 0 && <span>{formatTime(timeLeft)}</span>}

                  </Typography>
                  {/* <Typography>{transcriptData}</Typography> */}

                </>
              )}
            </CardContent>
          </Card>
        </Stack>
      ) : (
        <>
          <Stack sx={{ mt: 3, display: "flex", flexDirection: "row", justifyContent: "center" }}>
            {InterViewStage === "Partial" ? <Button variant="contained" onClick={handleResumeButtonClick}>Resume Interview</Button> : startInterviewButton ? <Button variant="contained" onClick={handleStartButtonClick}>Start</Button> : <CircularProgress />}
          </Stack>
          <Stack>
            <Box sx={{ mt: 3, p: 2, border: "1px solid #ccc", borderRadius: "8px" }}>
              <Typography variant="h6" gutterBottom>
                Prepare your Computer System for Online Interview or Exam
              </Typography>
              <Typography variant="body2" gutterBottom>
                Please note that this link will expire 30 minutes after the email is received to avoid any confusion with the actual test link.
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>ONLINE REMOTE PROCTORED EXAMINATION PROTOCOL</strong>
              </Typography>
              <Typography variant="body2" gutterBottom>
                <ul>
                  <li>Ensure you have a working computer system with a webcam, mic, and stable internet connection.</li>
                  <li>Browsers supported: Google Chrome, Firefox, Safari (Apple).</li>
                  <li>Exam Duration: 15 minutes.</li>
                  <li>The exam is monitored by a live proctor and an AI tool.</li>
                  <li>Avoid suspicious activities; warnings may lead to penalties.</li>
                  <li>Maintain a quiet environment and sufficient lighting.</li>
                  <li>Do not browse, use devices, or read aloud during the test.</li>
                  <li>Exiting fullscreen or pressing "F5" ends the test abruptly.</li>
                </ul>
              </Typography>
            </Box>
          </Stack>
        </>
      )}

      {chatThread.length > 0 && (
        <Stack sx={{ mt: 5, ml: 3, display: "flex", flexDirection: "column" }}>
          <Typography variant="h6">AI Post Interview Analysis</Typography>
          {chatThread.map((res) => (
            res.intqa_answer && (
              <Card key={res.intqa_id} sx={{ minWidth: "30vw" }}>
                <CardContent>
                  <Typography variant="h6">🤖 : {res.intqa_question}</Typography>
                  <Typography>🧑 : {res.intqa_answer}</Typography>
                </CardContent>
              </Card>
            )
          ))}
        </Stack>
      )}
      <Dialog
        open={showConcludeDialog}
        onClose={() => setShowConcludeDialog(false)}
      >
        <DialogTitle>{"Concluding Interview"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please wait, we are concluding the interview.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmConclude} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  currentUserDetails: state.auth.user,
});

const mapDispatchToProps = {
  Userprofile,
  hideSidebar,
  showSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewPage);
