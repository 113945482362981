import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-github";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { _createAiAgent, get_agent_by_id, update_agent } from "../../services/agent.service";

const EditorField = ({ label, value, onChange, height }) => (
  <>
    <Typography variant="body1" sx={{ mb: 1 }}>{label}:</Typography>
    <AceEditor
      mode="markdown"
      theme="github"
      value={value}
      onChange={onChange}
      width="100%"
      height={height}
      setOptions={{ tabSize: 2 }}
      style={{ border: "1px solid #ccc", borderRadius: "8px" }}
    />
  </>
);

const CreateAiAgent = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    agentName: "",
    prompt: "",
    dos: "",
    donts: "",
    guiderails: "",
  });

  const [loading, setLoading] = useState(false);

  // Handles input changes for both text fields and AceEditor
  const handleInputChange = (field) => (event) => {
    const value = event.target ? event.target.value : event;
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  // Fetch agent details if editing
  const fetchAgentData = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const response = await get_agent_by_id(id);
      if (response.status === 200) {
        const { agent_name, agent_summary, agent_dos, agent_dont, agent_guide_rails } = response.data.agent;
        setFormData({
          agentName: agent_name,
          prompt: agent_summary,
          dos: agent_dos,
          donts: agent_dont,
          guiderails: agent_guide_rails,
        });
      } else {
        console.error("Failed to fetch agent details:", response);
      }
    } catch (error) {
      console.error("Error fetching agent data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  // Fetch data if in edit mode
  useEffect(() => {
    fetchAgentData();
  }, [fetchAgentData]);

  // Handle form submission for both create and update
  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const data = {
        agent_name: formData.agentName,
        agent_summary: formData.prompt,
        agent_dos: formData.dos,
        agent_dont: formData.donts,
        agent_guide_rails: formData.guiderails,
        ...(id && { agent_id: id }), // Include `id` if updating
      };

      let response = null;
      if (id) {
        response = await update_agent(id, data);
      }else{
        response = await _createAiAgent(data);
      }

      console.log('res ============>', response);

      if (response.status === 200) {
        console.log(id ? "Updating agent with data:" : "Creating agent with data:", data);
        navigate("/hr/agents");
      } else {
        console.error("Error creating/updating agent:", response);
      }
    } catch (error) {
      console.error("Error submitting agent:", error);
    } finally {
      setLoading(false);
    }
  }, [formData, id, navigate]);

  return (
    <Card sx={{ p: 4, boxShadow: 4 }}>
      <CardContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Grid container spacing={3} sx={{ maxWidth: "75%" }}>
            <Grid item xs={12}>
              <Typography variant="h5">
                <SupportAgentIcon sx={{ mr: 1 }} /> {id ? "Edit Agent" : "Create Agent"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                placeholder="Enter Agent Name"
                value={formData.agentName}
                onChange={handleInputChange("agentName")}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>

            <Grid item xs={12}>
              <EditorField label="Prompt" value={formData.prompt} onChange={handleInputChange("prompt")} height="100px" />
            </Grid>

            <Grid item xs={12}>
              <EditorField label="Do's" value={formData.dos} onChange={handleInputChange("dos")} height="70px" />
            </Grid>

            <Grid item xs={12}>
              <EditorField label="Don'ts" value={formData.donts} onChange={handleInputChange("donts")} height="70px" />
            </Grid>

            <Grid item xs={12}>
              <EditorField label="Guiderails" value={formData.guiderails} onChange={handleInputChange("guiderails")} height="70px" />
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
                sx={{ height: 40, textTransform: "none" }}
                disabled={loading}
              >
                {loading ? "Processing..." : id ? "Update & Test" : "Save & Test"}
              </Button>
            </Grid>

            {id && (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate("/hr/agents")}
                  fullWidth
                  sx={{ height: 40, textTransform: "none" }}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CreateAiAgent;
