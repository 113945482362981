import axios from "axios";

export const _createRoom = async (data) => {
  try {
    const url = `/create-room`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _roomInfo = async (id) => {
  try {
    const url = `/room/${id}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const _roomParticipants = async (id) => {
  try {
    const url = `/room/${id}/participants`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}


export const _getMeetings = async () => {
  try {
    const url = `/meeting-calender`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}