import React, { useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const InterviewFeedbackList = () => {
  // Static data for interview feedback
  const [feedbackList] = useState([
    {
      id: "fb1",
      candidateName: "Alice Johnson",
      feedback: "Strong technical skills, but needs better communication.",
      status: "Reviewed",
    },
    {
      id: "fb2",
      candidateName: "Bob Smith",
      feedback: "Excellent problem-solving abilities.",
      status: "Pending",
    },
    {
      id: "fb3",
      candidateName: "Charlie Brown",
      feedback: "Needs improvement in time management.",
      status: "Reviewed",
    },
  ]);

  return (
    <Container sx={{ mt: 4 }}>
      <Stack direction="row" alignItems="center" spacing={2} mb={2}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Interview Feedback
        </Typography>
      </Stack>
      <Divider sx={{ mb: 2 }} />
      <Paper sx={{ width: "100%", overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Candidate Name</TableCell>
              <TableCell>Feedback</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbackList.map((feedback) => (
              <TableRow key={feedback.id}>
                <TableCell>{feedback.candidateName}</TableCell>
                <TableCell>{feedback.feedback}</TableCell>
                <TableCell>{feedback.status}</TableCell>
                <TableCell>
                  <Typography
                    component={Link}
                    to={`/panel/interview-feedback/${feedback.id}`}
                    sx={{ textDecoration: "none", color: "primary.main" }}
                  >
                    <VisibilityIcon sx={{ mr: 1 }} />
                    View
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default InterviewFeedbackList;
