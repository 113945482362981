import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  Paper, 
  Box, 
  Divider,
  Card,
  CardContent,
  useTheme,
  Button,
  LinearProgress,
  CircularProgress 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { _getTrainingCourse, _coompleteUserTrainingCourse } from '../../services/employee.service';
import { useParams, useNavigate } from "react-router-dom";


// Styled components remain the same
const SlidePreview = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '70vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.grey[100],
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
}));

const TopicList = styled(Paper)(({ theme }) => ({
  height: '70vh',
  overflow: 'auto',
  borderRadius: theme.spacing(2),
  background: theme.palette.primary.main,
  color: 'white',
}));

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.primary.dark : 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    cursor: 'pointer',
  },
  borderRadius: theme.spacing(1),
  margin: theme.spacing(0.5, 1),
}));

const calculateReadingTime = (description) => {
  const wordsPerMinute = 200;
  const words = description.split(/\s+/).length;
  const minutes = words / wordsPerMinute;
  const seconds = Math.ceil(minutes * 60);
  return Math.max(seconds, 15);
};

function TrainingCourse() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [timerComplete, setTimerComplete] = useState(false);
  const [trainingData, setTrainingData] = useState({});
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [courseCompleted, setCourseCompleted] = useState(false); // New state for completion
  const theme = useTheme();
  const { course_name, ses_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrainingCourse = async () => {
      try {
        setLoading(true);
        const response = await _getTrainingCourse(course_name, ses_id);
        const tData = JSON.parse(response?.data?.mc_course_training_data);
        setTrainingData(response.data);
        const trainingSlides = tData?.map(slide => ({
          ...slide,
          timer: calculateReadingTime(slide.description)
        }));
        setSlides(trainingSlides);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchTrainingCourse();      
  }, [course_name, ses_id]);

  useEffect(() => {
    if (slides.length > 0) {
      const currentTimer = slides[currentSlide].timer;
      setTimeLeft(currentTimer);
      setTimerComplete(false);

      const timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setTimerComplete(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [currentSlide, slides]);

  const handleSlideChange = (index) => {
    if (timerComplete || index < currentSlide) {
      setCurrentSlide(index);
    }
  };

  // New function to handle course completion
  const handleFinishCourse = async () => {
    try {
      setLoading(true);
      const response = await _coompleteUserTrainingCourse(trainingData.mc_id);
      setCourseCompleted(true);
      console.log('Course completed successfully:', response);
      // You might want to redirect or show a completion message here
      navigate(`/employee/suggested-courses/${ses_id}`);
    } catch (error) {
      console.error('Error completing course:', error);
      // You might want to show an error message to the user here
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh', 
          background: '#f5f5f5' 
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, background: '#f5f5f5', minHeight: '100vh' }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{ 
          mb: 4, 
          textAlign: 'center',
          color: theme.palette.primary.main,
          fontWeight: 'bold'
        }}
      >
        {trainingData && trainingData.mc_course_name}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <TopicList elevation={3}>
            <Typography 
              variant="h6" 
              sx={{ p: 2, fontWeight: 'bold', textAlign: 'center' }}
            >
              Course Topics
            </Typography>
            <Divider sx={{ bgcolor: 'white', mb: 1 }} />
            <List>
              {slides && slides.map((slide, index) => (
                <StyledListItem
                  key={slide.topic}
                  active={index === currentSlide ? 'true' : undefined}
                  onClick={() => handleSlideChange(index)}
                >
                  <ListItemText 
                    primary={`${slide.topic} (${slide.timer}s)`}
                    primaryTypographyProps={{
                      fontWeight: index === currentSlide ? 'bold' : 'normal'
                    }}
                  />
                </StyledListItem>
              ))}
            </List>
          </TopicList>
        </Grid>

        <Grid item xs={12} md={8}>
          <SlidePreview elevation={3}>
            <Card sx={{ maxWidth: 600, width: '100%', overflow: 'auto' }}>
              <CardContent>
                <Typography 
                  variant="h5" 
                  color="primary" 
                  gutterBottom
                  sx={{ fontWeight: 'bold' }}
                >
                  {slides[currentSlide]?.topic}
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                  {slides[currentSlide]?.description}
                </Typography>
                {slides[currentSlide]?.example && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      {slides[currentSlide].example.title}
                    </Typography>
                    <Box sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1, mb: 2 }}>
                      <Typography 
                        variant="body2" 
                        component="pre" 
                        sx={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}
                      >
                        {slides[currentSlide].example['sample_code/query']}
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Notes:</strong> {slides[currentSlide].example.notes}
                    </Typography>
                  </>
                )}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="caption">
                    Time remaining: {timeLeft}s
                  </Typography>
                  <LinearProgress 
                    variant="determinate" 
                    value={(timeLeft / (slides[currentSlide]?.timer || 15)) * 100} 
                    sx={{ mt: 1 }}
                  />
                </Box>
              </CardContent>
            </Card>
            
            <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                disabled={currentSlide === 0}
                onClick={() => handleSlideChange(currentSlide - 1)}
              >
                Previous
              </Button>
              {currentSlide === slides.length - 1 ? (
                <Button
                  variant="contained"
                  color="success"
                  disabled={!timerComplete || courseCompleted}
                  onClick={handleFinishCourse}
                >
                  {courseCompleted ? 'Course Completed' : 'Finish Course'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={currentSlide === slides.length - 1 || !timerComplete}
                  onClick={() => handleSlideChange(currentSlide + 1)}
                >
                  Next
                </Button>
              )}
            </Box>
          </SlidePreview>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TrainingCourse;