import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  Divider,
  Button,
  Link as MuiLink,
} from "@mui/material";

const InterviewFeedbackView = () => {
  // Dummy data for demonstration
  const candidateInfo = {
    name: "Alice Johnson",
    email: "alice@example.com",
    phone: "123-456-7890",
  };

  const interviewInfo = {
    date: "2025-03-11",
    time: "10:00 AM - 10:45 AM",
    meetingLink: "https://example.com/meeting/int1",
    interviewType: "Technical Interview",
  };

  const panelistInfo = [
    { name: "Panelist 1", role: "Interviewer", email: "panel1@example.com" },
    { name: "Panelist 2", role: "Interviewer", email: "panel2@example.com" },
  ];

  const transcription =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus sapien vel nulla pretium, a iaculis nulla molestie. Donec lacinia magna in risus molestie, at convallis odio fermentum. Curabitur ac velit ut magna pharetra dignissim. Integer vitae ligula id libero aliquet cursus. Phasellus vitae lacus vitae sapien scelerisque posuere.";
  const recordingUrl = "https://example.com/recordings/int1.mp4";
  const aiFeedbackReport =
    "The candidate demonstrated excellent technical proficiency. However, there is room for improvement in soft skills. Overall score: 85/100.";

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>
        Interview Feedback Details
      </Typography>

      {/* Candidate Info */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Candidate Information
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <Typography variant="body1">
            <strong>Name:</strong> {candidateInfo.name}
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong> {candidateInfo.email}
          </Typography>
          <Typography variant="body1">
            <strong>Phone:</strong> {candidateInfo.phone}
          </Typography>
        </CardContent>
      </Card>

      {/* Interview Info */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Interview Information
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <Typography variant="body1">
            <strong>Date:</strong> {interviewInfo.date}
          </Typography>
          <Typography variant="body1">
            <strong>Time:</strong> {interviewInfo.time}
          </Typography>
          <Typography variant="body1">
            <strong>Type:</strong> {interviewInfo.interviewType}
          </Typography>
          <Typography variant="body1">
            <strong>Meeting Link:</strong>{" "}
            <MuiLink href={interviewInfo.meetingLink} target="_blank">
              Join Meeting
            </MuiLink>
          </Typography>
        </CardContent>
      </Card>

      {/* Panelist Info */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Panelist Information
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <Grid container spacing={1}>
            {panelistInfo.map((panelist, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Typography variant="body1">
                  <strong>Name:</strong> {panelist.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Role:</strong> {panelist.role}
                </Typography>
                <Typography variant="body1">
                  <strong>Email:</strong> {panelist.email}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      {/* Transcription */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Transcription
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <Box
            sx={{
              maxHeight: 200,
              overflowY: "auto",
              p: 1,
              border: "1px solid #ddd",
              borderRadius: "4px",
            }}
          >
            <Typography variant="body2">{transcription}</Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Recording */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Recording
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <video width="100%" controls>
              <source src={recordingUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </CardContent>
      </Card>

      {/* AI Feedback Report */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 1 }}>
            AI-Generated Feedback Report
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <Box
            sx={{
              maxHeight: 150,
              overflowY: "auto",
              p: 1,
              border: "1px solid #ddd",
              borderRadius: "4px",
            }}
          >
            <Typography variant="body2">{aiFeedbackReport}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default InterviewFeedbackView;
