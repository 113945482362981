import { useState, useEffect, useCallback } from "react";
import { Button, Stack, Typography, Box, Modal } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSelfEvalTestEmployee, submitCodingEmployee } from "../../services/dashboard";
import AlertDialog from "../../components/Dialog";
import Loader from "../../components/Loader";
import CodeEditor from "../../components/Editor/CodeEditor";

const modalPopUpStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "8px",
  textAlign: "center",
};

export const TestCoding = ({
  dashboard: { testResultCode },
  skillData,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [answerSheetCoding, setAnswerSheetCoding] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isPassing, setIsPassing] = useState(0);
  const [openSecond, setOpenSecond] = useState(false);
  const [test, setTest] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (testResultCode) {
      setOpenSecond(true);
      const isPassings = testResultCode && testResultCode.finalPercentage > 50;
      setIsPassing(isPassings);
    }
  }, [testResultCode]);

  const onChangAnswer = useCallback((code, q_id, ques) => {
    const answer = code;
    let obj = { q_id, answer, ques };
    setAnswerSheetCoding((prev) => {
      let index = prev.findIndex((item) => item.q_id === q_id);
      if (index > -1) {
        prev[index] = obj;
      } else {
        prev.push(obj);
      }
      return [...prev];
    });
  }, []);

  useEffect(() => {
    if (!skillData) {
      skillData = JSON.parse(localStorage.getItem("skillData"));
    }
  }, [skillData]);

  useEffect(() => {
    const fetchTest = async () => {
      if (skillData && skillData?.length) {
        setSubmitting(true);
        let testData = await getSelfEvalTestEmployee({ skills: skillData }, "coding");
        setTest(testData);
        setSubmitting(false);
      }
    };
    fetchTest();
  }, [skillData]);

  // useEffect(() => {
    
  //   if (confirm) {
  //     fetchData();
  //   }
  // }, [confirm]);


  async function submitCodingEmp() {
    setSubmitting(true);
    let data = {
      skills: skillData,
      answerSheetCoding: answerSheetCoding,
    };
    await submitCodingEmployee(data);
    setOpen(false);
    setConfirm(false);
    setTimeout(() => {
      handleSubmit();
    }, 2000);
  }

  const submitData = () => {
    submitCodingEmp();
    // setTitle("Submit Test");
    // setContent("Are you sure you want to submit the test?");
    // setOpen(true);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < test?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmit = () => {
    setOpenSecond(false);
    navigate("/employee/ai-assistant", { replace: true });
  };

  return (
    <>
      {submitting ? (
        <Loader />
      ) : (
        <Stack
          direction={{ xs: "column", sm: "column", width: "-webkit-fill-available" }}
          alignItems="center"
        >
          <AlertDialog
            open={open}
            setOpen={setOpen}
            title={title}
            setConfirm={setConfirm}
            content={content}
          />
          <Stack direction={{ xs: "row", sm: "row" }} justifyContent="center" sx={{ mt: 3 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handlePreviousQuestion}
              disabled={currentQuestionIndex === 0}
            >
              Previous
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleNextQuestion}
              disabled={currentQuestionIndex === test?.length - 1}
            >
              Next
            </Button>
            <Button color="primary" variant="contained" onClick={submitData}>
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate("/dashboard")}
            >
              Back
            </Button>
          </Stack>

          <Stack direction={{ xs: "row", sm: "row" }} justifyContent="center" sx={{ mt: 3 }}>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Test Coding
            </Typography>
          </Stack>

          {test && test?.length > 0 && (
            <Stack
              direction={{ xs: "column", sm: "column" }}
              alignSelf="center"
              spacing={1}
              useFlexGap
              sx={{ pt: 2, width: { xs: "100%", sm: "100%" } }}
            >
              <Stack direction="row" sx={{ alignItems: "center", mt: 2 }}>
                <Typography variant="h6">
                  {currentQuestionIndex + 1}.{" "}
                  {test[currentQuestionIndex].q_name.split("Girikon")[0]}.<br />
                  {test[currentQuestionIndex].q_name.split("Girikon")[1]}
                </Typography>
              </Stack>
              <Stack direction="column" sx={{ width: "100%" }}>
                <CodeEditor
                  key={test[currentQuestionIndex].q_id} // Ensure unique key for each question
                  onChange={(e) =>
                    onChangAnswer(
                      e,
                      test[currentQuestionIndex].q_id,
                      test[currentQuestionIndex].q_name
                    )
                  }
                  style={{ width: "100%", userSelect: "none" }}
                />
              </Stack>
            </Stack>
          )}

          <Modal
            open={openSecond}
            onClose={() => setOpenSecond(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={modalPopUpStyle}>
              <Typography variant="h6" component="h2" id="child-modal-title">
                Your Score is
              </Typography>
              <Box>
                <Typography>
                  Your code is under review, you can check on dashboard.
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  color={isPassing ? "success" : "error"}
                  onClick={handleSubmit}
                >
                  OK
                </Button>
              </Box>
            </Box>
          </Modal>
        </Stack>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  skillData: state.skills.data,
  dashboard: state.dashboard,
});

const mapDispatchToProps = {
  // getAllSelfEvalTest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestCoding);