import axios from "axios";



export const get_report_by_id = async (id) => {
  try {
      const url = `psycometric/report/${id}`;
      const response = await axios.get(url);
      return response;
    } catch (error) {
      throw error;
    }
};

export const getPublicAIPsycometricReport = async (apct_id = "", org_name) => {
  try {
    // const url = `${org_name}/candidate/get/inteviewAiScore/${apct_id}`;
    let BASE_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;
    const url =  `${BASE_URL}/${org_name}/api/v1/public/get/psycometric/report/${apct_id}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPublicAIPsycometricReportLn = async (apct_id = "", org_name) => {
  try {
    // const url = `${org_name}/candidate/get/inteviewAiScore/${apct_id}`;
    let BASE_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;
    const url =  `${BASE_URL}/${org_name}/api/v1/public/get/psycometric/report_en/${apct_id}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const get_report_by_id_lan = async (id) => {
  try {
      const url = `psycometric/report_en/${id}`;
      const response = await axios.get(url);
      return response;
    } catch (error) {
      throw error;
    }
};
