import { Route, Routes, Navigate } from "react-router-dom";
import store from '../redux/store.js';
import InterviewerVideoCall  from "../pages/interviewer/InterviewerVideoCall.jsx";
import InterviewerDashboard from "../pages/interviewer/InterviewerDashboard.jsx";
import MeetingCalendar from "../pages/interviewer/MeetingCalendar.jsx";
import InterviewFeedbackList from "../pages/interviewer/InterviewFeedbackList.jsx";
import InterviewFeedbackView from "../pages/interviewer/InterviewFeedbackView.jsx";
import VideoCall from "../pages/candidate/VideoCall.jsx";


const InterviewerRoutes = () => {
  const { user } = store.getState().auth;
//   const { permissions } = user;

  // Define routes in an array for better organization
  const routes = [
    { path: "/panel/dashboard", element: <InterviewerDashboard /> },   
    // { path: "/panel/interview/:roomId", element: <InterviewerVideoCall  /> },
    { path: "/meeting/:roomId", element: <VideoCall  /> },
    { path: "/panel/meeting-calendar", element: <MeetingCalendar /> },  
    { path: "/panel/interview-feedback", element: <InterviewFeedbackList /> },
    { path: "/panel/interview-feedback/:id", element: <InterviewFeedbackView /> },
  ];

  return (
    <Routes>
      {routes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
      <Route path="*" element={<Navigate to="/panel/dashboard" replace />} />
    </Routes>
  );
};

export default InterviewerRoutes;
